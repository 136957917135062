export default function Checkbox({name, customClass, label, register, requiredErrorMessage, errors}) {
    return (
        <div className={`col-sm-12 fullform ${customClass}`}>
            <label className="rememberbox">{label}
                <input type="checkbox"
                    name={name}
                    {...register(name, {
                        required: requiredErrorMessage
                    })}
                    value=""
                />
                <span className="checkmark"></span>
                <small className="form-text text-muted">
                    {
                        errors[name] && <p>{errors[name].message}</p>
                    }
                </small>
            </label>
        </div>
    )
}
