import i18next from "i18next";

// All GTM events possible
export const getTagManagerEvents = {
    home: 'DYOB_start_your_design',
    loadStart: 'DYOB_load_your_design_start',
    loadFinish: 'DYOB_load_your_design_complete',
    save: 'DYOB_save_design_complete',
    download: 'DYOB_download_print_summary',
    book: 'DYOB_book_consultation_complete',
    selectProjectType: 'DYOB_select_project_type',
    selectShowerType: 'DYOB_shower_type_selection',
    selectPlumbingSide: 'DYOB Plumbing side selection',
    selectProduct: 'DYOB_config_product_selection',
    viewSummary: 'DYOB_summary_section_view',
    share: 'DYOB_share_design',
    pageView: 'pageview'
}

// Return the project type when selecting a product, viewing configurator page or summary
const getProjectType = () => {
    return window.location.pathname.includes('design-your-own-bathroom') ? 'Bathtub' : 'Shower';
}

// Returns list of all products selected with their categories and colors
const getSummaryInformation = (summaryPrintData) => {
    let summaryInformation = [];

    if (Object.keys(summaryPrintData).length > 0) {
        summaryInformation['projectType'] = getProjectType();

        Object.values(summaryPrintData).forEach((rowData) => {
            if (rowData.Eng) {
                // Category
                summaryInformation[rowData.Eng.category] = `${rowData.Eng.product_name}_${rowData.Eng.color}`;
            } else {
                // Sub category
                Object.values(rowData).forEach(childData => {
                    summaryInformation[childData.Eng.category] = `${childData.Eng.product_name}_${childData.Eng.color}`;
                });
            }
        });
    }

    return summaryInformation;
}

// Push GTM event in Google dataLayer
export const GoogleDataLayer = (data) => {
    let dataToPush;
    let summaryInformation;
    switch (data.event) {
        // Select project type
        case getTagManagerEvents.selectProjectType: 
            dataToPush = {
                'event': data.event,
                'projectType': data.projectType
            };
            break;

        // Select shower type
        case getTagManagerEvents.selectShowerType:
            dataToPush = {
                'event': data.event,
                'projectType': data.showerType
            };
            break;

        // Select plumbing side
        case getTagManagerEvents.selectPlumbingSide:
            dataToPush = {
                'event': data.event,
                'projectType': data.projectType,
                'plumbingSide': data.plumbingSide
            };
            break;

        // Select product
        case getTagManagerEvents.selectProduct:
            dataToPush = {
                'event': data.event,
                'productCategory': data.productCategory,            
                'productName': data.productName,
                'gaProductCode': data.gaProductCode,
                'projectType': getProjectType()
            };
            break;

        // View design summary
        case getTagManagerEvents.viewSummary:
            summaryInformation = getSummaryInformation(data.summaryPrintData);
            dataToPush = {
                'event': data.event,
                ...summaryInformation
            };
            break;

        // Book consultation completed
        case getTagManagerEvents.book:
            summaryInformation = getSummaryInformation(data.summaryPrintData);
            dataToPush = {
                'event': data.event,
                ...summaryInformation
            };
            break;

        // Share design
        case getTagManagerEvents.share:
            dataToPush = {
                'event': data.event,
                'shareMethod': data.shareMethod
            }
            break;

        // Any page view event except summary and 404 views
        case getTagManagerEvents.pageView:
            const pathName = window.location.pathname;
            let pageTitle;
            if (pathName.includes('plumbing-position')) {
                pageTitle = `${pathName.includes('shower') ? 'Shower' : 'Bath'} plumbing side`;
            } else if (pathName.includes('project-type')) {
                pageTitle = 'project-type';
            } else if (pathName.includes('choose-shower')) {
                pageTitle = 'shower-type';
            } else if (pathName.includes('load-existing-design')) {
                pageTitle = 'load existing design';
            }  else if (pathName.includes('design-your-own-shower')) {
                pageTitle = 'Design your own shower';
            } else if (pathName.includes('design-your-own-bathroom')) {
                pageTitle = 'Design your own bathroom'; 
            } else if (pathName === '/' || pathName === `/${i18next.language}`) {
                pageTitle = 'Home';
            } else {
                // No tracking for current page
                return;
            }
            dataToPush = {
                'event': data.event,
                'page': {
                    url: pathName,
                    title: pageTitle
                }
            }
            break;

        // All other events that don't require additional information other then the event name
        default:
            dataToPush = {
                'event': data.event
            };
            break;
    }

    window.dataLayer.push(dataToPush);
}