// Get campaign informations from URL and put them in a JSON object
export const getCampaignData = (location) => {
    let campaignInformations = {};
    const cookies = getCookies();
    location.search.replace('?', '').split('&').forEach((campaignData) => {
        
        let [campaignKey, campaignValue] = campaignData.split('=');

        if (campaignKey && getCampaignKeys.includes(campaignKey) && campaignValue && campaignValue.length > 0) {
            campaignInformations[campaignKey] = campaignValue;
        }
    });

    // Include values from cookies if they exist and match the campaign keys
    getCampaignKeys.forEach((key) => {
        if (cookies[key] && !campaignInformations[key]) {
            campaignInformations[key] = cookies[key];
        }
    });
    return campaignInformations;
}

// List of campaign keys that can be found in the URL 
export const getCampaignKeys = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content', 'cid', 'fbclid', 'gclid', 'gbraid', 'wbraid', 'dclid', 'ttclid', 'li_fat_id', 'epik', 'sccid', 'msclkid', '_fbc', '_fbp', 'ga_user_id', 'ga_client_id' ];

 // Utility function to parse cookies
 export const getCookies = () => {
    const cookies = document.cookie.split('; ').reduce((acc, cookie) => {
        const [key, value] = cookie.split('=');
        acc[key] = decodeURIComponent(value);
        return acc;
    }, {});
    return cookies;
};