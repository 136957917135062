import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

let initialState = {
    x: 0,
    y: 0,
    scale: 1,
    isDragging: false
}

export const calculateSnapping = createAsyncThunk('calculateSnapping/design', async(payload, thunkAPI) => {
    let newX = thunkAPI.getState().layout.x;
    let newY = thunkAPI.getState().layout.y;
    let mainDivRef = payload.mainDivRef;
    let dragAreaRef = payload.dragAreaRef;
    
    let imageBounds = dragAreaRef.current.getBoundingClientRect();
    let containerBounds = mainDivRef.current.getBoundingClientRect();

    // Calculate drag snapping when zoomed in
    let imageOriginalWidth = dragAreaRef.current.clientWidth;
    let imageWidthOverhang = (imageBounds.width - imageOriginalWidth) / 2;
    let imageOriginalHeight = dragAreaRef.current.clientHeight;
    let imageHeightOverhang = (imageBounds.height - imageOriginalHeight) / 2;

    // Check for horizontal snapping
    if (imageBounds.left > containerBounds.left) {
        newX = imageWidthOverhang;
    } else if (imageBounds.right < containerBounds.right) {
        newX = -(imageBounds.width - containerBounds.width) + imageWidthOverhang;
    }

    // Check for vertical snapping
    if (imageBounds.top > containerBounds.top) {
        newY = imageHeightOverhang;
    } else if (imageBounds.bottom < containerBounds.bottom) {
        newY = -(imageBounds.height - containerBounds.height) + imageHeightOverhang;
    }

    await thunkAPI.dispatch(translate({x: newX, y: newY}));
});

export const layoutSlice = createSlice({
    name:'layout',
    initialState,
    // define reducer and auto generate the actions
    reducers: {
        zoomIn: (state) => {
            state.scale += 0.1;
        },
        zoomOut: (state) => {
            if (state.scale > 1) {
                state.scale -= 0.1;
            }
        },
        resetView: (state) => {
            state.x = 0;
            state.y = 0;
            state.scale = 1;
        },
        translate: (state, action) => {
            state.x = action.payload.x;
            state.y = action.payload.y;
        },
        setIsDragging: (state, action) => {
            state.isDragging = action.payload;
        }
    }
});

export const {zoomIn, zoomOut, resetView, translate, setIsDragging} = layoutSlice.actions;

export const getX = (state) => state.layout.x;
export const getY = (state) => state.layout.y;
export const getScale = (state) => state.layout.scale;
export const getIsDragging = (state) => state.layout.isDragging;

export default layoutSlice.reducer;
