import React,{useEffect,useRef} from 'react'
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import Header from '../header/Header'
import Intro from '../../video/intro.mp4'
import { Link, useLocation } from 'react-router-dom'
import nextArrowImage from '../../images/icon3.png';
import i18next from 'i18next';
import { getBookConsultationStatus } from '../designmobilelayout/mobileMenuSlice';
import Loader from '../loader/Loader';
import { GoogleDataLayer, getTagManagerEvents } from '../layout/GoogleDataLayer';

const HomePage = () => {
  const videoEl  = useRef(null);
  const bookConsultationStatus = useSelector(getBookConsultationStatus); 
  const attemptPlay = () => {
    videoEl && videoEl.current && videoEl.current.play().catch(error => {
        console.error("Error attempting to play", error);
      });
  };

  // React hook to trigger the video to play after loading
  useEffect(() => {
    attemptPlay();
  }, []);

  const { t } = useTranslation();
  const location = useLocation();
 
  return (
    <>
      <Header fixedHeaderClass='headerbgFixed'/>
      <div className="pre-config-main">
        <div className="pre-config-bg">
          <video  style={{ margin: "0 auto",height:"" }} 
            playsInline
            loop
            muted
            alt="Intro"
            src={Intro}
            ref={videoEl}
          />
        </div>

        <div className="pre-config-info">
          <h2>
            {
              t("homePage.title", {
                brand: window.location.hostname.includes('bainmagique') || location.search.includes("brand=BM") ? 
                'Bain Magique' : 'Bath Fitter'
              })
            }
          </h2>
          <p>{t("homePage.description")}</p>
          <Link to={`/${i18next.language}/project-type${location.search}`} onClick={() => GoogleDataLayer({event: getTagManagerEvents.home})}>
            <div className="follow-steps">
              <h4>{t("homePage.instructions")}</h4>
              <div className='nextStepIcon'>
                <img src={nextArrowImage} alt=''/>
              </div>
            </div>
          </Link>
        </div>
      </div>
      {
        bookConsultationStatus === 'loading' ? <Loader /> : null
      }  
    </>
  )
}

export default HomePage