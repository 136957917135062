import React,{useEffect} from 'react'
import { useSelector,useDispatch } from 'react-redux'
import BathShowerHeader from './BathShowerHeader'
import { fetchCatData,getCatStatus,getCatErrors,selectAllCat  } from '../categorydata/categoryDataSlice';
import MobileProjectData from './MobileProjectData'
import { useTranslation } from "react-i18next";
import i18next from 'i18next';
import ArrowRight from '../../images/arrow-right.svg'
import { Link, useLocation } from 'react-router-dom';
import { getBookConsultationStatus } from '../designmobilelayout/mobileMenuSlice';
import Loader from '../loader/Loader';

const MobileProjectType = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const catStatus = useSelector(getCatStatus);
  const catError = useSelector(getCatErrors);
  const cats = useSelector(selectAllCat);
  const bookConsultationStatus = useSelector(getBookConsultationStatus);

  useEffect(() => {
    dispatch(fetchCatData());
  }, [dispatch]);

let content;
if(catStatus ==='loading')
{
    content=<p>{t("loading")}</p>
}
else if(catStatus ==='idle')
{    
    
      if(Object.values(cats)!== undefined)
      {
        content = cats.map(cats=> <MobileProjectData key={cats.id} cats={cats}  />)
      }
     
}
else if(catStatus === 'failed')
{
      content =<p>{catError}</p>
}

  return (
    <> 
      <BathShowerHeader/>
      <div className="select-icons">
        <div className="selector-title">
          <h4>{t("typeMobile.instructions")}</h4>
        </div>
        <div className="select-item">
          {content}
        </div>
        <div className="load-existing">
          <h4>{t("typeMobile.loadExisting")}</h4>
          <div className="load-existing-item select-item">
            <div className="item-outer">
              {
                cats.map(category =>   
                  category.slug === 'load-existing' ? ( 
                    <Link to={`/${i18next.language}/load-existing-design-mobile${location.search}`} key={category.id}>
                      <div className='item-image-space' >
                        <img src={category.background_image?window.config.REACT_APP_BACKEND_URL+category.background_image.src:''} alt={category.name}/>
                        <div className="item-title">
                          <p>{t("typeMobile.load")}</p>
                          <img src={ArrowRight} alt="arrow"/>
                        </div>
                      </div>
                    </Link>
                  ) : null
                )
              }
            </div>
          </div>
        </div>
      </div>
      {
        bookConsultationStatus === 'loading' ? <Loader /> : null
      }
    </>
  )
}

export default MobileProjectType