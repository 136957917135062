import React,{useEffect} from 'react'
import { Link, useLocation } from 'react-router-dom'
import i18next from 'i18next';
import { Trans, useTranslation } from 'react-i18next';
import { TranslationKeys } from '../../i18n';
import { getProjectType, getSummaryPageData,backToDesign, getLoadExistingDesignStatus } from '../designbathshower/designBathShowerSlice';
import MobileDesignSummaryContent from './MobileDesignSummaryContent';
import { useDispatch,useSelector } from 'react-redux';
import { GoogleDataLayer, getTagManagerEvents } from '../layout/GoogleDataLayer';

const MobileDesignSummary = () => { 
const { t } = useTranslation();
const dispatch = useDispatch();
const location = useLocation();

const projectType = useSelector(getProjectType);
const projecTypeName = projectType["translations"] && projectType["translations"][TranslationKeys[i18next.language]] ?
    projectType["translations"][TranslationKeys[i18next.language]]:
    projectType.name;

let summaryDataList =useSelector(getSummaryPageData); 
let loadExistingDesignStatus = useSelector(getLoadExistingDesignStatus)
let summary_content;
if(Object.keys(summaryDataList).length >0)
{
    summary_content=Object.entries(summaryDataList).map(([key,data])=><MobileDesignSummaryContent key={key} summaryData={data} title={key}/>)
} 

useEffect(() => {
  if (loadExistingDesignStatus === 'idle') {
    GoogleDataLayer({
        event: getTagManagerEvents.viewSummary,
        summaryPrintData: summaryDataList
    });
}
}, [loadExistingDesignStatus, summaryDataList]);

const handleBackToDesignClick = () => {
  dispatch(backToDesign());
};

  return (
    <div className='tabsinnerOd' id="tablescrollOd">
      <div className="summaryTitle col-sm-12">
        <p className="mainhead backToDesign">
          <Link to={`${location.pathname}${location.search}`} onClick={handleBackToDesignClick}><span><i className="fas fa-angle-left"></i></span>{t("summaryBackPage")}</Link>
        </p>
      </div>
      <div className="filtertext middletextcenter"  >                   
        <p className="congrats">{t("summary.congratulations")}</p>
          <Trans i18nKey="summary.instructions" values={{category: projecTypeName.toLowerCase()}}>
            <span className="congratssmalltex">
              You've designed your new category!<br/>The next step towards your new category is to request a free consultation.
            </span> 
          </Trans>
      </div>
      {summary_content}
    </div>
  )
}

export default MobileDesignSummary