import React from 'react'
import { Link,useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

import { useDispatch } from 'react-redux';
import { modifySummaryAssestMobile } from '../designbathshower/designBathShowerSlice';

const MobileDesignSummaryContent = ({summaryData,title}) => {
const dispatch=useDispatch();    
const { t } = useTranslation();
const location  = useLocation();

  return (
    <>
        
        <div className="filtertext">
            <div className="col-sm-7 headingleft">
                <p className="mainhead">{title}</p>
            </div>
            <div className="col-sm-5 addbutton">
                <Link to={`${location.pathname}${location.search}`} className="clear"   onClick={()=> dispatch(modifySummaryAssestMobile(title))}>{t("modify")}</Link>
            </div>
        </div>
            {
                 (summaryData.assetImage !==undefined)?(<div className='bathTubSummary'>
                 <table>
                    <tbody>
                        <tr>
                            <td>
                                <span className="images">
                                    <img src={window.config.REACT_APP_BACKEND_URL+summaryData.assetImage} alt='' id="summaryBath"/>
                                </span>
                            </td>
                            <td>{t("summary.productName")} : <br/> <b>{summaryData.assetName}</b><br/>
                                  <span className="colorboxes">{t("color")} : <br/><b>{summaryData.assetColor}</b> </span>
                            </td>
                        </tr>
                        
                    </tbody>
                  </table>
                </div>):(Object.values(summaryData).map(data=><div className='bathTubSummary' key={data.assetId}>
                 <table >
                    <tbody>
                        <tr>
                            <td>
                                <span className="images">
                                    <img src={window.config.REACT_APP_BACKEND_URL+data.assetImage} alt='' id="summaryBath"/>
                                </span>
                            </td>
                            <td>{t("summary.productName")} : <br/> <b>{data.assetName}</b><br/>
                                  <span className="colorboxes">{t("color")} : <br/><b>{data.assetColor}</b> </span>
                            </td>
                        </tr>
                        
                    </tbody>
                  </table>
                </div>))
            }
            
           
    </>
  )
}

export default MobileDesignSummaryContent