import React from 'react'
import { useDispatch ,useSelector} from 'react-redux';
import i18next from 'i18next';
import { TranslationKeys } from '../../i18n';
import { getVariantDetails,getSelectedAssetsID,setOrderSelectedSubCat,setCategoriesScrollColorSelection,getCategoriesScrollColorSelection,removeSingleProduct} from '../designbathshower/designBathShowerSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { GoogleDataLayer, getTagManagerEvents } from '../layout/GoogleDataLayer';

const MobileDesignData = ({datamobile,mainCat,headerIndex}) => {
const categoriesScrollColorSelection = useSelector(getCategoriesScrollColorSelection);
const dispatch =useDispatch();  

let variantId, colorName, colorHex, variantImage,categoryName,productName;

 
      productName = datamobile.meta_data.filter(m => m.key === TranslationKeys[i18next.language]).length === 0 ?
      datamobile.name :datamobile.meta_data.filter(m => m.key === TranslationKeys[i18next.language])[0].value;
      
      
      categoryName = datamobile.categories[0][TranslationKeys[i18next.language]] ?datamobile.categories[0][TranslationKeys[i18next.language]] :datamobile.categories[0].name;
      
      
      if (datamobile.default_attributes[0]) {
      variantId = datamobile.default_attributes[0].variantId;
      colorName = datamobile.default_attributes[0][TranslationKeys[i18next.language]] ? datamobile.default_attributes[0][TranslationKeys[i18next.language]] : datamobile.default_attributes[0].option;
      colorHex = datamobile.default_attributes[0].value;
      variantImage = datamobile.images[0]?datamobile.images[0].src:'';
      } else {
        if(datamobile.product_variations.length >0)
        {
           variantId = datamobile.product_variations[0].id;
          colorName = datamobile.product_variations[0].name;
          colorHex = datamobile.product_variations[0].value;
          variantImage = datamobile.images[0]?datamobile.images[0].src:'';
        }
     
      }
      const selectedVarinat=useSelector(getSelectedAssetsID);
      
      let ObjectLen=Object.keys(selectedVarinat).length;

      const handleAssetVariantImage = () => {
        dispatch(getVariantDetails({
          'product_variations': datamobile.product_variations,
          'mainCatName'       : datamobile.categories[0].name,
          'categoryName'      : categoryName,
          'name'              : productName,
          'assetId'           : datamobile.id,
          'variantId'         : variantId,
          'assetColor'        : colorName,
          'assetColorStyle'   : colorHex,
          'assetImage'        : variantImage,
          'parentCat'         : mainCat,
          'childLen'          : 0 ,
          'headerIndex'       : headerIndex,
          'Eng'               : {'product_name':datamobile.name,'color':datamobile.product_variations[0].name,'category':datamobile.categories[0].name} 
        }));
        GoogleDataLayer({
          event: getTagManagerEvents.selectProduct,
          productName: datamobile.name,
          gaProductCode: `${datamobile.categories[0].name}-${datamobile.name}`,
          productCategory: datamobile.categories[0].name
        });
      };

      const handleSelectedAssetVariantImage = () => {
        handleAssetVariantImage();
        if(!Object.keys(categoriesScrollColorSelection).includes(mainCat)){
          dispatch(setCategoriesScrollColorSelection(mainCat));
        }
        dispatch(
          setOrderSelectedSubCat({
            'parentCat': mainCat,
            'categoryName': categoryName,
            'assetId': datamobile.id
          })
        );
      };

      const handleRemoveSelection = (e) => {
        e.stopPropagation();

        dispatch(removeSingleProduct({'categoryName': categoryName}));
      };

      return (
        <li className="col-sm-4">
          <div className={`padding ${ObjectLen > 0 && Object.values(selectedVarinat).indexOf(datamobile.id) !== -1 ? 'active' : ''}`}>
            <span className="imagesborder" onClick={handleSelectedAssetVariantImage}>
              <img src={datamobile.images[0] ? window.config.REACT_APP_BACKEND_URL + datamobile.images[0].src : ''} alt="" />
              {ObjectLen>0 && Object.values(selectedVarinat).indexOf(datamobile.id)!== -1 ? (
                  <FontAwesomeIcon icon={faTimes} className="chekedIcon" onClick={handleRemoveSelection} />
                ) : null
              }              
            </span>
            <span className="tittle">{productName}</span>
          </div>
        </li>
      )
}


export default MobileDesignData