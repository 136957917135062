import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import i18next from 'i18next';
import { useTranslation } from "react-i18next";
import { useForm } from 'react-hook-form';
import Header from '../header/Header'
import { fetchUserSavedDesigns, getLoadExistingStatus, getLoadExistingData, getIsUserDesignsFetched, clearUserSavedDesigns, getBookConsultationStatus } from '../designmobilelayout/mobileMenuSlice';
import { openLoadExistingDesignPopUp } from '../designbathshower/designBathShowerSlice';
import Loader from '../loader/Loader';
import DesignLoadExisitngPop from '../designbathshowerpopup/DesignLoadExisitngPop';
import { GoogleDataLayer, getTagManagerEvents } from '../layout/GoogleDataLayer';
import EmailInputField from '../formfields/EmailInputField';

const LoadExistingDesign = () => {
const dispatch  = useDispatch();    
const { t }     = useTranslation();  
const location  = useLocation();
const bookConsultationStatus = useSelector(getBookConsultationStatus);

const onSubmit = (data, e) => {
    dispatch(fetchUserSavedDesigns(data.email));
    reset();
    GoogleDataLayer({event: getTagManagerEvents.loadStart});
};

const { register, reset, handleSubmit, formState: { errors } } = useForm();

const getUserDesignsStatus = useSelector(getLoadExistingStatus)
const savedDesigns = useSelector(getLoadExistingData)
const isUserDesignsFetched = useSelector(getIsUserDesignsFetched);

  return (
   
    <>
     <Header fixedHeaderClass='headerbgFixed'/>
       <div className="load-project">
            <div className="skiptogetstarted back-to">
                <p className="back-to">
                    <Link to={`/${i18next.language}/project-type${location.search}`} onClick={() => dispatch(clearUserSavedDesigns())}>
                        <span><i className="fas fa-angle-left"></i></span>{t("previousPage")}
                    </Link>
                </p>
            </div>

            <div className="load-project-inner">     
                <h2>{t("loadExisting.title")}</h2>
                <p className="instructions">{t("loadExisting.instructions")}</p>
                <div className="load-email-field">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <EmailInputField name="email"
                            placeholder={t("label.email")}
                            register={register}
                            requiredErrorMessage={t("error.emailRequired")}
                            patternErrorMessage={t("error.emailFormat")}
                            errors={errors}
                        />
                        <button type="submit">{t("loadExisting.load")}</button>
                    </form>
                </div>
            </div> 
        </div>
        <div className="saved-designs">
            {
                isUserDesignsFetched ? (
                    <>
                        <h2>{t("loadExisting.subtitle")}</h2>

                        {
                            Object.keys(savedDesigns).length > 0 ? (
                                <div className="left-right-img">
                                    {
                                        Object.entries(savedDesigns).map(([key,data]) => (
                                            
                                            <div className="left-image" key={key}>
                                                <Link to={`${location.pathname}${location.search}`} onClick={() => dispatch(openLoadExistingDesignPopUp(data))}>
                                                    <div className="thumbnail-img">
                                                        <img src={data.big_image} alt=""/>
                                                    </div>
                                                    <p>{ data['creation_date'].substr(0,10) }</p>
                                                </Link>
                                            </div>
                                        ))
                                    }
                                </div>
                            ) : (
                                <p className='form-text text-muted'>{t("loadExisting.noDesignsFound")}</p>
                            )
                        }
                    </>
                ) : null
            }
        </div>
        {
            getUserDesignsStatus === 'loading' ? <Loader /> : null
        }
         <DesignLoadExisitngPop/> 
        {
            bookConsultationStatus === 'loading' ? <Loader /> : null
        }
    </>
    
  )
}

export default LoadExistingDesign