import { createAsyncThunk,createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import i18next from "i18next";
import { TranslationKeys } from "../../i18n";
import { getDesignImage } from "../capturedesign/CaptureDesign"
import { setDesignId } from "../designmobilelayout/mobileMenuSlice";

let initialState={
    status:'idle',
    error:null,
    allAssestsData:[],
    projectType: [],
    designstatus:"idle",
    designBathData:[], 
    variantDetailStatus:'idle',
    variantDetailsData:[], 
    selectedAssetsId:[],
    assestVarianttImageStatus:'idle',
    colorVariationsRight:[],
    colorVarinatOtherData:[],
    isMissingCategoriesPopUpActive: false,
    summaryPopUpStatus:'idle',
    summaryPopUpErr:null,
    summaryPopUpMissingCategories: [],
    summaryPageData:[],
    modifySummaryMenu:'',
    isStartOverPopUpActive:false,
    isResetDesign: false,
    isSavePopUpActive:false,
    summarySelectedAssets:[],
    modifySummaryMobileMenu:null,
    designImageSource:'',
    takeDesignScreenshotStatus: 'idle',
    designSummaryImageError: '',
    loadExistingDesignStatus:'idle',
    designShowerErr:null,
    headerMenuClickStatus:null,
    bigImageStatus:'idle',
    bigImageErr:null,
    bigImageSrc:null,
    isSummaryViewActive: false,
    isNewScreenshotNeeded: false,
    mobileMainDivClass:false,
    mobileParentKey:null,
    isLoadDesignPopUpActive: false,
    projectToLoad: [],
    mobileParentKeyIndex:null,
    previousSelectedColor:[],
    orderSelectedSubCat:[],
    categoriesScrollColorSelection:[],
    hasLoadedDesign: false,
}

const domainUrl    =window.config.REACT_APP_PRODUCT_URL;
const perPageData  =window.config.REACT_APP_PER_PAGE;
const requestOptions = {
    auth: {
        username: window.config.REACT_APP_CONSUMER_KEY,
        password: window.config.REACT_APP_CONSUMER_SECRET
    }
};

export const getAllAssetsHeaderMenus = createAsyncThunk('getAllAssets/getAllAssetsHeaderMenus', async (catid) => {
    const CAT_URL = `${domainUrl}/categories/?orderby=id&parent=${catid}`; 
    try {
        const response = await axios.get(CAT_URL, requestOptions);

        if (response.data.length > 0) {
            return response.data;
        }
    } catch(err) {
        return err.message;
    }
});

export const fetchProjectType = createAsyncThunk('getProjectType/getName', async(payload) => {
    let parentCatId = payload;
    try {
        let response, catURL;
        while (parentCatId > 0) {
            catURL = `${domainUrl}/categories/${parentCatId}`;
            response = await axios.get(catURL, requestOptions);
            parentCatId = response.data.parent;
        }

        return response.data;
    } catch (err) {
        return err.message;
    }
});

export const getCategoryProductsData = createAsyncThunk('fetchgetCategoryProductsData/getgetCategoryProductsData', async (payload, thunkAPI) => {
    let allData = thunkAPI.getState().designbathshower.designBathData;
    const category = payload;
    let allCategoryData = [];
    const categoryName = category["translations"] && category["translations"][TranslationKeys[i18next.language]] ?
      category["translations"][TranslationKeys[i18next.language]] :
      category.name;
  
    try {
      let searchSubCategoryResponse = await axios.get(`${domainUrl}/categories/?orderby=id&parent=${category.id}`, requestOptions);
      if (searchSubCategoryResponse.data.length > 0) {
        for (const subCategory of searchSubCategoryResponse.data) {
          let isSearchFinished = false;
          let currentPage = 1;
          let subCategoryData = [];
  
          while (!isSearchFinished) {
            let subCategoryResponse = await axios.get(`${domainUrl}/?page=${currentPage}&category=${subCategory.id}&per_page=${perPageData}&status=publish&orderby=title&order=asc`, requestOptions);
  
            if (subCategoryResponse.data.length > 0) {
              subCategoryData = subCategoryData.concat(subCategoryResponse.data);
              currentPage++;
            } else {
              isSearchFinished = true;
            }
          }

          const subCategoryName = subCategory["translations"] && subCategory["translations"][TranslationKeys[i18next.language]] ? subCategory["translations"][TranslationKeys[i18next.language]] : subCategory.name;
          allCategoryData = { ...allCategoryData, ...{ [subCategoryName]: subCategoryData }};
        }

      } else {
        let isSearchFinished = false;
        let currentPage = 1;
  
        while (!isSearchFinished) {
          let parentCategoryResponse = await axios.get(`${domainUrl}/?page=${currentPage}&category=${category.id}&per_page=${perPageData}&status=publish&orderby=title&order=asc`, requestOptions);
          if (parentCategoryResponse.data.length > 0) {
            allCategoryData = allCategoryData.concat(parentCategoryResponse.data);
            currentPage++;
          } else {
            isSearchFinished = true;
          }
        }
      }

      allData = { ...allData, [categoryName]: allCategoryData };
  
      return allData;
    } catch (error) {
      return error.message;
    }
  });
 
export const getBigImageUrl =createAsyncThunk('getBigImageUrl/fetchBigImageUrl',async(payload,thunkAPI)=>{

    const CAT_URL=domainUrl+'/categories/'+payload.catid;
    
    try
    {
        const response = await axios.get(CAT_URL,requestOptions)
        if(response.data.parent >0 )
        {
            if(response.data.big_image === null)
            {
                const CAT_URL          =domainUrl+'/categories/'+response.data.parent;
                const responseBigImage = await axios.get(CAT_URL,requestOptions)
                return responseBigImage.data.big_image;
            }
            else{
                return response.data.big_image;
            }            
        }
    } 
    catch(err)
    {
        return err.message;
    }
})
// code to get the variant images and details
export const getVariantDetails= createAsyncThunk('fetchVariantDetails/getVariantDetails',async(payload,thunkAPI)=>{ 
     
    const variantUrlAll   = domainUrl + "/" + payload.assetId + "/variations/";
    let variantAllDetails = thunkAPI.getState().designbathshower.variantDetailsData;
    let selectionMade     = thunkAPI.getState().designbathshower.selectedAssetsId;
    let summaryPageData   = thunkAPI.getState().designbathshower.summaryPageData;
    let previousSelectedColor = thunkAPI.getState().designbathshower.previousSelectedColor;

    try {
        const response = await axios.get(variantUrlAll,requestOptions)
        let variationId = payload.variantId;
        let colorName = payload.assetColor;
        let colorHex = payload.assetColorStyle;
        let englishProductInformation = payload.Eng;
        let imageRightUrl;
        if(response.data.length>0)
        {
            let variantDetails = response.data.find(data => data.id === payload.variantId);
            imageRightUrl = variantDetails.image.src;
        }

        // Automatically select previous selected color if there is one
        let previousColor = previousSelectedColor[payload.mainCatName];
        if (previousColor) {
            let colorNameKey = TranslationKeys[i18next.language] ? TranslationKeys[i18next.language] : 'name';
            let variation = Object.values(payload.product_variations).find(variation => variation[colorNameKey] === previousColor);

            if (variation) {
                variationId = variation.id;
                colorName = variation[colorNameKey];
                colorHex = variation.value;
                englishProductInformation['color'] = variation.name;
                imageRightUrl = variation['image_src'];
            }
        }

        let variantArr=[]
         variantArr={[payload.categoryName]:{'mainData':response.data,'otherData':{'assetId':payload.assetId,'variantId':variationId,'assetName':payload.name,'assetColor':colorName,'assetColorStyle':colorHex,'assetImage':payload.assetImage,'selectedCat':payload.categoryName,'rightImageUrl':imageRightUrl,'parentCatName':payload.parentCat,'childLen':payload.childLen,'headerIndex':payload.headerIndex,'catNameEng':payload.mainCatName}}};
        
         let colorVariations
         let summary_data
         let summaryArr={[payload.parentCat]:{'assetId':payload.assetId,'variantId':variationId,'assetName':payload.name,'assetColor':colorName,'assetColorStyle':colorHex,'assetImage':payload.assetImage,'selectedCat':payload.categoryName,'rightImageUrl':imageRightUrl,'parentCatName':payload.parentCat,'childLen':payload.childLen,'Eng':englishProductInformation,'headerIndex':payload.headerIndex}};
         let selectionDone,childSelection
         
         if(payload.childLen===0 || payload.childLen>2)
         {
            
            selectionDone        ={[payload.categoryName]:payload.assetId}

            colorVariations={'mainData':response.data,'otherData':{'assetId':payload.assetId,'variantId':variationId,'assetName':payload.name,'assetColor':colorName,'assetColorStyle':colorHex,'assetImage':payload.assetImage,'selectedCat':payload.parentCat,'rightImageUrl':imageRightUrl,'allDataKey':payload.categoryName,'childLen':payload.childLen,'headerIndex':payload.headerIndex,'catNameEng':payload.mainCatName}};
            thunkAPI.dispatch(getColorVariationsRight(colorVariations))
            
            if(payload.childLen>2)
            {
                childSelection    ={[payload.parentCat]:{[payload.categoryName]:payload.assetId}}
                selectionDone     ={[payload.parentCat]:{...selectionMade[payload.parentCat],...childSelection[payload.parentCat]}}
 
                 let summaryArrChild={[payload.parentCat]:{[payload.categoryName]:{'assetId':payload.assetId,'variantId':variationId,'assetName':payload.name,'assetColor':colorName,'assetColorStyle':colorHex,'assetImage':payload.assetImage,'selectedCat':payload.categoryName,'rightImageUrl':imageRightUrl,'parentCatName':payload.parentCat,'childLen':payload.childLen,'Eng':englishProductInformation,'headerIndex':payload.headerIndex}}}

                 summaryArr={[payload.parentCat]:{...summaryPageData[payload.parentCat],...summaryArrChild[payload.parentCat]}}

                 colorVariations={'mainData':response.data,'otherData':{'assetId':payload.assetId,'variantId':variationId,'assetName':payload.name,'assetColor':colorName,'assetColorStyle':colorHex,'assetImage':payload.assetImage,'selectedCat':payload.parentCat,'rightImageUrl':imageRightUrl,'allDataKey':payload.categoryName,'childLen':payload.childLen,'headerIndex':payload.headerIndex,'catNameEng':payload.mainCatName}};

                 thunkAPI.dispatch(getColorVariationsRight(colorVariations))
    
            }
         }
         else if( payload.childLen<=2){
            selectionDone={[payload.parentCat]:{[payload.categoryName]:payload.assetId}}

            summaryArr={[payload.parentCat]:{[payload.categoryName]:{'assetId':payload.assetId,'variantId':variationId,'assetName':payload.name,'assetColor':colorName,'assetColorStyle':colorHex,'assetImage':payload.assetImage,'selectedCat':payload.categoryName,'rightImageUrl':imageRightUrl,'parentCatName':payload.parentCat,'childLen':payload.childLen,'Eng':englishProductInformation,'headerIndex':payload.headerIndex}}}

             colorVariations={'mainData':response.data,'otherData':{'assetId':payload.assetId,'variantId':variationId,'assetName':payload.name,'assetColor':colorName,'assetColorStyle':colorHex,'assetImage':payload.assetImage,'selectedCat':payload.parentCat,'rightImageUrl':imageRightUrl,'allDataKey':payload.categoryName,'childLen':payload.childLen,'headerIndex':payload.headerIndex,'catNameEng':payload.mainCatName}};

             thunkAPI.dispatch(getColorVariationsRight(colorVariations))

            variantArr={[payload.parentCat]:{'mainData':response.data,'otherData':{'assetId':payload.assetId,'variantId':variationId,'assetName':payload.name,'assetColor':colorName,'assetColorStyle':colorHex,'assetImage':payload.assetImage,'selectedCat':payload.categoryName,'rightImageUrl':imageRightUrl,'parentCatName':payload.parentCat,'childLen':payload.childLen,'headerIndex':payload.headerIndex,'catNameEng':payload.mainCatName}}};
             
         }

        let finalSelection={...selectionMade,...selectionDone}
        thunkAPI.dispatch(setAssetIds(finalSelection))

        summary_data={...summaryPageData,...summaryArr}
        thunkAPI.dispatch(setSummaryPageData(summary_data))

        return {...variantAllDetails,...variantArr}; 
    } 
    catch(err)
    {
        return err.message;
    }
})

export const getAssestVariantImage =createAsyncThunk('fetchAssestVariantImage/getAssestVariantImage',async(payload,thunkAPI)=>{
    const variantUrl = domainUrl + "/" + payload.assetId + "/variations/" + payload.variantId; 
    let variantAllDetails=thunkAPI.getState().designbathshower.variantDetailsData;
    let summaryPageData   = thunkAPI.getState().designbathshower.summaryPageData;

    try {
        const response = await axios.get(variantUrl,requestOptions); 
        let allAssestData;        
        let categoryName;
        if(payload.assestAllData!==undefined)
        { 
            allAssestData = payload.assestAllData.find(data => data.id === payload.assetId);

            categoryName = allAssestData.categories[0][TranslationKeys[i18next.language]] ?
                allAssestData.categories[0][TranslationKeys[i18next.language]] :
                allAssestData.categories[0].name;
            
        }
        let arrColor, summaryArr,arr
        
        if(payload.childLen===0 )
        {
            summaryArr={[categoryName]:{'variantId':payload.variantId,'rightImageUrl':response.data.image.src,'assetName':variantAllDetails[categoryName]['otherData']['assetName'],'assetColor':payload.assestColor,'assetColorStyle':payload.assestColorStyle,'assetImage':variantAllDetails[categoryName]['otherData']['assetImage'],'selectedCat':categoryName,'assetId':payload.assetId ,'parentCatName':payload.parentCat,'childLen':payload.childLen,'Eng':payload.Eng,'headerIndex':payload.headerIndex}};

            arrColor={'mainData':variantAllDetails[categoryName]['mainData'],'otherData':{'variantId':payload.variantId,'rightImageUrl':response.data.image.src,'assetName':variantAllDetails[categoryName]['otherData']['assetName'],'assetColor':payload.assestColor,'assetColorStyle':payload.assestColorStyle,'assetImage':variantAllDetails[categoryName]['otherData']['assetImage'],'selectedCat':categoryName,'assetId':payload.assetId ,'allDataKey':categoryName,'childLen':payload.childLen,'headerIndex':payload.headerIndex,'catNameEng':payload.mainCatName}}

            thunkAPI.dispatch(getColorVariationsRight(arrColor))

            arr={[categoryName]:{'mainData':variantAllDetails[categoryName]['mainData'],'otherData':{'variantId':payload.variantId,'rightImageUrl':response.data.image.src,'assetName':variantAllDetails[categoryName]['otherData']['assetName'],'assetColor':payload.assestColor,'assetColorStyle':payload.assestColorStyle,'assetImage':variantAllDetails[categoryName]['otherData']['assetImage'],'selectedCat':categoryName,'assetId':payload.assetId,'parentCatName':payload.parentCat,'childLen':payload.childLen,'headerIndex':payload.headerIndex,'catNameEng':payload.mainCatName}}}
        }
       
        if(payload.childLen>2)
        {
            
            let summaryArrChild={[payload.parentCat]:{[categoryName]:{'variantId':payload.variantId,'rightImageUrl':response.data.image.src,'assetName':variantAllDetails[categoryName]['otherData']['assetName'],'assetColor':payload.assestColor,'assetColorStyle':payload.assestColorStyle,'assetImage':variantAllDetails[categoryName]['otherData']['assetImage'],'selectedCat':categoryName,'assetId':payload.assetId ,'parentCatName':payload.parentCat,'childLen':payload.childLen,'Eng':payload.Eng,'headerIndex':payload.headerIndex}}};

            summaryArr={[payload.parentCat]:{...summaryPageData[payload.parentCat],...summaryArrChild[payload.parentCat]}}

            arrColor={'mainData':variantAllDetails[categoryName]['mainData'],'otherData':{'variantId':payload.variantId,'rightImageUrl':response.data.image.src,'assetName':variantAllDetails[categoryName]['otherData']['assetName'],'assetColor':payload.assestColor,'assetColorStyle':payload.assestColorStyle,'assetImage':variantAllDetails[categoryName]['otherData']['assetImage'],'selectedCat':payload.parentCat,'assetId':payload.assetId ,'allDataKey':categoryName,'childLen':payload.childLen,'headerIndex':payload.headerIndex,'catNameEng':payload.mainCatName}}
              
            thunkAPI.dispatch(getColorVariationsRight(arrColor))

            arr={[categoryName]:{'mainData':variantAllDetails[categoryName]['mainData'],'otherData':{'variantId':payload.variantId,'rightImageUrl':response.data.image.src,'assetName':variantAllDetails[categoryName]['otherData']['assetName'],'assetColor':payload.assestColor,'assetColorStyle':payload.assestColorStyle,'assetImage':variantAllDetails[categoryName]['otherData']['assetImage'],'selectedCat':categoryName,'assetId':payload.assetId,'parentCatName':payload.parentCat,'childLen':payload.childLen,'headerIndex':payload.headerIndex,'catNameEng':payload.mainCatName}}}
        }
        else if(payload.childLen <=2 && payload.childLen !==0)
        {
            
            let summaryArrChild={[payload.parentCat]:{[categoryName]:{'variantId':payload.variantId,'rightImageUrl':response.data.image.src,'assetName':variantAllDetails[payload.parentCat]['otherData']['assetName'],'assetColor':payload.assestColor,'assetColorStyle':payload.assestColorStyle,'assetImage':variantAllDetails[payload.parentCat]['otherData']['assetImage'],'selectedCat':categoryName,'assetId':payload.assetId ,'parentCatName':payload.parentCat,'childLen':payload.childLen,'Eng':payload.Eng,'headerIndex':payload.headerIndex}}};

            summaryArr=summaryArrChild

            arrColor={'mainData':variantAllDetails[payload.parentCat]['mainData'],'otherData':{'variantId':payload.variantId,'rightImageUrl':response.data.image.src,'assetName':variantAllDetails[payload.parentCat]['otherData']['assetName'],'assetColor':payload.assestColor,'assetColorStyle':payload.assestColorStyle,'assetImage':variantAllDetails[payload.parentCat]['otherData']['assetImage'],'selectedCat':payload.parentCat,'assetId':payload.assetId ,'allDataKey':categoryName,'childLen':payload.childLen,'headerIndex':payload.headerIndex,'catNameEng':payload.mainCatName}}

            thunkAPI.dispatch(getColorVariationsRight(arrColor))
          
            arr={[payload.parentCat]:{'mainData':variantAllDetails[payload.parentCat]['mainData'],'otherData':{'variantId':payload.variantId,'rightImageUrl':response.data.image.src,'assetName':variantAllDetails[payload.parentCat]['otherData']['assetName'],'assetColor':payload.assestColor,'assetColorStyle':payload.assestColorStyle,'assetImage':variantAllDetails[payload.parentCat]['otherData']['assetImage'],'selectedCat':categoryName,'assetId':payload.assetId,'parentCatName':payload.parentCat,'childLen':payload.childLen,'headerIndex':payload.headerIndex,'catNameEng':payload.mainCatName}}}
        }
        let summary_data={...summaryPageData,...summaryArr}
        thunkAPI.dispatch(setSummaryPageData(summary_data))

        return {...variantAllDetails,...arr}; 
        
    } 
    catch(err)
    {
        return err.message;
    }
})

export const getIsShowMissingProductsPopUp = createAsyncThunk('showSummary/getIsShowMissingProductsPopUp', async(payload, thunkAPI) => {
    let designBathData = thunkAPI.getState().designbathshower.designBathData;
    let selectedIds = thunkAPI.getState().designbathshower.variantDetailsData;
    // Construct array with only selected category names
    let selectionMade = Object.keys(selectedIds);

    let missingCategories = [];
    Object.keys(designBathData).forEach(category => {
        let categoryValues = Object.values(designBathData[category]);
        if (categoryValues.every(categoryValue => Array.isArray(categoryValue))) {
            // Category has sub-categories
            if (categoryValues.length > 2) {
                // Accessories
                Object.keys(designBathData[category]).forEach(subCategory => {
                    if (!selectionMade.includes(subCategory)) {
                        return missingCategories.push(subCategory);
                    }
                });
            } else {
                // Doors/Rods so do the same as parent category
                if (!selectionMade.includes(category)) {
                    return missingCategories.push(category);
                }
            }
        } else {
            // Parent category
            if (!selectionMade.includes(category)) {
                return missingCategories.push(category);
            }
        }
    });
    return missingCategories;
});

export const takeDesignScreenshot = createAsyncThunk('design/takeScreenshot', async(payload) => {
    const {isMobile} = payload;
    return await getDesignImage(isMobile);
});

export const loadDesignData = createAsyncThunk('loadDesign/fetchDesignData', async(payload, thunkAPI) => {
    try {
        const response = await axios.get(`${window.config.REACT_APP_LOAD_DESIGN_URL}=${payload}`);

        // Shared designs can't get overwritten
        if (response.data && response.data['platform'] !== 'share') {
            thunkAPI.dispatch(setDesignId(payload));
        }

        const allDataRow = JSON.parse(response.data.all_data);
        const allProducts = allDataRow.allData;
        const keys = Object.keys(allProducts);

        let allProductIds, allProductSummaryInfos, allProductsInfos;
        
        for (const key of keys) {
            // get missing infos (parent category name translated, product name translated, etc.)
            const productResponse = await axios.get(`${domainUrl}/${allProducts[key].otherData.assetId}`, requestOptions);

            let i18nKey = TranslationKeys[i18next.language] ?
                TranslationKeys[i18next.language] :
                'name';

            let categoryNameI18n = productResponse.data.categories[0][i18nKey];
            
            let productName = TranslationKeys[i18next.language] ? 
                productResponse.data.meta_data.find(metaInfos => metaInfos.key === TranslationKeys[i18next.language]).value : 
                productResponse.data.name;

            let variantId = allProducts[key].otherData.variantId;
            let variation = productResponse.data.product_variations.find(variation => variation.id === variantId);

            let productEnglishInfo = {};
            productEnglishInfo['category'] = productResponse.data.categories[0].name;
            productEnglishInfo['product_name'] = productResponse.data.name;
            productEnglishInfo['color'] = variation ? variation.name : null;

            let productSelection, productInfos, productSummaryInfos;

            if (allProducts[key].otherData.childLen === 0) { // Parent category
                productInfos = {
                    [categoryNameI18n]: {
                        'mainData': allProducts[key].mainData,
                        'otherData': {
                            'assetId': allProducts[key].otherData.assetId,
                            'variantId': variantId,
                            'assetName': productName,
                            'assetColor': variation[i18nKey],
                            'assetColorStyle': allProducts[key].otherData.assetColorStyle,
                            'assetImage': allProducts[key].otherData.assetImage,
                            'selectedCat': categoryNameI18n,
                            'rightImageUrl': variation.image_src,
                            'parentCatName': categoryNameI18n,
                            'childLen': allProducts[key].otherData.childLen,
                            'headerIndex': allProducts[key].otherData.headerIndex,
                            'catNameEng': allProducts[key].otherData.catNameEng
                        }
                    }
                };

                productSummaryInfos = {
                    [categoryNameI18n]: {
                        'assetId': allProducts[key].otherData.assetId,
                        'variantId': variantId,
                        'assetName': productName,
                        'assetColor': variation[i18nKey],
                        'assetColorStyle': allProducts[key].otherData.assetColorStyle,
                        'assetImage': allProducts[key].otherData.assetImage,
                        'selectedCat': categoryNameI18n,
                        'rightImageUrl': variation.image_src,
                        'parentCatName': categoryNameI18n,
                        'childLen': allProducts[key].otherData.childLen,
                        'headerIndex': allProducts[key].otherData.headerIndex,
                        'Eng': productEnglishInfo,
                    }
                };

                productSelection = {[categoryNameI18n]: allProducts[key].otherData.assetId};
                thunkAPI.dispatch(setOrderSelectedSubCat({
                    'parentCat'     : categoryNameI18n,
                    'categoryName'  : categoryNameI18n,
                    'assetId'       : allProducts[key].otherData.assetId,
                  }));

            } else if (allProducts[key].otherData.childLen > 2) { // Accecorries
                let categoryId = productResponse.data.categories[0].id;
                let categoryResponse = await axios.get(`${domainUrl}/categories/${categoryId}`, requestOptions);
                let parentId = categoryResponse.data.parent;
                let parentCategoryResponse = await axios.get(`${domainUrl}/categories/${parentId}`, requestOptions);
                let parentCatNameI18n = parentCategoryResponse.data.translations[i18nKey] ? parentCategoryResponse.data.translations[i18nKey] : parentCategoryResponse.data.name;
                
                productInfos = {
                    [categoryNameI18n]: {
                        'mainData': allProducts[key].mainData,
                        'otherData': {
                            'assetId': allProducts[key].otherData.assetId,
                            'variantId': variantId,
                            'assetName': productName,
                            'assetColor': variation[i18nKey],
                            'assetColorStyle': allProducts[key].otherData.assetColorStyle,
                            'assetImage': allProducts[key].otherData.assetImage,
                            'selectedCat': categoryNameI18n,
                            'rightImageUrl': variation.image_src,
                            'parentCatName': parentCatNameI18n,
                            'childLen': allProducts[key].otherData.childLen,
                            'headerIndex': allProducts[key].otherData.headerIndex,
                            'catNameEng': allProducts[key].otherData.catNameEng
                        }
                    }
                };
                
                productSummaryInfos = {
                    [parentCatNameI18n]: {
                        ...allProductSummaryInfos[parentCatNameI18n],
                        ...{
                            [categoryNameI18n]: {
                                'assetId': allProducts[key].otherData.assetId,
                                'variantId': variantId,
                                'assetName': productName,
                                'assetColor': variation[i18nKey],
                                'assetColorStyle': allProducts[key].otherData.assetColorStyle,
                                'assetImage': allProducts[key].otherData.assetImage,
                                'selectedCat': categoryNameI18n,
                                'rightImageUrl': variation.image_src,
                                'parentCatName': parentCatNameI18n,
                                'childLen': allProducts[key].otherData.childLen,
                                'headerIndex': allProducts[key].otherData.headerIndex,
                                'Eng': productEnglishInfo
                            }
                        }
                    }
                };

                productSelection = {
                    [parentCatNameI18n]: {
                        ...allProductIds[parentCatNameI18n],
                        ...{
                            [categoryNameI18n]: allProducts[key].otherData.assetId
                        }
                    }
                };

                thunkAPI.dispatch(setOrderSelectedSubCat({
                    'parentCat'     : parentCatNameI18n,
                    'categoryName'  : categoryNameI18n,
                    'assetId'       : allProducts[key].otherData.assetId,
                  })
                );

            } else { // Doors/Rods
                let categoryId = productResponse.data.categories[0].id;
                let categoryResponse = await axios.get(`${domainUrl}/categories/${categoryId}`, requestOptions);
                let parentId = categoryResponse.data.parent;
                let parentCategoryResponse = await axios.get(`${domainUrl}/categories/${parentId}`, requestOptions);
                let parentCatNameI18n = parentCategoryResponse.data.translations[i18nKey] ? parentCategoryResponse.data.translations[i18nKey] : parentCategoryResponse.data.name;

                productInfos = {
                    [parentCatNameI18n]: {
                        'mainData': allProducts[key].mainData,
                        'otherData': {
                            'assetId': allProducts[key].otherData.assetId,
                            'variantId': variantId,
                            'assetName': productName,
                            'assetColor': variation[i18nKey],
                            'assetColorStyle': allProducts[key].otherData.assetColorStyle,
                            'assetImage' :allProducts[key].otherData.assetImage,
                            'selectedCat': categoryNameI18n,
                            'rightImageUrl': variation.image_src,
                            'parentCatName': parentCatNameI18n,
                            'childLen': allProducts[key].otherData.childLen,
                            'headerIndex': allProducts[key].otherData.headerIndex,
                            'catNameEng': allProducts[key].otherData.catNameEng
                        }
                    }
                };
        
                productSummaryInfos = {
                    [parentCatNameI18n]: {
                        [categoryNameI18n]: {
                            'assetId': allProducts[key].otherData.assetId,
                            'variantId': variantId,
                            'assetName': productName,
                            'assetColor': variation[i18nKey],
                            'assetColorStyle': allProducts[key].otherData.assetColorStyle,
                            'assetImage': allProducts[key].otherData.assetImage,
                            'selectedCat': categoryNameI18n,
                            'rightImageUrl': variation.image_src,
                            'parentCatName': parentCatNameI18n,
                            'childLen': allProducts[key].otherData.childLen,
                            'headerIndex': allProducts[key].otherData.headerIndex,
                            'Eng': productEnglishInfo
                        }
                    }
                }
                
                productSelection = {
                    [parentCatNameI18n]: {
                        [categoryNameI18n]: allProducts[key].otherData.assetId
                    }
                };

                thunkAPI.dispatch(setOrderSelectedSubCat({
                    'parentCat'     : parentCatNameI18n,
                    'categoryName'  : categoryNameI18n,
                    'assetId'       : allProducts[key].otherData.assetId,
                  })
                );
            }

            allProductIds = { ...allProductIds, ...productSelection };
            allProductSummaryInfos = { ...allProductSummaryInfos, ...productSummaryInfos };
            allProductsInfos = { ...allProductsInfos, ...productInfos };
        }

        thunkAPI.dispatch(setAssetIds(allProductIds));
        thunkAPI.dispatch(setSummaryPageData(allProductSummaryInfos));

        return allProductsInfos;
    } catch(err) {
        return err.message;
    }
})

export const designBathShowerSlice= createSlice({
    name:'designBathShower',
    initialState,
    // define reducer and auto generate the actions
    reducers:{
        setAssetIds:(state,action)=>{   
            state.selectedAssetsId=action.payload
        },
        getColorVariationsRight:(state,action)=>{ 
            state.colorVariationsRight=action.payload.mainData
            state.colorVarinatOtherData=action.payload.otherData
        },
        setProductRightVariationOptions: (state, action) => {
            if (action.payload.childLen > 2) {
                state.colorVariationsRight = state.variantDetailsData[action.payload.categoryName]['mainData'];
            } else {
                state.colorVariationsRight = state.variantDetailsData[action.payload.parentCat]['mainData'];
            }
            state.colorVarinatOtherData = {
                'variantId': action.payload.variantId,
                'rightImageUrl': action.payload.rightImageUrl,
                'assetName': action.payload.name,
                'assetColor': action.payload.assetColor,
                'assetColorStyle': action.payload.assestColorStyle,
                'assetImage': action.payload.assetImage,
                'selectedCat': action.payload.parentCat,
                'assetId': action.payload.assetId,
                'allDataKey': action.payload.categoryName,
                'childLen': action.payload.childLen,
                'headerIndex': action.payload.headerIndex,
                'catNameEng': action.payload.mainCatName
            };
        },
        getSummaryPopUpStatusClose: (state) => {
            state.isMissingCategoriesPopUpActive = false;
        },
        modifySummaryAssest:(state,action)=>{
            state.modifySummaryMenu=action.payload
            state.isSummaryViewActive = false;
            state.headerMenuClickStatus=action.payload
        },
        resetSummaryMenu:(state)=>{
            state.modifySummaryMenu=''
        },
        openStartOverPopUp : (state) => {
            state.isStartOverPopUpActive = true;
            state.isResetDesign = false;
        },
        openResetDesignPopUp : (state) => {
            state.isStartOverPopUpActive = true
            state.isResetDesign = true;
        },
        closeStartOverPopUp: (state) => {
            state.isStartOverPopUpActive = false;
        },
        openSavePopUp: (state) => {
            state.isSavePopUpActive = true;
        },
        closeSavePopUp: (state) => {
            state.isSavePopUpActive = false;
        },
        goSummaryPageDesign: (state) => {
            state.isMissingCategoriesPopUpActive = false;
            state.isSummaryViewActive = true;
        },
        modifySummaryAssestMobile: (state, action) => {
            state.isMissingCategoriesPopUpActive = false;
            state.isSummaryViewActive = false;
            state.modifySummaryMobileMenu = action.payload;
        },
        setHandleHeaderMenuClick:(state,action)=>{
            state.headerMenuClickStatus=action.payload
            state.isSummaryViewActive = false;
        },
        backToDesign: (state) => {
            state.isSummaryViewActive = false;
            state.modifySummaryMobileMenu = false;
        },
        startOverClearState:(state)=>{
            state.colorVariationsRight  = [];
            state.colorVarinatOtherData = []; 
            state.summaryPageData=[]
            state.variantDetailsData=[]
        },
        setMobileParentKey : (state, action) => {
            state.mobileParentKey = action.payload;
        },
        setMobileParentKeyIndex :(state,action) =>{
            state.mobileParentKeyIndex=action.payload
        },
        setPreviousSelectedColor:(state,action)=>{
            state.previousSelectedColor={...state.previousSelectedColor,...{[action.payload.categoryName]:action.payload.colorName}}
        },
        setOrderSelectedSubCat: (state, action) => {
            const { parentCat, categoryName, assetId } = action.payload;
            state.orderSelectedSubCat = {...state.orderSelectedSubCat,[parentCat]: {...state.orderSelectedSubCat[parentCat],[categoryName]: assetId}};
        },
        removeSelectedSubCat: (state, action) => {
            const { parentCat,subCategory,childLen } = action.payload;
            const updatedOrderSelectedSubCat = { ...state.orderSelectedSubCat };
            if (updatedOrderSelectedSubCat[parentCat]) {
              if (childLen === 2) {
                delete updatedOrderSelectedSubCat[parentCat];
              } 
              else {
                delete updatedOrderSelectedSubCat[parentCat][subCategory];
                if (Object.values(updatedOrderSelectedSubCat[parentCat]).length === 0) {
                  delete updatedOrderSelectedSubCat[parentCat];
                }
              }
            }
            state.orderSelectedSubCat = updatedOrderSelectedSubCat;
        },
        setCategoriesScrollColorSelection: (state, action) => {
            let updatedCategoriesScrollColorSelection;
            if (!Object.keys(state.categoriesScrollColorSelection).includes(action.payload)) {
              updatedCategoriesScrollColorSelection = { ...state.categoriesScrollColorSelection, ...{ [action.payload]: false }};
            } else {
              updatedCategoriesScrollColorSelection = { ...state.categoriesScrollColorSelection, ...{ [action.payload]: true }};
            }
            state.categoriesScrollColorSelection = updatedCategoriesScrollColorSelection;
        },
        setSummaryPageData: (state, action) => {
            state.summaryPageData = action.payload;
        },
        resetAllState :(state) => {            
            state.status           ='idle';
            state.error            =null;
            state.allAssestsData   =[];
            state.projectType = [];
            state.designstatus     ="idle";
            state.designBathData   =[] 
            state.getChildErr      =null
            state.variantDetailStatus ='idle'
            state.variantDetailsData =[]
            state.selectedAssetsId  =[]
            state.assestVarianttImageStatus ='idle'
            state.colorVariationsRight=[]
            state.colorVarinatOtherData=[]
            state.isMissingCategoriesPopUpActive = false;
            state.summaryPopUpStatus='idle'
            state.summaryPopUpErr=null
            state.summaryPopUpMissingCategories = [];
            state.summaryPageData=[]
            state.modifySummaryMenu=''
            state.isStartOverPopUpActive=false
            state.isSavePopUpActive=false
            state.modifySummaryMobileMenu=null
            state.designImageSource = '';
            state.takeDesignScreenshotStatus = 'idle';
            state.designSummaryImageError= ''
            state.loadExistingDesignStatus='idle'
            state.designShowerErr=null
            state.headerMenuClickStatus=null
            state.bigImageStatus='idle'
            state.bigImageErr=null
            state.bigImageSrc=null
            state.isSummaryViewActive = false;
            state.isNewScreenshotNeeded = false;
            state.mobileMainDivClass=false
            state.mobileParentKey    =null
            state.previousSelectedColor=[]
            state.orderSelectedSubCat=[]
            state.categoriesScrollColorSelection=[]
            state.hasLoadedDesign=false
        },
        openLoadExistingDesignPopUp: (state, action) => {
            state.isLoadDesignPopUpActive = true;
            state.projectToLoad = action.payload;
        },
        closeLoadExistingDesignPopUp: (state) => {
            state.isLoadDesignPopUpActive = false;
            state.projectToLoad = [];
        },
        setIsLoadInSummary: (state, action) => {
            state.isSummaryViewActive = action.payload;
        },
        clearEntireCategory: (state, action) => {
            // Clear entire category from dictionnaries
            const { categoryName, subCategoryNames } = action.payload;

            // Variant details dictionnary
            let variantDetailsData = {...state.variantDetailsData};
            if (subCategoryNames) {
                subCategoryNames.forEach((subCategoryName) => {
                    if (variantDetailsData.hasOwnProperty(subCategoryName)) {
                        delete variantDetailsData[subCategoryName];
                    }
                });
            } else if (variantDetailsData.hasOwnProperty(categoryName)) {
                delete variantDetailsData[categoryName];
            }
            state.variantDetailsData = variantDetailsData;

            // Selected asset Ids dictionnary
            let selectedAssetsId = {...state.selectedAssetsId};
            if (selectedAssetsId.hasOwnProperty(categoryName)) {
                delete selectedAssetsId[categoryName];
            }
            state.selectedAssetsId = selectedAssetsId;
            
            // Summary page dictionnary
            let summaryPageData = {...state.summaryPageData};
            if (summaryPageData.hasOwnProperty(categoryName)) {
                delete summaryPageData[categoryName];
            }
            state.summaryPageData = summaryPageData;

            // Ordered selected categories
            let orderSelectedSubCat = { ...state.orderSelectedSubCat };
            if (orderSelectedSubCat.hasOwnProperty(categoryName)) {
                delete orderSelectedSubCat[categoryName];
            }
            state.orderSelectedSubCat = orderSelectedSubCat;

            state.colorVariationsRight  = [];
            state.colorVarinatOtherData = [];
            state.isNewScreenshotNeeded = true;
        },
        removeSingleProduct: (state, action) => {
            // Remove single product from dictionnaries
            const { categoryName, subCategoryName } = action.payload;

            // Variant details dictionnary
            let variantDetailsData = {...state.variantDetailsData};
            if (subCategoryName && variantDetailsData.hasOwnProperty(subCategoryName)) {
                delete variantDetailsData[subCategoryName];
            } else if (variantDetailsData.hasOwnProperty(categoryName)) {
                delete variantDetailsData[categoryName];
            }
            state.variantDetailsData = variantDetailsData;

            // Selected asset Ids dictionnary
            let selectedAssetsId = {...state.selectedAssetsId};
            if (subCategoryName && selectedAssetsId[categoryName].hasOwnProperty(subCategoryName)) {
                delete selectedAssetsId[categoryName][subCategoryName];

                if (Object.keys(selectedAssetsId[categoryName]).length === 0) {
                    delete selectedAssetsId[categoryName];
                }
            } else {
                delete selectedAssetsId[categoryName];
            }
            state.selectedAssetsId = selectedAssetsId;

            // Summary page dictionnary
            let summaryPageData = {...state.summaryPageData};
            if (subCategoryName && summaryPageData[categoryName].hasOwnProperty(subCategoryName)) {
                delete summaryPageData[categoryName][subCategoryName];

                if (Object.keys(summaryPageData[categoryName]).length === 0) {
                    delete summaryPageData[categoryName];
                }
            } else if (summaryPageData.hasOwnProperty(categoryName)) {
                delete summaryPageData[categoryName];
            }
            state.summaryPageData = summaryPageData;

            // Ordered selected categories
            let orderSelectedSubCat = { ...state.orderSelectedSubCat };
            if (subCategoryName && orderSelectedSubCat[categoryName].hasOwnProperty(subCategoryName)) {
                delete orderSelectedSubCat[categoryName][subCategoryName];

                if (Object.keys(orderSelectedSubCat[categoryName]).length === 0) {
                    delete orderSelectedSubCat[categoryName];
                }
            } else if (orderSelectedSubCat.hasOwnProperty(categoryName)) {
                delete orderSelectedSubCat[categoryName];
            }
            state.orderSelectedSubCat = orderSelectedSubCat;

            state.colorVariationsRight  = [];
            state.colorVarinatOtherData = [];
            state.isNewScreenshotNeeded = true;
        }
    },
    extraReducers:(builder)=>           
        {
        builder
        .addCase(getAllAssetsHeaderMenus.pending, (state) => {
            state.status           = 'loading';
        })
        .addCase(getAllAssetsHeaderMenus.fulfilled, (state, action) => {
            state.status          =  'idle';             
            const allData         =  action.payload;             
            state.allAssestsData  =  allData;
        })
        .addCase(getAllAssetsHeaderMenus.rejected, (state,action) =>{
            state.status               = 'failed'
            state.error                =  action.error.message
        })
        .addCase(fetchProjectType.pending, (state) => {
            state.status = 'loading';
        })
        .addCase(fetchProjectType.fulfilled, (state, action) => {
            state.status = 'idle';
            state.projectType = action.payload;
        })
        .addCase(fetchProjectType.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        })
        .addCase(getCategoryProductsData.pending, (state) => {
            state.designstatus           = 'loading';
        })
        .addCase(getCategoryProductsData.fulfilled, (state, action) => {
            state.designstatus = 'success';
            state.designBathData = action.payload;
        })
        .addCase(getCategoryProductsData.rejected, (state) =>{
            state.designstatus         = 'failed'
        })
        
        // details to get the variant images data
        .addCase(getVariantDetails.pending, (state) => {
            state.variantDetailStatus      = 'loading';
        })
        .addCase(getVariantDetails.fulfilled, (state, action) => {
            state.variantDetailStatus     =  'success';          
            const variantAllData          =  action.payload;                   
            state.variantDetailsData      =  variantAllData;
            state.isNewScreenshotNeeded = true;
            state.mobileMainDivClass=true;
        })
        .addCase(getVariantDetails.rejected, (state) =>{
            state.variantDetailStatus    = 'failed'
        })
        .addCase(getAssestVariantImage.pending, (state) => {
            state.assestVarianttImageStatus      = 'loading';
        })
        .addCase(getAssestVariantImage.fulfilled, (state, action) => {
            state.assestVarianttImageStatus     =  'succes';             
            const assestVariantAllData          =  action.payload; 
            state.variantDetailsData            =  assestVariantAllData;
            state.isNewScreenshotNeeded = true;
        })
        .addCase(getAssestVariantImage.rejected, (state) =>{
            state.assestVarianttImageStatus    = 'failed'
        })
        .addCase(getIsShowMissingProductsPopUp.pending, (state) => {
            state.summaryPopUpStatus = 'loading';
        })
        .addCase(getIsShowMissingProductsPopUp.fulfilled, (state, action) => {
            state.summaryPopUpStatus = 'idle';
            if (action.payload.length === 0) {
                // All categories selected so show summary view right away
                state.isMissingCategoriesPopUpActive = false;
                state.isSummaryViewActive = true;
             } else {
                // Some categories are missing so show summary missing category pop-up
                state.isMissingCategoriesPopUpActive = true;
            }
            state.summaryPopUpMissingCategories = action.payload;
        })
        .addCase(getIsShowMissingProductsPopUp.rejected, (state, action) => {
            state.summaryPopUpStatus = 'failed';
            state.summaryPopUpErr = action.error.message;
        })
        .addCase(takeDesignScreenshot.pending, (state) => {
            state.takeDesignScreenshotStatus = 'loading';
        })
        .addCase(takeDesignScreenshot.fulfilled, (state, action) => {
            state.takeDesignScreenshotStatus = 'idle';
            state.designImageSource = action.payload;
            state.isNewScreenshotNeeded = false;
        })
        .addCase(takeDesignScreenshot.rejected, (state, action) => {
            state.takeDesignScreenshotStatus = 'failed';
            state.designImageSource = '';
            state.designSummaryImageError = action.error.message;
        })
        .addCase(loadDesignData.pending, (state) => {
            state.loadExistingDesignStatus = 'loading';
        })
        .addCase(loadDesignData.fulfilled, (state, action) => {
            state.loadExistingDesignStatus = 'idle'; 
            state.variantDetailsData = action.payload;
            state.variantDetailStatus = 'success'; 
            state.isNewScreenshotNeeded = true;
            state.hasLoadedDesign = true;
        })
        .addCase(loadDesignData.rejected, (state,action) =>{
            state.loadExistingDesignStatus = 'failed'
            state.designShowerErr    =  action.error.message
        })
        .addCase(getBigImageUrl.pending, (state) => {
            state.bigImageStatus = 'loading';
        })
        .addCase(getBigImageUrl.fulfilled, (state, action) => {
            state.bigImageStatus = 'idle';
            state.bigImageSrc = action.payload;
        })
        .addCase(getBigImageUrl.rejected, (state,action) =>{
            state.bigImageStatus = 'failed'
            state.bigImageErr    =  action.error.message
        })
    },
});

export const {  
    setAssetIds,
    getColorVariationsRight,
    setProductRightVariationOptions,
    getSummaryPopUpStatusClose,
    getOnLoadAssestStatus,
    modifySummaryAssest,
    resetSummaryMenu,
    openStartOverPopUp,
    closeStartOverPopUp,
    openResetDesignPopUp,
    openSavePopUp,
    closeSavePopUp,
    goSummaryPageDesign,
    modifySummaryAssestMobile,
    setHandleHeaderMenuClick,
    backToDesign,
    startOverClearState,
    setMobileParentKey,
    resetAllState,
    openLoadExistingDesignPopUp,
    closeLoadExistingDesignPopUp,
    sharePopUpStatusWeb,
    setIsLoadInSummary,
    setMobileParentKeyIndex,
    setPreviousSelectedColor,
    setOrderSelectedSubCat,
    removeSelectedSubCat,
    setCategoriesScrollColorSelection,
    setSummaryPageData,
    clearEntireCategory,
    removeSingleProduct,
} = designBathShowerSlice.actions;

export const allDesignBathData                      = (state) => state.designbathshower.designBathData;
export const getAssetsErrors                        = (state) => state.designbathshower.error;
export const getAssetsStatus                        = (state) => state.designbathshower.status;
export const getBigImageSrcUrl                      = (state) => state.designbathshower.bigImageSrc;
export const getCategoriesScrollColorSelection      = (state) => state.designbathshower.categoriesScrollColorSelection;
export const getClickedMenuVal                      = (state) => state.designbathshower.headerMenuClickStatus;
export const getColorOtherData                      = (state) => state.designbathshower.colorVarinatOtherData;
export const getColorVariRight                      = (state) => state.designbathshower.colorVariationsRight;
export const getTakeDesignScreenshotStatus          = (state) => state.designbathshower.takeDesignScreenshotStatus;
export const getDesignImageSource                   = (state) => state.designbathshower.designImageSource;
export const getDesignStatus                        = (state) => state.designbathshower.designstatus;
export const getIsLoadDesignPopUpActive             = (state) => state.designbathshower.isLoadDesignPopUpActive;
export const getIsResetDesign                       = (state) => state.designbathshower.isResetDesign;
export const getIsStartOverPopUpActive              = (state) => state.designbathshower.isStartOverPopUpActive;
export const getIsSummaryViewActive                 = (state) => state.designbathshower.isSummaryViewActive;
export const getLoaderVariantChange                 = (state) => state.designbathshower.assestVarianttImageStatus;
export const getLoadExistingDesignStatus            = (state) => state.designbathshower.loadExistingDesignStatus;
export const getMobileDivClassCheck                 = (state) => state.designbathshower.mobileMainDivClass;
export const getModifySummaryMenuVal                = (state) => state.designbathshower.modifySummaryMobileMenu;
export const getMoodifySummaryMenu                  = (state) => state.designbathshower.modifySummaryMenu;
export const getOrderSelectedSubCat                 = (state) => state.designbathshower.orderSelectedSubCat;
export const getParentKey                           = (state) => state.designbathshower.mobileParentKey;
export const getParentKeyIndex                      = (state) => state.designbathshower.mobileParentKeyIndex;
export const getProjectToLoad                       = (state) => state.designbathshower.projectToLoad;
export const getProjectType                         = (state) => state.designbathshower.projectType;
export const getSelectedAssetsID                    = (state) => state.designbathshower.selectedAssetsId;
export const getIsSavePopUpActive                   = (state) => state.designbathshower.isSavePopUpActive;
export const getIsNewScreenshotNeeded               = (state) => state.designbathshower.isNewScreenshotNeeded;
export const getSummaryMissingData                  = (state) => state.designbathshower.summaryPopUpMissingCategories;
export const getSummaryPageData                     = (state) => state.designbathshower.summaryPageData;
export const getVariantAllData                      = (state) => state.designbathshower.variantDetailsData;
export const getVariantDetailStatus                 = (state) => state.designbathshower.variantDetailStatus;
export const selectAllAssests                       = (state) => state.designbathshower.allAssestsData;
export const summaryPopUpStatus                     = (state) => state.designbathshower.isMissingCategoriesPopUpActive;
export const getHasLoadedDesign                     = (state) => state.designbathshower.hasLoadedDesign;

export default designBathShowerSlice.reducer;