import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

i18n
  .use(initReactI18next)
  .use(LanguageDetector) // Language detection plugin used to detect user language up to 8 different ways
  .use(HttpApi) // Load resources from a backend server using the XMLHttpRequest or the fetch API
  .init({
    supportedLngs: ["en", "fr", "es"],
    fallbackLng: "en",
    detection: {
        order: ["path", "querystring", "cookie", "navigator", "htmlTag"],
        caches: ["cookie"]
    },
    backend: {
        loadPath: "/assets/i18n/{{lng}}.json"
    }
  });

export default i18n;

export const TranslationKeys = {
  'fr': "name_fr",
  'es': "name_es"
};