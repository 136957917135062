import React from 'react'
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { allDesignBathData, getDesignStatus, getVariantDetailStatus, getVariantAllData } from './designBathShowerSlice'
import DesingBathShowerData from './DesingBathShowerData';
import SummaryPageLink from './SummaryPageLink';
import LoaderImg from '../../images/loader.gif'
import { getMissingMinimumSelection } from '../designbathshowerpopup/MinimumSelection'

const DesignBathShowerList  = () => {
const finalData             = useSelector(allDesignBathData);
const designStatus          = useSelector(getDesignStatus);

  const checkIfSelectionDone = useSelector(getVariantDetailStatus);
  const minimumSelect = useSelector(getVariantAllData);

  const location = useLocation();
  const projectType = location.pathname.includes('design-your-own-bathroom') ? 'bathtub' : 'shower';
  let showSummaryButton;
  if (checkIfSelectionDone==='success') {
    let missingCategories = getMissingMinimumSelection(Object.keys(minimumSelect), projectType);
    if (missingCategories.length > 0) {
      showSummaryButton = false;
    } else {
      showSummaryButton = true;
    }
  }

  let content;
  if (Object.keys(finalData).length > 0) {
    content = Object.entries(finalData).map(([key, assest_data],indexKey) => <DesingBathShowerData key={key} index={key} finalData={assest_data} indexKey={indexKey} />)
  }

  return (
    <>
      {content}
      {
        (designStatus==='loading')?(<img src={LoaderImg} alt='' id="categoryLoader"/>):''
      }
      {
        showSummaryButton===true ? (<SummaryPageLink/> ):'' 
      }
    </>
  )
}

export default DesignBathShowerList