export async function getDesignImage(isMobile) {
    // Get all images for design screenshot
    var elementId = isMobile ? 'full-design-mobile' : 'full-design';
    var designDiv = document.getElementById(elementId);
    var designImages = Array.from(designDiv.getElementsByTagName('img'));
    
    // Custom sorting function
    function customSort(a, b) {
        // Compare z-index values, but keep old bathroom image first
        if (a === designImages[0]) {
            return -1; // 'a' should come before 'b'
        } else if (b === designImages[0]) {
            return 1; // 'b' should come before 'a'
        } else {
            let zIndexA = parseInt(window.getComputedStyle(a.parentElement).zIndex);
            let zIndexB = parseInt(window.getComputedStyle(b.parentElement).zIndex);
            return zIndexA - zIndexB;
        }
    }

    // Sort the array of images
    designImages.sort(customSort);

    // Use Promise to wait for all images to load
    const loadImagePromises = [];
    designImages.forEach(image => {
        const loadImagePromise = new Promise(resolve => {
            const imageCopy = new Image();
            imageCopy.crossOrigin = "anonymous";
            imageCopy.onload = function () {
                resolve(imageCopy);
            };
            imageCopy.src = image.src;
        });
        loadImagePromises.push(loadImagePromise);
    });
    const loadedImages = await Promise.all(loadImagePromises);

    // Calculate offset to draw center of design image on scaled canvas
    var newWidth = 625;
    var newHeight = 600;
    var sourceHeight = designImages[0].naturalHeight;
    var scale = sourceHeight / newHeight;
    var sourceWidth = newWidth * scale;
    var sourceNaturalWidth = designImages[0].naturalWidth;
    var sourceOffsetX = (sourceNaturalWidth - sourceWidth) / 2;

    // Scale images and draw on canvas
    var scaledImage = document.createElement("canvas");
    scaledImage.width = newWidth;
    scaledImage.height = newHeight;
    var ctx = scaledImage.getContext('2d');

    loadedImages.forEach(image => {
        ctx.drawImage(
            image,
            sourceOffsetX,
            0,
            sourceWidth,
            sourceHeight,
            0,
            0,
            newWidth,
            newHeight
        );
    });

    return scaledImage.toDataURL('image/jpg');
}
