import React,{useEffect} from 'react'
import { useSelector,useDispatch } from 'react-redux'
import { useTranslation } from "react-i18next";
import { useParams } from 'react-router-dom';
import ShowerDataMobile from './ShowerDataMobile';
import { selectShowerList,showerParentId,chooseshowerstatus,chooseshowererr,getShowerPlumbingPositions } from '../categorydata/categoryDataSlice';
import BathShowerHeader from './BathShowerHeader';
import { getBookConsultationStatus } from '../designmobilelayout/mobileMenuSlice';
import Loader from '../loader/Loader';

const ChooseShowerListMobile = () => {
const dispatch          = useDispatch(); 
const chooseShower      = useSelector(selectShowerList);
const showerStatus      = useSelector(chooseshowerstatus);
const showerError       = useSelector(chooseshowererr); 
const parentId          = useSelector(showerParentId); 
const bookConsultationStatus = useSelector(getBookConsultationStatus);   
const { t } = useTranslation();

const params = useParams();

    useEffect(() => {
        dispatch(getShowerPlumbingPositions(params.id))
    }, [dispatch, params.id]);

let content;
if(showerStatus ==='loading')
{
    content=<p>{t("loading")}</p>
}
if(showerStatus ==='idle' && chooseShower.length >0 )
{
    content = chooseShower.map(chooseShower=> <ShowerDataMobile key={chooseShower.id} chooseShower={chooseShower} parentId={parentId} />)        
}
if(showerStatus === 'failed')
{
        content =<p>{showerError}</p>
}  
  return (
    <>
    <BathShowerHeader/>
      

        <div className="select-icons select-plumbing">
                <div className="selector-title">
                <h4>{t("chooseShower.configuration")}</h4>
                </div>
                <div className="select-item future-shower">
                   {content}                    
                </div>
        </div>
        {
            bookConsultationStatus === 'loading' ? <Loader /> : null
        }
    </>
    
  )
}

export default ChooseShowerListMobile