import React,{useEffect} from 'react'
import { useSelector,useDispatch } from 'react-redux'
import { useTranslation } from "react-i18next";
import Header from '../header/Header'
import { fetchCatData,getCatStatus,getCatErrors,selectAllCat } from '../categorydata/categoryDataSlice'
import ProjectData from './ProjectData'
import { getBookConsultationStatus } from '../designmobilelayout/mobileMenuSlice';
import Loader from '../loader/Loader';

const ProjectType = () => {
const dispatch    = useDispatch();
const catStatus   = useSelector(getCatStatus);
const catError    = useSelector(getCatErrors);
const cats        = useSelector(selectAllCat);
const bookConsultationStatus = useSelector(getBookConsultationStatus);

  useEffect(() => {
    dispatch(fetchCatData());
  }, [dispatch]);

const { t } = useTranslation();

let content;
if(catStatus ==='loading')
{
    content=<p>{t("loading")}</p>
}
else if(catStatus ==='idle')
{    
      if(Object.values(cats).length>0)
      {
        content = cats.map(cats=> <ProjectData key={cats.id} cats={cats}  />)
      }
     
}
else if(catStatus === 'failed')
{
      content =<p>{catError}</p>
}  
  return (
    <>
     <Header fixedHeaderClass='headerbgFixed'/>
     <div className="pre-config-main2 pre-config-main4">
        <h2>{t("type.instructions")}</h2>
        <div className="left-right-img">
           {content}
        </div>
     </div> 
      {
        bookConsultationStatus === 'loading' ? <Loader /> : null
      }
    </>
    
  )
}

export default ProjectType