import ReactFlagsSelect from "react-flags-select"
import { useTranslation } from "react-i18next";
import ReactInputMask from "react-input-mask";

export default function PhoneInputField({name, placeholder, register, required, patternErrorMessage, errors, countryCode, setCountryCode}) {
    const { t } = useTranslation();
    
    return (
        <div className="col-sm-12 fullform">
            <ReactFlagsSelect
                countries={["US", "CA"]}
                customLabels={{
                    "US": { primary: t('country.US'), secondary: ContryCodePhoneValues['US'] },
                    "CA": { primary: t('country.CA'), secondary: ContryCodePhoneValues['CA'] },
                }}
                selected={countryCode}
                onSelect={(code) => {setCountryCode(code)}}
                showSelectedLabel={false}
                className='country-code'
            />
            <span className="inline">
                <ReactInputMask mask="(999) 999-9999"
                    className="form-control phone"
                    name={name}
                    placeholder={placeholder}
                    {...register(name, {
                        required: required,
                        pattern: {
                            value: /^\(\d{3}\)\s\d{3}-\d{4}$/,
                            message: patternErrorMessage
                        }
                    })}
                />
            </span>
            <small className="form-text text-muted">
                {
                    errors[name] && <p>{errors[name].message}</p>
                }
            </small>
        </div>
    )
}

export const ContryCodePhoneValues = {
    'US': '+1',
    'CA': '+1'
};
