import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import i18next from 'i18next';
import { TranslationKeys } from '../../i18n';
import ArrowRight from '../../images/arrow-right.svg'
import { GoogleDataLayer, getTagManagerEvents } from '../layout/GoogleDataLayer';

const PlumbingDataMobile = ({plumbsData}) => {

    const location = useLocation();

    // Get plumbing category translation from object if exist, default to plumbing category name otherwise
    const categoryName = plumbsData["translations"] && plumbsData["translations"][TranslationKeys[i18next.language]] ?
        plumbsData["translations"][TranslationKeys[i18next.language]] :
        plumbsData.name;

    return (
        <Link
            to={`/${i18next.language}/design-your-own-bathroom/${plumbsData.id}${location.search}`}
            onClick={() => GoogleDataLayer({
                event: getTagManagerEvents.selectPlumbingSide,
                projectType: 'Bathtub',
                plumbingSide: plumbsData.name
            })}
        >
            <div className="item-outer">
                <img src={window.config.REACT_APP_BACKEND_URL+plumbsData.background_image.src} alt={categoryName}/>
                <div className="item-title">
                    <p>{categoryName}</p>
                    <img src={ArrowRight} alt="arrow"/>
                </div>
            </div>
        </Link>
    )
}

export default PlumbingDataMobile