import React from 'react'
import { TranslationKeys } from '../../i18n';
import i18next from 'i18next';
import ArrowRight from '../../images/arrow-right.svg'
import { Link, useLocation } from 'react-router-dom';
import { GoogleDataLayer, getTagManagerEvents } from '../layout/GoogleDataLayer';

const MobileProjectData = ({cats}) => {

    const location = useLocation();
    const categoryName = cats["translations"] && cats["translations"][TranslationKeys[i18next.language]] ?
        cats["translations"][TranslationKeys[i18next.language]] :
        cats.name;

    return (
		<>
            {
                cats.slug !== 'load-existing' ? (
                    <Link to={cats.name.toLowerCase().includes('shower') ?
                        `/${i18next.language}/choose-shower-m/${cats.id}${location.search}` :
                        `/${i18next.language}/plumbing-position-m/${cats.id}${location.search}`}
                        onClick={() => {
                            cats.name.toLowerCase().includes('shower') ? (
                                GoogleDataLayer({
                                    event: getTagManagerEvents.selectProjectType,
                                    projectType: 'Shower'
                                })
                            ) : (
                                GoogleDataLayer({
                                    event: getTagManagerEvents.selectProjectType,
                                    projectType: 'Bathtub'
                                })
                            )
                        }}
                    >
                        <div className="item-outer">
                            <img src={cats.background_image?window.config.REACT_APP_BACKEND_URL+cats.background_image.src:''} alt={cats.name}/>
                            <div className="item-title">
                                <p>{categoryName}</p>
                                <img src={ArrowRight} alt="arrow"/>
                            </div>
                        </div>
                    </Link>
                ) : null
            }
        </>
    )
}

export default MobileProjectData