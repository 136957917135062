import React from 'react'
import { Link,useLocation } from 'react-router-dom'
import { useSelector,useDispatch } from 'react-redux';
import i18next from 'i18next';
import { getSelectedAssetsID,setHandleHeaderMenuClick,getClickedMenuVal } from '../designbathshower/designBathShowerSlice';
import CheckImg from '../../images/circular-check.svg'
import headerImg from '../../images/headerActive.svg'
import { TranslationKeys } from '../../i18n';

const HeaderMenuData = ({assetData,index}) => {
  const dispatch=useDispatch();  
  const location = useLocation();

  // Get category translation from object if exist, default to category name otherwise
  const categoryName = assetData.translations?.[TranslationKeys[i18next.language]] || assetData.name;

  const headTitleSelected = useSelector(getSelectedAssetsID);
  const clickedMenu = useSelector(getClickedMenuVal);
  const isActive = (clickedMenu === categoryName) || (index === 1 && clickedMenu === null);
  const isSelected = Object.keys(headTitleSelected).includes(categoryName);

  return (
    <li className={isActive ? 'active' : ''}>
      <Link to={`${location.pathname}${location.search}`} onClick={() => dispatch(setHandleHeaderMenuClick(categoryName))}>
        {isActive ? (
          isSelected ? <img src={headerImg} alt='' /> : <span className='headerNumer'>{index}</span>
        ) : (
          isSelected ? <img src={CheckImg} alt='' /> : <span className='headerNumer'>{index}</span>
        )}
        {categoryName}
      </Link>
    </li>
  );
};

export default HeaderMenuData;