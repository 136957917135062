export default function TextInputField({name, placeholder, register, requiredErrorMessage, errors}) {
    return (
        <div className="col-sm-12 fullform">
            <input type="text"
                className="form-control"
                name={name}
                placeholder={placeholder}
                {...register(name, {
                    required: requiredErrorMessage,
                    validate: (value) => !!value.trim() || requiredErrorMessage
                })}
                maxLength={20}
            />
            <small className="form-text text-muted">
                {
                    errors[name] && <p>{errors[name].message}</p>
                }
            </small>
        </div>
    )
}
