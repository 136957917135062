import React from 'react'
import { useSelector,useDispatch } from 'react-redux'
import { Link,useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { getSummaryPopUpStatusClose, summaryPopUpStatus, getSummaryMissingData, goSummaryPageDesign } from '../designbathshower/designBathShowerSlice'

const DesignSummaryPopUp = () => {
const dispatch          = useDispatch(); 
const pop_status        = useSelector(summaryPopUpStatus); 
 
const location = useLocation();
const missingCategories = useSelector(getSummaryMissingData);
const { t } = useTranslation();

  return (
    (pop_status===true)?(
        <div className="modal fade in" id="missings"  role="dialog" data-backdrop="static" data-keyboard="false" style={{"display": "block"}}>
           <div className="modal-dialog" role="document">
               <div className="modal-content">
                   <div className="modal-header">
                       <h5 className="modal-title" id="exampleModalLabel">{t("missingCategories.title")}</h5>
                   </div>
                    <div className="modal-body">
                        <div className="startmid">
                            {
                                missingCategories.map(category => (
                                    <p className="emailtexts" key={category}>
                                        {
                                            category === "Tub fronts" || category === "Baignoires" || category === "Bañeras" ?
                                                t('missingCategories.tubFronts') :
                                            category === "Shower bases" || category === "Bases de douche" || category === "Bases de ducha" ?
                                                t('missingCategories.showerBases') :
                                            category === "Walls" || category === "Murs" || category === "Paredes" ?
                                                t('missingCategories.walls') :
                                            category === "Faucets" || category === "Robinetterie" || category === "Grifos" ?
                                                t('missingCategories.faucets') :
                                            category === "Shelves" || category === "Étagères" || category === "Repisas" ?
                                                t('missingCategories.shelves') :
                                            category === "Soap dishes" || category === "Porte-savons" || category === "Jaboneras" ?
                                                t('missingCategories.soapDishes') :
                                            category === "Grab bars" || category === "Barres d'appui" || category === "Barras de sujeción" ?
                                                t('missingCategories.grabBars') :
                                            category === "Seats" || category === "Sièges" || category === "Asientos" ?
                                                t('missingCategories.seats') :
                                            category === "Doors/Rods" || category === "Portes / Barres" || category === "Puertas / Barras" ?
                                                t('missingCategories.doorsRods') :
                                            category === "Doors" || category === "Portes" || category === "Puertas" ?
                                                t('missingCategories.doors') :
                                            category === "Wainscotings" || category === "Lambris" || category === "Paneles" ?
                                                t('missingCategories.wainscotings') :
                                            category === "Ceilings" || category === "Plafonds" || category === "Techos" ?
                                                t('missingCategories.ceilings') :
                                                t('missingCategories.general', {category: category.toLowerCase()})
                                        }
                                    </p>
                                ))
                            }
                        </div>
                    </div>
                    <div className="modal-fotter">
                       <p className="emailtexts">{t("missingCategories.continue")}</p>
                        <div className="buttoncenters">
                            <Link to={`${location.pathname}${location.search}`} className="backtext" onClick={() => dispatch(getSummaryPopUpStatusClose())}>
                                <i className="fas fa-chevron-left"></i> {t("missingCategories.back")} 
                            </Link>
                           <button className="summary" onClick={ () =>  dispatch(goSummaryPageDesign())}>{t("missingCategories.goToSummary")} <i className="fas fa-chevron-right"></i></button>
                       </div>
                    </div>
               </div>
           </div>
       </div>
      ):null
      
  )
}

export default DesignSummaryPopUp