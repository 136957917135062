import React from 'react'
import { getIsLoadDesignPopUpActive, closeLoadExistingDesignPopUp, getProjectToLoad, setIsLoadInSummary, fetchProjectType} from '../designbathshower/designBathShowerSlice'
import { useSelector,useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ModalCros from '../../images/crox.png'
import NextIcon from '../../images/angle-right.svg';
import { Link, useLocation } from 'react-router-dom';
import { clearUserSavedDesigns } from '../designmobilelayout/mobileMenuSlice';
import { getCampaignKeys } from '../campaignanalytics/CampaignAnalytics';
import { GoogleDataLayer, getTagManagerEvents } from '../layout/GoogleDataLayer';
import { getMissingMinimumSelection } from './MinimumSelection';
import i18next from 'i18next';

const DesignLoadExisitngPop = () => {
const dispatch        =useDispatch();
const isLoadDesignPopUpActive   =useSelector(getIsLoadDesignPopUpActive);
const project        =useSelector(getProjectToLoad)
const { t }           = useTranslation();
    
    const location = useLocation();

    const onSelectionMade = async (isLoadInSummary) => {
        dispatch(clearUserSavedDesigns());
        await dispatch(fetchProjectType(project['cat_id']));
        dispatch(setIsLoadInSummary(isLoadInSummary));
        dispatch(closeLoadExistingDesignPopUp());
        GoogleDataLayer({event: getTagManagerEvents.loadFinish});
    }

    let pathName;
    let projectType;
    let isHideSummary = false;
    if (Object.keys(project).length > 0) {
        // Change link path based on project type
        if (project['path_name'].includes('bathroom')) {
            pathName = project['path_name'];
            projectType = 'bathtub';
        } else {
            pathName = 'design-your-own-shower';
            projectType = 'shower';
        }

        // Disable view in summary button if project doesn't have at least a shower base/tub front and walls
        const projectAllData = JSON.parse(project['all_data']);
        const missingCategories = getMissingMinimumSelection(Object.keys(projectAllData.allData), projectType, project['locale']);
        if (missingCategories.length > 0) {
            isHideSummary = true;
        }
    }

    // Build search parameter for URL based on saved design informations
    let searchTerms = '';
    getCampaignKeys.forEach((key) => {
        if (project[key]) {
            searchTerms = searchTerms.concat(`${searchTerms.length === 0 ? 
                `?${key}=${project[key]}` : 
                `&${key}=${project[key]}`}`
            );
        }
    });
    // If saved design information didn't have campaign information, then we use current campaign information
    if (searchTerms.length === 0) {
        searchTerms = location.search;
    }
    
  return (
    (isLoadDesignPopUpActive===true)?(<div className="modal fade  in savePopSavenew" id="savePopSave"  role="dialog" data-backdrop="static" data-keyboard="false" style={{"display":"block"}}>
    <div className="modal-dialog" role="document">
        <div className="modal-content">
            
            <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">{t("loadExistingPopUp.title")}
                    <span className="close" data-dismiss="modal" onClick={ () =>  dispatch(closeLoadExistingDesignPopUp())}><img src={ModalCros} alt=""/></span>
                </h5>
            </div>                    
            <div className="modal-body">                
                <div className="savebuttonboxs">
                    <Link
                        to={`/${i18next.language}/${pathName}/${project['cat_id']}/${project['id']}${searchTerms}`}
                        onClick={() => onSelectionMade(false)}
                    >
                        <button className={`savebtn ${i18next.language === 'fr' ? "LangBtn" : ""}`}>
                            {t("loadExistingPopUp.editDesign")}
                        </button>
                    </Link>
                    
                    {
                        !isHideSummary && (
                            <Link
                                to={`/${i18next.language}/${pathName}/${project['cat_id']}/${project['id']}${searchTerms}`}
                                onClick={() => onSelectionMade(true)}
                                className="summaryLink active"
                            >
                                <button className={`savebtn save ${i18next.language === 'fr' ? "LangBtn" : ""}`}>
                                    {t("loadExistingPopUp.viewSummary")}<img src={NextIcon} alt=''/>
                                </button>
                            </Link>
                        )
                    }
                </div>
            </div>
        </div>
    </div>
</div> ):null
  )
}

export default DesignLoadExisitngPop