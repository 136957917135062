import React from 'react'
import { useDispatch ,useSelector} from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import i18next from 'i18next';
import { TranslationKeys } from '../../i18n';
import { getVariantDetails,getSelectedAssetsID,setOrderSelectedSubCat, removeSingleProduct, setHandleHeaderMenuClick } from './designBathShowerSlice';
import { GoogleDataLayer, getTagManagerEvents } from '../layout/GoogleDataLayer';

const DesignBathShowerInnerData = ({innerData,mainCat,headerIndexKey}) => { 
    const dispatch = useDispatch();
    const selectedVariant = useSelector(getSelectedAssetsID);
    const isProductSelected = selectedVariant && Object.values(selectedVariant).indexOf(innerData.id) !== -1;

    // Get name translation from product meta_data if exist, default to product name otherwise
    const productName = innerData.meta_data.filter(m => m.key === TranslationKeys[i18next.language]).length === 0 ?
        innerData.name :
        innerData.meta_data.filter(m => m.key === TranslationKeys[i18next.language])[0].value;
   
    const categoryName = innerData.categories[0][TranslationKeys[i18next.language]] ?
        innerData.categories[0][TranslationKeys[i18next.language]] :
        innerData.categories[0].name;

    let variantId, colorName, colorHex, variantImage;
    if (innerData.product_variations.length > 0) {
        variantId = innerData.product_variations[0].id;
        colorName = innerData.product_variations[0][TranslationKeys[i18next.language]] ?
            innerData.product_variations[0][TranslationKeys[i18next.language]] :
            innerData.product_variations[0].name;
        colorHex = innerData.product_variations[0].value;
        variantImage = innerData.images[0]?innerData.images[0].src:'';
    }

    return (
        <div className="col-sm-4">
            <div>
                <div className= {`padding ${isProductSelected ? 'active' : '' }`}>
                    <div className='hoverMainParent'>
                        <span
                            className="imagesborder"
                            onClick={() => {
                                dispatch(getVariantDetails({
                                    'product_variations': innerData.product_variations,
                                    'mainCatName': innerData.categories[0].name,
                                    'categoryName': categoryName,
                                    'name': productName,
                                    'assetId': innerData.id,
                                    'variantId': variantId,
                                    'assetColor': colorName,
                                    'assetColorStyle': colorHex,
                                    'assetImage': variantImage,
                                    'parentCat': mainCat,
                                    'childLen': 0,
                                    'headerIndex': headerIndexKey,
                                    'Eng': {'product_name':innerData.name,'color':innerData.product_variations[0].name,'category':innerData.categories[0].name} 
                                }));
                                GoogleDataLayer({
                                    event: getTagManagerEvents.selectProduct,
                                    productName: innerData.name,
                                    gaProductCode: `${innerData.categories[0].name}-${innerData.name}`,
                                    productCategory: innerData.categories[0].name
                                });
                                dispatch(
                                    setOrderSelectedSubCat({
                                        'parentCat': mainCat,
                                        'categoryName': categoryName,
                                        'assetId': innerData.id
                                    })
                                );
                                dispatch(setHandleHeaderMenuClick(categoryName));
                            }}
                        >
                            <img src={innerData.images[0]?window.config.REACT_APP_BACKEND_URL+innerData.images[0].src:''} alt='' /> 
                        </span>

                        {
                            isProductSelected ? (
                                <div className="hover-overlay">
                                    <FontAwesomeIcon icon={faTimes} className='chekedIcon' onClick={() => dispatch(removeSingleProduct({'categoryName': categoryName}))}/>
                                </div>
                            ) : null
                        }
                    </div>
                    <span className="tittle">{productName} </span>
                </div>
            </div>
        </div>
    )
}

export default DesignBathShowerInnerData