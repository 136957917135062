import React from 'react'
import { useSelector } from 'react-redux'
import LayoutRightImageContent from './LayoutRightImageContent'
import { getVariantAllData,getBigImageSrcUrl } from '../designbathshower/designBathShowerSlice'
import { getIsDragging, getScale, getX, getY } from './layoutSlice' 
import DragMove from './DragMove'

const LayoutRight = ({mainDivRef, dragAreaRef}) => {
const allVarinatData     = useSelector(getVariantAllData);
const x = useSelector(getX);
const y = useSelector(getY);
const scale = useSelector(getScale);
const isDragging = useSelector(getIsDragging);

let content;
if(Object.keys(allVarinatData).length > 0)
{
    
        content = Object.entries(allVarinatData).map(([key,data])=><LayoutRightImageContent key={key} data={data.otherData} dataClass={key} />)
}
let bigImage=useSelector(getBigImageSrcUrl)

const dragMoveBinding = DragMove({mainDivRef, dragAreaRef});

function preventImageDrag(event) {
    event.preventDefault();
}

return (
    <div className={`left-image-layout ${isDragging ? 'dragging' : 'draggable'}`} id="full-design" ref={dragAreaRef} {... dragMoveBinding()} style={{left: x, top: y, transform:`scale(${scale})`}}>
        <div className="bg-img">
            {
                bigImage ? (
                    <img src={window.config.REACT_APP_BACKEND_URL + bigImage.src}
                        alt=''
                        onDragStart={preventImageDrag}
                        crossOrigin="Anonymous"
                    />
                ) : null
            }
        </div>
        <div className="inner-elements">
            {content}
        </div>
    </div>
)
}

export default LayoutRight