import  React,{useEffect,useRef} from 'react'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next';
import zoomInImage from  '../../images/zoomin.png'
import zoomOutImage from  '../../images/zoomout.png'
import resetViewImage from  '../../images/reset.png'
import BottomIcon from '../../images/bottomicons.png'
import SaveImg from '../../images/save.png'
import ShareImg from '../../images/share.png'
import { Link,useLocation } from 'react-router-dom'
import { useSelector,useDispatch } from 'react-redux'
import { zoomIn, zoomOut, resetView, calculateSnapping } from './layoutSlice'
import DesignBathShowerList from '../designbathshower/DesignBathShowerList'
import LayoutRight from './LayoutRight'
import { getColorVariRight, getColorOtherData, allDesignBathData, getMoodifySummaryMenu, resetSummaryMenu, getSelectedAssetsID, getClickedMenuVal, getIsSummaryViewActive, getIsNewScreenshotNeeded, takeDesignScreenshot, getVariantDetailStatus, getLoaderVariantChange, getTakeDesignScreenshotStatus } from '../designbathshower/designBathShowerSlice'
import DesingBathShowerColors from '../designbathshower/DesingBathShowerColors'
import SummaryBathShower from '../designbathshowersummary/SummaryBathShower'
import { openSaveDesignLayoutRightPopUp, openSharePopUp, showMinimumSelectionAlert } from '../designmobilelayout/mobileMenuSlice'
import TutorialPopUp from '../popup/TutorialPopUp'
import LoaderDesign from '../loader/LoaderDesign';
import { getMissingMinimumSelection } from '../designbathshowerpopup/MinimumSelection';


const Layout = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const dispatch = useDispatch();

          let right_color_vari         = useSelector(getColorVariRight);          
          let right_other_Data         = useSelector(getColorOtherData);           
          let allDataDesign            = useSelector(allDesignBathData);
          let  current_head_menu       = useSelector(getMoodifySummaryMenu);
          const selectedAssets         = useSelector(getSelectedAssetsID);
          const clickedMenu            = useSelector(getClickedMenuVal);
          const isSummaryViewActive    = useSelector(getIsSummaryViewActive);
          const isNewScreenshotNeeded = useSelector(getIsNewScreenshotNeeded);
          const productVariantsStatus = useSelector(getVariantDetailStatus);
          const variantImageStatus = useSelector(getLoaderVariantChange);
          const takeDesignScreenshotStatus = useSelector(getTakeDesignScreenshotStatus);
          
          let menuId = clickedMenu !== null && clickedMenu !== undefined
              ? clickedMenu.replace(/[\s/]/g, '')
              : '';

          useEffect( ()=>{ 
               if(current_head_menu!=='')
               {
                  if(current_head_menu.includes('/'))
                  {
                       let menu=current_head_menu.replace(/\//g, '')
                       let movetoDiv = document.querySelector('#slide'+menu);
                       if(movetoDiv !==null)
                       {
                          movetoDiv.scrollIntoView( { behavior: 'smooth', block: 'start' } ); 
                          dispatch(resetSummaryMenu())
                       }
                  }
                  else{
                       let menu=current_head_menu.replace(/\s/g, '')                       
                       let movetoDiv = document.querySelector('#slide'+menu);
                       if(movetoDiv !==null)
                       {
                         movetoDiv.scrollIntoView( { behavior: 'smooth', block: 'start' } ); 
                         dispatch(resetSummaryMenu())
                       }
                  }
               }
              },[dispatch, current_head_menu])

         useEffect( ()=>{
               if(menuId!==null && menuId!==undefined)
               {                 
                    const section = document.querySelector('#slide'+menuId);
                   
                    if(section!==null)
                    {
                         section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
                    }
               }
          },[menuId])

    useEffect(() => {
        if (isNewScreenshotNeeded) {
            dispatch(takeDesignScreenshot({
                isMobile: false
            }));
        }
    }, [dispatch, isNewScreenshotNeeded]);
    
    const ref = useRef()
     // code to change the heder on scroll down and up
     
    const projectType = location.pathname.includes('design-your-own-bathroom') ? 'bathtub' : 'shower';
    const missingCategories = getMissingMinimumSelection(Object.keys(selectedAssets), projectType);
    const onShareClick = () => {
        if (missingCategories.length > 0) {
            dispatch(showMinimumSelectionAlert());
        } else {
            dispatch(openSharePopUp());
        }
    }

     const mainDivRef = React.useRef();
     const dragAreaRef = React.useRef();

    return ( 
     
             <>
                        <div className="col-sm-4 leftmenu DesktopView" ref={ref}>
                            {
                                isSummaryViewActive === true ? <SummaryBathShower/> : <DesignBathShowerList/>
                            }
                        </div>
                         <div className="col-sm-8" id="my-node">
                            <div className="design-container" ref={mainDivRef}>
                                <LayoutRight mainDivRef={mainDivRef} dragAreaRef={dragAreaRef}/>
                                {
                                    productVariantsStatus === 'loading' ||
                                    variantImageStatus === 'loading' ||
                                    takeDesignScreenshotStatus === 'loading' ? (
                                        <LoaderDesign />
                                    ) : null
                                }
                            </div>

                              <div className="bottom icons">
                                        <div className="bottomzoomicons">  
                                            <li>
                                                <Link to={`${location.pathname}${location.search}`} className="tooltip"
                                                    onClick={() => dispatch(zoomIn())}
                                                >
                                                    <img src={zoomInImage} alt=''/><span className="tooltiptext tooltip-right">{t('tooltip.zoomIn')}</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={`${location.pathname}${location.search}`} className="tooltip" onClick={async() => {
                                                    await dispatch(zoomOut());
                                                    dispatch(calculateSnapping({mainDivRef, dragAreaRef}));
                                                }}>
                                                    <img src={zoomOutImage} alt=''/><span className="tooltiptext tooltip-right">{t('tooltip.zoomOut')}</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={`${location.pathname}${location.search}`} className="tooltip"
                                                    onClick={() => dispatch(resetView())}
                                                >
                                                    <img src={resetViewImage} alt=''/><span className="tooltiptext tooltip-right">{t('tooltip.resetView')}</span>
                                                </Link>
                                            </li>
                                        </div>
                                             <div className="iconsleft">                                             
                                                  <span className="imagesfull tooltip">
                                                  <img src={BottomIcon} alt=''/>
                                                  <span className="tooltiptext">{t('tooltip.colorSelector')}</span>
                                                  </span>
                                                  { 
                                                       (Object.values(right_color_vari).length>0 )?(Object.values(right_color_vari).map(data=>
                                                       <DesingBathShowerColors colorVarinatsData={data} variantId={right_other_Data.variantId} assetId={right_other_Data.assetId} childLen={right_other_Data.childLen} parentCatName={right_other_Data.selectedCat} key={data.id} allData={
                                                        (allDataDesign[right_other_Data.selectedCat]!==undefined)?((allDataDesign[right_other_Data.selectedCat][right_other_Data.allDataKey]===undefined)
                                                        ?
                                                        (allDataDesign[right_other_Data.selectedCat])
                                                        :
                                                        (allDataDesign[right_other_Data.selectedCat][right_other_Data.allDataKey])):''
                                                    } headerIndexKey={right_other_Data.headerIndex} catNameEng={right_other_Data.catNameEng} />).reverse()):''
                                                  }
                                             </div>
                                            <div className="rightsitems">
                                                <Link to={`${location.pathname}${location.search}`} className={`savebtn ${i18next.language ==='fr' ? 'frenchShare' :''}`} onClick={() => dispatch(openSaveDesignLayoutRightPopUp(selectedAssets))}>
                                                    <i><img src={SaveImg} alt=''/></i>
                                                    {t("button.save")}
                                                </Link>
                                                <Link to={`${location.pathname}${location.search}`} className={`savebtn ${i18next.language ==='fr' ? 'frenchShare' :''}`} onClick={onShareClick}>
                                                    <i><img src={ShareImg} alt=''/></i>
                                                    {t("button.share")}
                                                </Link>
                                            </div>
                              </div>
                         </div>
                         
                         <TutorialPopUp/> 
                 </>
     
       )
}

export default Layout
