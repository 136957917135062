import  React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { Link, useLocation } from 'react-router-dom'
import Reset from '../../images/reseticon.png'
import StartOver from '../../images/start_over.png'
import Previous from '../../images/previous.png'
import { useSelector, useDispatch } from 'react-redux';
import i18next from 'i18next'
import { useTranslation } from "react-i18next";
import { selectAllAssests,getAssetsErrors,getAssetsStatus,openStartOverPopUp,getIsSummaryViewActive,getIsShowMissingProductsPopUp,getVariantDetailStatus,getVariantAllData, openResetDesignPopUp, getSelectedAssetsID } from '../designbathshower/designBathShowerSlice'
import HeaderMenuData from './HeaderMenuData'
import LoaderImg from '../../images/loader.gif'
import { getMissingMinimumSelection } from '../designbathshowerpopup/MinimumSelection'
import { showProductRequiredAlert } from '../designmobilelayout/mobileMenuSlice'

const HeaderMenu = () => {
const dispatch          = useDispatch();
const assets_status        = useSelector(getAssetsStatus);
const assets_err           = useSelector(getAssetsErrors);
const assetData            = useSelector(selectAllAssests);
const selectedAssets         = useSelector(getSelectedAssetsID);

const isSummaryViewActive = useSelector(getIsSummaryViewActive);

const checkIfSelectionDone=useSelector(getVariantDetailStatus);

const minimumSelect        =useSelector(getVariantAllData);

const location = useLocation();
const projectType = location.pathname.includes('design-your-own-bathroom') ? 'bathtub' : 'shower';
let showSummaryButton;
if (checkIfSelectionDone==='success') {
    let missingCategories = getMissingMinimumSelection(Object.keys(minimumSelect), projectType);
    if (missingCategories.length > 0) {
        showSummaryButton = false;
    } else {
        showSummaryButton = true;
    }
}


let content;
if(assets_status ==='loading')
{
    content=<img src={LoaderImg} alt='' id="headerLoader"/>
}
else if(assets_status ==='idle')
{ 
    
        if(assetData!==undefined && assetData !=='Network Error')
        { 
            
            content = assetData.map((assetData,index)=> <HeaderMenuData key={assetData.id} index={index+1} assetData={assetData}  />)    
        }            
}
else if(assets_status === 'failed')
{
        content =<p>{assets_err}</p>
}

const [isOpen, setIsopen] = useState(false);
const ToggleSidebar = () => {    
   isOpen === true ? setIsopen(false) : setIsopen(true);   
}
const { t } = useTranslation();

const handleResetClick = () => {
    if (Object.keys(selectedAssets).length === 0) {
        dispatch(showProductRequiredAlert());
    } else {
        dispatch(openResetDesignPopUp());
    }
};

const handleStartOverClick = () => {
    if (Object.keys(selectedAssets).length === 0) {
        window.location.href = `/${i18next.language}${location.search}`;
    } else {
        dispatch(openStartOverPopUp());
    }
}
    
    return ( 
        <div className="navigation">
            <div className="container">
                <div className="maninav">
                    <ul>
                        <li className={`nav-item dropdown ${isOpen === true ? 'nav-item dropdown open' : 'nav-item dropdown'}`} onClick={ToggleSidebar}>
                            <Link to={`${location.pathname}${location.search}`} className="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                               <FontAwesomeIcon icon={faBars} onClick={ToggleSidebar}/>
                            </Link>
                            <ul className= {`dropdown-menu ${i18next.language==='fr' ?'frenchLi':''}`} aria-labelledby="navbarDropdownMenuLink">
                                <li className="dropdown-submenu">
                                    <Link to={`${location.pathname}${location.search}`} onClick={handleStartOverClick}>
                                        <img src={StartOver} alt=''/> {t("startOver.title")}
                                    </Link>                                    
                                </li>
                                <li className={`${i18next.language==='fr' ?'frenchLi':''}`}>
                                    <Link to={`/${i18next.language}/load-existing-design${location.search}`}>
                                        <img src={Previous} alt=''/> {t("loadExisting.title")}
                                    </Link>
                                </li>
                            </ul>
                        </li>
                        {content}
                        {
                            showSummaryButton === true ? (
                                <li className='summaryMenu'>
                                    {
                                        isSummaryViewActive === true ?
                                            <button type="button" className="summary-btn active">{t('button.summary')}</button>
                                        :
                                            <button type="button" className="summary-btn" onClick={() => dispatch(getIsShowMissingProductsPopUp())}>{t('button.summary')}</button>
                                    }
                                </li>
                            ) : null
                        }
                        
                        <li className='resetHeaderLink'>
                            <Link to={`${location.pathname}${location.search}`} onClick={handleResetClick}>
                                <img src={Reset} alt='' />
                                <span id='resetSpan'>{t("reset")}</span> 
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default HeaderMenu
