import React from 'react'
import { useDispatch } from 'react-redux';
import { hideAllAlerts } from '../designmobilelayout/mobileMenuSlice';
import alertSave from '../../images/alertSave.png'
import ModalCros from '../../images/crox.png'

const SaveMessage = ({ msg }) => {
  const dispatch = useDispatch();
  return (
    <div className='alertMainDiv'>
      <div className='midditemss alertSuccess'>
        <div className='imagAlert'><img src={alertSave} alt='' /></div>
        <div className='msgText'> {msg}</div>
        <div className='cross' onClick={() => dispatch(hideAllAlerts())}> <img src={ModalCros} alt='' /></div>
      </div>
    </div>
  )
}

export default SaveMessage
