import { createAsyncThunk,createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import BlobStorage from "../blobstorage/BlobStorage";
import { sendTrackException, sendTrackTrace } from '../telemetry/TelemetryProvider';

let initialState={
    isShowProductRequiredAlert:null,
    isSocialPopUpActive: false,
    isMobileSocialPopUpActive: false,
    isShowMinimumSelectionAlert: false,
    isShowLinkCopiedAlert: false,
    saveForSocialStatus: 'idle',
    socialErrStatus:null,
    isSaveDesignLayoutRightPopUpActive:null,
    saveDesignStatus:'idle',
    openSaveDesignLayoutRightPopUpClose:null,
    saveStatus: false,
    saveStatusErr: false,
    scheduleBookStatus: false,
    scheduleBookErr: false,
    saveDesingErr:null,
    bookNowDesingBathShowerStatus:null,
    schedulePostStatus:'idle',
    schedulePostErr:null,    
    socialImageUrl:null,
    loadExistingStatus:'idle',
    loadExistingErr:null,
    loadExistingData:[],
    isUserDesignsFetched: false,
    designId: -1,
    shareDesignId: -1,
    shareLink: '',
    shareByEmailStatus: 'idle',
    isShowShareByEmailSuccessAlert: false,
    isShowShareByEmailFailAlert: false,
    isShowSaveForShareErrorAlert: false,
    blobStorageAccountName: '',
    blobStorageContainerName: '',
    blobStorageSASToken: ''
}

const saveDesignUrl  =window.config.REACT_APP_SAVE_DESIGN_URL;
const bookConsultationUrl = window.config.REACT_APP_BOOK_URL;   
const bookConsultationWithDesignUrl = window.config.REACT_APP_BOOK_WITH_DESGIN_URL;
const saveDesignForSharingUrl = window.config.REACT_APP_SOCIAL_URL;
const shareDesignByEmailUrl = window.config.REACT_APP_SHARE_EMAIL_URL;

// Returns list of all products selected with their categories and colors
function getSummaryInformation(summaryPrintData) {
  let summaryInformation = {};

  if (Object.keys(summaryPrintData).length > 0) {
    Object.values(summaryPrintData).forEach((rowData) => {
      if (rowData.Eng) {
        // Category
        summaryInformation[rowData.Eng.category] = `${rowData.Eng.product_name}_${rowData.Eng.color}`;
      } else {
        // Sub category
        Object.values(rowData).forEach(childData => {
          summaryInformation[childData.Eng.category] = `${childData.Eng.product_name}_${childData.Eng.color}`;
        });
      }
    });
  }

  return summaryInformation;
}

export const getBlobStorageToken = createAsyncThunk('get/BlobStorageToken', async(payload, thunkApi) => {
  try {
    const response = await axios.get(window.config.REACT_APP_BLOB_STORAGE_TOKEN_URL);

    sendTrackTrace('GET api/getSASToken', payload, response.data);

    return JSON.parse(response.data);
  } catch (err) {
    sendTrackException(err);

    return thunkApi.rejectWithValue(err.message);
  }
});

// code to save the web and mobile data 
export const addNewDesignBathShower= createAsyncThunk('saveWebDesign/addNewDesignBathShower',async(payload,thunkApi)=>{
  const selectedProducts = getSummaryInformation(thunkApi.getState().designbathshower.summaryPageData);
  const dataToSave = {
    'first_name': payload.firstName,
    'last_name': payload.lastName,
    'design_id': payload.designId,
    'brand': payload.locale === 'fr' ? 'BM' : 'BF',
    'platform': payload.platform,
    'email': payload.email,
    'locale': payload.locale,
    'catid': payload.catid,
    'pathName': payload.pathName,
    'is_marketing_consent': payload.isMarketingConsent,
    'campaign_informations': payload.campaignInformations
  };

  try {
    let blobImage = await BlobStorage(
      thunkApi.getState().designbathshower.designImageSource,
      thunkApi.getState().mobilemenu.blobStorageAccountName,
      thunkApi.getState().mobilemenu.blobStorageContainerName,
      thunkApi.getState().mobilemenu.blobStorageSASToken
    );

    const response = await axios.post(saveDesignUrl, {
      ...dataToSave,
      'big_image': blobImage,
      'finalData': payload.allData
    });

    sendTrackTrace('POST api/saveDesign', {
      ...dataToSave,
      'big_image': blobImage,
      'selectedProducts': selectedProducts
    }, response.data);

    return response.data.design_id;
  } catch(err) {
    sendTrackException(err, {
      ...dataToSave,
      'selectedProducts': selectedProducts
    });

    return thunkApi.rejectWithValue(err.message);
  }
});

// Save contact info and create lead
export const scheduleDesignBookNow = createAsyncThunk('scheduleDesignBookNow/scheduleDesignBookNow', async(payload, thunkApi) => {
  const bookData = {
    'first_name': payload.firstName,
    'last_name': payload.lastName,
    'email': payload.email,
    'phone': payload.phone,
    'zip_code': payload.zipCode,
    'locale': payload.locale,
    'is_marketing_consent': true,
    'campaign_informations': payload.campaignInformations
  };
  
  if (Object.keys(payload.allData.allData).length > 0) {
    const selectedProducts = getSummaryInformation(thunkApi.getState().designbathshower.summaryPageData);
    const bookDataWithDesign = {
      ...bookData, 
      'design_id': payload.designId,
      'brand': payload.locale === 'fr' ? 'BM' : 'BF',
      'platform': payload.platform,
      'catid': payload.catid,
      'pathName': payload.pathName,
    };

    try {
      // There's a design to save/update with the lead
      let blobImage = await BlobStorage(
        thunkApi.getState().designbathshower.designImageSource,
        thunkApi.getState().mobilemenu.blobStorageAccountName,
        thunkApi.getState().mobilemenu.blobStorageContainerName,
        thunkApi.getState().mobilemenu.blobStorageSASToken
      );

      const response = await axios.post(bookConsultationWithDesignUrl, {
        ...bookDataWithDesign,
        'big_image': blobImage,
        'finalData': payload.allData
      });
    
      sendTrackTrace('POST api/bookConsultationWithDesign', {
          ...bookDataWithDesign,
          'big_image': blobImage,
          'selectedProducts': selectedProducts,
      }, response.data);
    
      return response.data;
    } catch (err) {
      sendTrackException(err, {
        ...bookDataWithDesign,
        'selectedProducts': selectedProducts,
      });

      throw thunkApi.rejectWithValue(err.message);
    }
  } else {
    try {
      const response = await axios.post(bookConsultationUrl, bookData);
    
      sendTrackTrace('POST api/bookConsultation', bookData, response.data);

      return response.data;
    } catch (err) {
      sendTrackException(err, bookData);

      throw thunkApi.rejectWithValue(err.message);
    }
  }
});

// Fetch saved designs linked to an email
export const fetchUserSavedDesigns = createAsyncThunk('getLoadExistingDesign/fetchLoadExistingDesign', async(payload) => {
    try {
      const response = await axios.get(window.config.REACT_APP_GET_DESIGNS_URL, {params: {"email": payload}});
      
      sendTrackTrace('GET api/getUserSavedDesigns', {"email": payload}, response.data);

      return response.data;
    } catch(err) {
      sendTrackException(err, {"email": payload});

      return err.message;
    }
});

export const saveDesignForSharing = createAsyncThunk('saveDesign/saveForSharing', async (payload, thunkApi) => { 
  const selectedProducts = getSummaryInformation(thunkApi.getState().designbathshower.summaryPageData);
  const dataToSave = {
    'platform': 'share',
    'locale': payload.locale,
    'catid': payload.catid,
    'pathName': payload.pathName
  };

  try {
    let blobImage = await BlobStorage(
      thunkApi.getState().designbathshower.designImageSource,
      thunkApi.getState().mobilemenu.blobStorageAccountName,
      thunkApi.getState().mobilemenu.blobStorageContainerName,
      thunkApi.getState().mobilemenu.blobStorageSASToken
    );  

    const response = await axios.post(saveDesignForSharingUrl, {
      ...dataToSave,
      'big_image': blobImage,
      'finalData': payload.allData
    });
    
    sendTrackTrace('POST api/shareDesign', {
      ...dataToSave,
      'big_image': blobImage,
      'selectedProducts': selectedProducts
    }, response.data);
    
    return {
      shareDesignId: response.data,
      designImageUrl: blobImage,
      pathName: payload.pathName,
      catId: payload.catid
    };
  } catch(err) {
    sendTrackException(err, {
      ...dataToSave,
      'selectedProducts': selectedProducts
    });

    return thunkApi.rejectWithValue(err.message);
  }
});

export const shareDesignByEmail = createAsyncThunk('shareDesign/shareByEmail', async (payload, thunkApi) => { 
  let shareData = {
    'shared_design_id': payload.shareDesignId,
    'first_name': payload.firstName,
    'last_name': payload.lastName,
    'email': payload.email,
    'brand': payload.locale === 'fr' ? 'BM' : 'BF',
    'share_address': payload.friendEmail,
    'is_marketing_consent': payload.isMarketingConsent
  };

  try {      
    const response = await axios.post(shareDesignByEmailUrl, shareData);

    sendTrackTrace('POST api/shareDesignByEmail', shareData, response.data);

    return response.data
  } catch(err) {
    sendTrackException(err, shareData);

    return thunkApi.rejectWithValue(err.message);
  }
});

export const mobileMenuSlice = createSlice({
    name: 'menuData',
    initialState,    
    reducers: {
      openSharePopUp: (state) => {
        state.isSocialPopUpActive = true;
      },
      closeSharePopUp:(state) => {
        state.isSocialPopUpActive = false
      },
      openMobileSharePopUp: (state) => {
        state.isMobileSocialPopUpActive = true;
      },
      closeMobileSharePopUp: (state) => {
        state.isMobileSocialPopUpActive = false;
      },
      showMinimumSelectionAlert: (state) => {
        state.isShowMinimumSelectionAlert = true;
      },
      hideAllAlerts: (state) => {
        state.isShowMinimumSelectionAlert = false;
        state.saveStatus = false;
        state.saveStatusErr = false;
        state.scheduleBookStatus = false;
        state.scheduleBookErr = false;
        state.isShowProductRequiredAlert = false;
        state.isShowLinkCopiedAlert = false;
        state.isShowShareByEmailSuccessAlert = false;
        state.isShowShareByEmailFailAlert = false;
        state.isShowSaveForShareErrorAlert = false;
      },
      openSaveDesignLayoutRightPopUp: (state, action) => {
        if (Object.keys(action.payload).length > 0) {            
          state.isShowProductRequiredAlert = false;
          state.isSaveDesignLayoutRightPopUpActive = true;
        } else {  
          state.isShowProductRequiredAlert = true;
        }
      },
      saveMyWebBathShowerDesignClose:(state)=>{
        state.isSaveDesignLayoutRightPopUpActive = false;
      },
      bookNowPopUpDesingBathShower:(state)=>{
         state.bookNowDesingBathShowerStatus=true
      },
      getBookNowDesignBathStatusPopClose:(state)=>{
        state.bookNowDesingBathShowerStatus=false;
      },
      resetDesignId: (state) => {
        state.designId = -1;
      },
      setDesignId: (state, action) => {
        if (action.payload > 0) {
          state.designId = action.payload;
        }
      },
      clearUserSavedDesigns: (state) => {
        state.isUserDesignsFetched = false;
        state.loadExistingData = [];
      },
      showLinkCopiedAlert: (state) => {
        state.isShowLinkCopiedAlert = true;
      },
      showProductRequiredAlert: (state) => {
        state.isShowProductRequiredAlert = true;
      }
    },
    extraReducers: (builder) => {
        builder
          .addCase(getBlobStorageToken.fulfilled, (state, action) => {
            state.blobStorageAccountName = action.payload.accountName;
            state.blobStorageContainerName = action.payload.containerName;
            state.blobStorageSASToken = action.payload.sas_token;
          })
          .addCase(getBlobStorageToken.rejected, (state) => {
            state.blobStorageAccountName = '';
            state.blobStorageContainerName = '';
            state.blobStorageSASToken = '';
          })
          .addCase(addNewDesignBathShower.pending, (state) => {           
            state.saveDesignStatus = 'loading';          
          })
          .addCase(addNewDesignBathShower.fulfilled, (state, action) => {
            state.saveDesignStatus = 'idle';
            state.saveStatus = true;
            if (action.payload > 0) {
              state.designId = action.payload;
            }
          })
          .addCase(addNewDesignBathShower.rejected, (state, action) => {
            state.saveDesignStatus = 'failed';
            state.saveStatusErr = true;
            state.saveDesingErr   =  action.error.message;
          })
          .addCase(scheduleDesignBookNow.pending, (state) => {
            state.schedulePostStatus = 'loading';          
          })
          .addCase(scheduleDesignBookNow.fulfilled, (state, action) => {
            state.schedulePostStatus = 'idle';
            state.scheduleBookStatus = true;
            if (action.payload.design_id > 0) {
              state.designId = action.payload.design_id;
            }
          })
          .addCase(scheduleDesignBookNow.rejected, (state, action) => {
            state.schedulePostStatus = 'failed';
            state.scheduleBookErr = true;
            state.schedulePostErr    =  action.error.message;
          })         
          .addCase(fetchUserSavedDesigns.pending, (state) => {           
            state.loadExistingStatus = 'loading';          
          })
          .addCase(fetchUserSavedDesigns.fulfilled, (state, action) => {
            state.loadExistingStatus  = 'idle';
            state.loadExistingData    =action.payload
            state.isUserDesignsFetched = true;
          })
          .addCase(fetchUserSavedDesigns.rejected, (state, action) => {
            state.loadExistingStatus = 'failed';
            state.loadExistingErr    =  action.error.message;
            state.isUserDesignsFetched = true;
          })
          .addCase(saveDesignForSharing.pending, (state) => {
            state.saveForSocialStatus = 'loading';
          })
          .addCase(saveDesignForSharing.fulfilled, (state, action) => {
            state.saveForSocialStatus = 'idle';
            if (action.payload.shareDesignId > 0) {
              state.shareDesignId = action.payload.shareDesignId;
              state.socialImageUrl = `${window.config.REACT_APP_BACKEND_URL}/${action.payload.designImageUrl}`;
              state.shareLink = `${window.location.origin}/${action.payload.pathName}/${action.payload.catId}/${action.payload.shareDesignId}`;
            }
          })
          .addCase(saveDesignForSharing.rejected, (state, action) => {
            state.saveForSocialStatus = 'failed';
            state.socialErrStatus = action.error.message;
            state.isShowSaveForShareErrorAlert = true;
            state.isSocialPopUpActive = false;
            state.shareDesignId = -1;
            state.socialImageUrl = '';
            state.shareLink = '';
          })
          .addCase(shareDesignByEmail.pending, (state) => {
            state.shareByEmailStatus = 'loading';
          })
          .addCase(shareDesignByEmail.fulfilled, (state) => {
            state.shareByEmailStatus = 'idle';
            state.isShowShareByEmailSuccessAlert = true;
          })
          .addCase(shareDesignByEmail.rejected, (state) => {
            state.shareByEmailStatus = 'failed';
            state.isShowShareByEmailFailAlert = true;
          })
  },
   
});

export const { openSharePopUp, closeSharePopUp, openMobileSharePopUp, closeMobileSharePopUp, showMinimumSelectionAlert, hideAllAlerts, openSaveDesignLayoutRightPopUp, saveMyWebBathShowerDesignClose, bookNowPopUpDesingBathShower, getBookNowDesignBathStatusPopClose, resetDesignId, setDesignId, clearUserSavedDesigns, showLinkCopiedAlert, showProductRequiredAlert } = mobileMenuSlice.actions;

export const getIsSocialPopUpActive = (state) => state.mobilemenu.isSocialPopUpActive;
export const getIsMobileSocialPopUpActive = (state) => state.mobilemenu.isMobileSocialPopUpActive;
export const getIsShowMinimumSelectionAlert = (state)=>state.mobilemenu.isShowMinimumSelectionAlert;
export const getSaveForSocialStatus = (state) => state.mobilemenu.saveForSocialStatus;
export const getIsShowSaveForShareErrorAlert = (state) => state.mobilemenu.isShowSaveForShareErrorAlert;
export const getIsShowLinkCopiedAlert = (state) => state.mobilemenu.isShowLinkCopiedAlert;
export const getIsShowProductRequiredAlert = (state)=>state.mobilemenu.isShowProductRequiredAlert;
export const getIsSaveDesignLayoutRightPopUpActive = (state) => state.mobilemenu.isSaveDesignLayoutRightPopUpActive;
export const getSaveDesignStatus = (state) => state.mobilemenu.saveDesignStatus;
export const getBookNowDesignBathStatusPop = (state)=>state.mobilemenu.bookNowDesingBathShowerStatus;  
export const getBookConsultationStatus = (state) => state.mobilemenu.schedulePostStatus;
export const getIsShowSaveDesignSuccess    = (state)=>state.mobilemenu.saveStatus;
export const getIsShowSaveDesignError      = (state)=>state.mobilemenu.saveStatusErr;
export const getIsScheduleDesignBookNowSuccess = (state)=>state.mobilemenu.scheduleBookStatus;
export const getIsScheduleDesignBookNowError   = (state)=>state.mobilemenu.scheduleBookErr;
export const getSocialImageUrl             =(state)=>state.mobilemenu.socialImageUrl;

export const getIsUserDesignsFetched = (state) => state.mobilemenu.isUserDesignsFetched;
export const getLoadExistingStatus         =(state)=>state.mobilemenu.loadExistingStatus;
export const getLoadExistingData           =(state)=>state.mobilemenu.loadExistingData;

export const getShareDesignId = (state) => state.mobilemenu.shareDesignId;
export const getDesignId = (state) => state.mobilemenu.designId;
export const getShareLink = (state) => state.mobilemenu.shareLink;

export const getShareByEmailStatus = (state) => state.mobilemenu.shareByEmailStatus;
export const getIsShowShareByEmailSuccessAlert = (state) => state.mobilemenu.isShowShareByEmailSuccessAlert;
export const getIsShowShareByEmailFailAlert = (state) => state.mobilemenu.isShowShareByEmailFailAlert;

export default mobileMenuSlice.reducer;
