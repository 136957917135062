import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import i18next from 'i18next'
import { TranslationKeys } from '../../i18n'
import { GoogleDataLayer, getTagManagerEvents } from '../layout/GoogleDataLayer'

const ShowerData = ({chooseShower,parentId}) => {

  // Get shower type category translation from object if exist, default to shower type category name otherwise
  const categoryName = chooseShower["translations"] && chooseShower["translations"][TranslationKeys[i18next.language]] ?
    chooseShower["translations"][TranslationKeys[i18next.language]]:
    chooseShower.name;

  const location = useLocation();

  return (
    <div className="left-image">
      <div className="thumbnail-img">
        <Link
          to={`/${i18next.language}/shower-plumbing-position/showerId=${chooseShower.id}&parentId=${parentId}${location.search}`}
          onClick={() => GoogleDataLayer({
            event: getTagManagerEvents.selectShowerType,
            showerType: chooseShower.name
          })}
        >
          <img src={chooseShower.image.src?window.config.REACT_APP_BACKEND_URL+chooseShower.image.src:''} alt=""/>
          <p>{categoryName}</p>
          <div className='backgroundImage'><img src={chooseShower.background_image.src?window.config.REACT_APP_BACKEND_URL+chooseShower.background_image.src:''} alt=""/></div>
        </Link>
      </div> 
    </div>
  )
}
 
export default ShowerData