import { createAsyncThunk,createSlice } from "@reduxjs/toolkit";
import axios from "axios";

let initialState={
   
    status:'idle',
    error:'',
    orderData:[],
    orderId:'',
    statusSocial:'idle',
    errorSocial:'',
    socialOrderId:'',
    scheduleStatus:'idle',
    scheduleError:'',
    scheduleFromVal:''
    


}
const domainUrl    =window.config.REACT_APP_ORDER_URL;

const saveDesignUrl  =window.config.REACT_APP_SAVE_DESIGN_URL;

export const addNewDesign = createAsyncThunk('design/addNewDesign', async (payload,thunkApiToPostData) => {    
          const email            =payload.email;
          const bathId           =payload.bathId;
          const bathVariantId    =payload.bathVariantId;
          const bathImage        =payload.bathImage;

          const wallId           =payload.wallId;
          const wallVariantId    =payload.wallVariantId;
          const wallImage        =payload.wallImage;
          

          const faucet_id        =payload.faucet_id;
          const faucetVariantId  =payload.faucetVariantId;
          const faucetImage      =payload.faucetImage; 

          const shelve_id        =payload.shelve_id;
          const shelveVariantId  =payload.shelveVariantId;
          const shelveImage      =payload.shelveImage;

          const soap_id          =payload.soap_id;
          const soapVariantId    =payload.soapVariantId;
          const soapImage        =payload.soapImage;

          const sidebar_id       =payload.sidebar_id;
          const sidebarVariantId =payload.sidebarVariantId;
          const sideBarImage     =payload.sideBarImage;  

          const door_id          =payload.bathId;
          const variantDoorId    =payload.bathVariantId;

          const rod_id           =payload.bathId;
          const variantRod       =payload.bathVariantId;

          const doorRodImage     =payload.doorRodImage; 
          const bath_summary     =payload.bathSummaryImg;
          const wall_summary     =payload.wallSummaryImg;
          const faucet_summary   =payload.faucetSummaryImg;
          const soap_summary     =payload.soapSummaryImg;
          const shelve_summary   =payload.shelveSummaryImg;
          const bar_summary      =payload.barSummaryImg;
          const door_summary     =payload.doorSumamryImg;
          const rod_summary      =payload.rodSummaryImg;  


          const dataFinal = 
          {
                payment_method: "bacs",
                payment_method_title: "Direct Bank Transfer",
                set_paid: true,
                billing: {first_name: "John",last_name: "Doefdsfs",address_1: "969 Marketdsfsf",address_2: "",city: "San Franciscofsdfs",state: "CA", postcode: "94103fdsfs",country: "US",email: email, phone: "(555) 555-5555"},shipping: {first_name: "Johnfdsfs",last_name: "Doefdsfds",address_1: "969 Marketfdsfs",address_2: "",city: "San Francisco",state: "CA",postcode: "94103", country: "US"},
                meta_data:[
                    {
                        id:bathId?bathId:9999,
                        key:"bathImg",
                        value:bath_summary
                    },
                    {
                        id:wallId?wallId:9999,
                        key:"wallImg",
                        value:wall_summary
                    },
                    {
                        id:faucet_id?faucet_id:9999,
                        key:"faucetImg",
                        value:faucet_summary
                    },
                    {
                        id:soap_id?soap_id:9999,
                        key:"soapImg",
                        value:soap_summary
                    },
                    {
                        id:shelve_id?shelve_id:9999,
                        key:"shelveImg",
                        value:shelve_summary
                    },
                    {
                        id:sidebar_id?sidebar_id:9999,
                        key:"barImg",
                        value:bar_summary
                    },
                    {
                        id:door_id?door_id:9999,
                        key:"doorImg",
                        value:door_summary
                    },
                    {
                        id:rod_id?rod_id:9999,
                        key:"rodImg",
                        value:rod_summary
                    }
                ],
                line_items: [
                    {
                        product_id: bathId?bathId:9999,
                        variation_id: bathVariantId?bathVariantId:9999,
                        quantity: 1
                    },
                    {
                        product_id: wallId?wallId:9999,
                        variation_id: wallVariantId?wallVariantId:9999,
                        quantity: 1
                    },
                    {
                        product_id: faucet_id?faucet_id:9999,
                        variation_id: faucetVariantId?faucetVariantId:9999,
                        quantity: 1
                    },
                    {
                        product_id: shelve_id?shelve_id:9999,
                        variation_id: shelveVariantId?shelveVariantId:9999,
                        quantity: 1
                    },
                    {
                        product_id: soap_id?soap_id:9999,
                        variation_id: soapVariantId?soapVariantId:9999,
                        quantity: 1
                    },
                    {
                        product_id: sidebar_id?sidebar_id:9999,
                        variation_id: sidebarVariantId?sidebarVariantId:9999,
                        quantity: 1
                    },
                    {
                        product_id: door_id?door_id:rod_id?rod_id:9999,
                        variation_id: variantDoorId?variantDoorId:variantRod?variantRod:9999,
                        quantity: 1
                    }                                   
                ],shipping_lines: [{method_id: "flat_rate",method_title: "Flat Rate",total: "0.00"}]
          }; 
           try
            {
                const response = await axios.post(domainUrl,dataFinal)  
                console.log("Domain",domainUrl) 
                console.log("Bath",response)   
                thunkApiToPostData.dispatch(postData({'orderID':response.data.id,'email':email,'bathId':bathId,bath_image:bathImage,wall_image:wallImage,faucet_image:faucetImage,shelve_image:shelveImage,soap_image:soapImage,handle_image:sideBarImage,door_rod:doorRodImage}));          
                return response.data;
            } 
            catch(err)
            {
               return err.message;
            } 
});

export const postData = createAsyncThunk('design/postData', async (payload) => {    
           
        const order_id     =payload.orderID;
        const email        =payload.email;
        const bath_image   =payload.bath_image;
        const wall_image   =payload.wall_image;
        const faucet_image =payload.faucet_image;
        const shelve_image =payload.shelve_image;
        const soap_image   =payload.soap_image;
        const handle_image =payload.handle_image;
        const door_rod     =payload.door_rod;
        const relatedData ={orderId:order_id,email:email,bath_image:bath_image,faucet_position:'left',shelvePos:'left',wall_image:wall_image,faucet_image:faucet_image,shelve_image:shelve_image,soap_image:soap_image,handle_image:handle_image,door_rod:door_rod};               
        try
        {
            
            const response = await axios.post(saveDesignUrl,relatedData) 
            console.log("hello----",response.data)
            return response.data;
        } 
        catch(err)
        {
            console.log("err--",err)
            return err.message;
        } 
});

const SocialUrl=window.config.REACT_APP_SOCIAL_URL;
export const socialShareDesignSave=createAsyncThunk('design/socialShareDesignSave', async (payload,thunkApiToPostSocialData) => { 

          const bathId           =payload.bathId;
          const bathVariantId    =payload.bathVariantId;
          const bathImage        =payload.bathImage;
          const wallId           =payload.wallId;
          const wallVariantId    =payload.wallVariantId;
          const wallImage        =payload.wallImage;
          const faucet_id        =payload.faucet_id;
          const faucetVariantId  =payload.faucetVariantId;
          const faucetImage      =payload.faucetImage;
          const shelve_id        =payload.shelve_id;
          const shelveVariantId  =payload.shelveVariantId;
          const shelveImage      =payload.shelveImage;
          const soap_id          =payload.soap_id;
          const soapVariantId    =payload.soapVariantId;
          const soapImage        =payload.soapImage;
          const sidebar_id       =payload.sidebar_id;
          const sidebarVariantId =payload.sidebarVariantId;
          const sideBarImage     =payload.sideBarImage;
          const door_id          =payload.door_id;
          const variantDoorId    =payload.variantDoorId;
          const doorImage        =payload.doorImage;
          const rod_id           =payload.rod_id;
          const variantRod       =payload.variantRod;
          const rodImage         =payload.rodImage; 
          const bath_summary     =payload.bathSummaryImg;
          const wall_summary     =payload.wallSummaryImg;
          const faucet_summary   =payload.faucetSummaryImg;
          const soap_summary     =payload.soapSummaryImg;
          const shelve_summary   =payload.shelveSummaryImg;
          const bar_summary      =payload.barSummaryImg;
          const door_summary     =payload.doorSumamryImg;
          const rod_summary      =payload.rodSummaryImg;
          const dataFinal = 
          {
                payment_method: "bacs",
                payment_method_title: "Direct Bank Transfer",
                set_paid: true,
                billing: {first_name: "John",last_name: "Doefdsfs",address_1: "969 Marketdsfsf",address_2: "",city: "San Franciscofsdfs",state: "CA", postcode: "94103fdsfs",country: "US", phone: "(555) 555-5555"},shipping: {first_name: "Johnfdsfs",last_name: "Doefdsfds",address_1: "969 Marketfdsfs",address_2: "",city: "San Francisco",state: "CA",postcode: "94103", country: "US"},
                meta_data:[
                    {
                        id:bathId?bathId:9999,
                        key:"bathImg",
                        value:bath_summary
                    },
                    {
                        id:wallId?wallId:9999,
                        key:"wallImg",
                        value:wall_summary
                    },
                    {
                        id:faucet_id?faucet_id:9999,
                        key:"faucetImg",
                        value:faucet_summary
                    },
                    {
                        id:soap_id?soap_id:9999,
                        key:"soapImg",
                        value:soap_summary
                    },
                    {
                        id:shelve_id?shelve_id:9999,
                        key:"shelveImg",
                        value:shelve_summary
                    },
                    {
                        id:sidebar_id?sidebar_id:9999,
                        key:"barImg",
                        value:bar_summary
                    },
                    {
                        id:door_id?door_id:9999,
                        key:"doorImg",
                        value:door_summary
                    },
                    {
                        id:rod_id?rod_id:9999,
                        key:"rodImg",
                        value:rod_summary
                    }
                ],
                line_items: [
                    {
                        product_id: bathId?bathId:9999,
                        variation_id: bathVariantId?bathVariantId:9999,
                        quantity: 1
                    },
                    {
                        product_id: wallId?wallId:9999,
                        variation_id: wallVariantId?wallVariantId:9999,
                        quantity: 1
                    },
                    {
                        product_id: faucet_id?faucet_id:9999,
                        variation_id: faucetVariantId?faucetVariantId:9999,
                        quantity: 1
                    },
                    {
                        product_id: shelve_id?shelve_id:9999,
                        variation_id: shelveVariantId?shelveVariantId:9999,
                        quantity: 1
                    },
                    {
                        product_id: soap_id?soap_id:9999,
                        variation_id: soapVariantId?soapVariantId:9999,
                        quantity: 1
                    },
                    {
                        product_id: sidebar_id?sidebar_id:9999,
                        variation_id: sidebarVariantId?sidebarVariantId:9999,
                        quantity: 1
                    },
                    {
                        product_id: door_id?door_id:9999,
                        variation_id: variantDoorId?variantDoorId:9999,
                        quantity: 1
                    },
                    {
                        product_id: rod_id?rod_id:9999,
                        variation_id:variantRod?variantRod:9999,
                        quantity: 1
                    }                                     
                ],shipping_lines: [{method_id: "flat_rate",method_title: "Flat Rate",total: "0.00"}]
          }; 
          try
            {
                const response = await axios.post(domainUrl,dataFinal)  
                  
                thunkApiToPostSocialData.dispatch(postSocialData({'orderID':response.data.id,'bathId':bathId,bath_image:bathImage,wall_image:wallImage,faucet_image:faucetImage,shelve_image:shelveImage,soap_image:soapImage,handle_image:sideBarImage,door_rod:doorImage?doorImage:rodImage}));          
                return response.data;
            } 
            catch(err)
            {
               return err.message;
            } 

});

export const postSocialData = createAsyncThunk('design/postSocialData', async (payload) => {    
        console.log("SocialOrderId---",payload.orderID)   
        const order_id     =payload.orderID;
        const email        =payload.email;
        const bath_image   =payload.bath_image;
        const wall_image   =payload.wall_image;
        const faucet_image =payload.faucet_image;
        const shelve_image =payload.shelve_image;
        const soap_image   =payload.soap_image;
        const handle_image =payload.handle_image;
        const door_rod     =payload.door_rod;
        
        const relatedData ={orderId:order_id,email:email,bath_image:bath_image,faucet_position:'left',shelvePos:'left',wall_image:wall_image,faucet_image:faucet_image,shelve_image:shelve_image,soap_image:soap_image,handle_image:handle_image,door_rod:door_rod};               
        try
        {            
            const response = await axios.post(SocialUrl,relatedData) 
            return response.data;
        } 
        catch(err)
        {
            return err.message;
        } 
});

export const scheduleDesign=createAsyncThunk('design/scheduleDesign', async (payload) => { 
    const email     =payload.email;
    const firstName =payload.firstName;
    const lastName  =payload.lastName;
    const phoneNo   =payload.phoneNo;
    const zipCode   =payload.zipCode;
    const bookConsultationUrl =window.config.REACT_APP_BOOK_URL;
     const dataToSave={email:email,name:firstName,lastName:lastName,phone:phoneNo,zipCode:zipCode,order_id:0}
        try
        {            
            const response = await axios.post(bookConsultationUrl,dataToSave) 
             console.log("helloSocial----",response)
            return response;
        } 
        catch(err)
        {
            return err.message;
        } 
    

}) ;
export const popupSlice= createSlice({
            name:'designpop',
            initialState,
            // define reducer and auto generate the actions
            reducers:{                
                scheduleClose:(state)=>{
                   
                   state.scheduleFromVal=''                  
                },
            },
            extraReducers:(builder)=>           
            {
                builder
                        .addCase(addNewDesign.pending, (state,action) => {
                            state.status = 'loading';
                        })
                        .addCase(addNewDesign.fulfilled, (state, action) => {
                            console.log("before")
                            
                             state.status       = 'idle';  
                             const orderDataRes =action.payload;                           
                             state.orderData    =orderDataRes;
                             state.orderId      =orderDataRes.id;
                             
                        })
                        .addCase(addNewDesign.rejected, (state,action) =>{
                              state.status='failed'
                              state.error =action.error.message
                        })
                        .addCase(socialShareDesignSave.pending, (state,action) => {
                            state.statusSocial = 'loading';
                        })
                        .addCase(socialShareDesignSave.fulfilled, (state, action) => {
                             state.statusSocial        = 'idle';
                             const orderDataSocialRes  =action.payload; 
                             state.socialOrderId       =orderDataSocialRes.id;
                        })
                        .addCase(socialShareDesignSave.rejected, (state,action) =>{
                              state.statusSocial='failed'
                              
                        }).addCase(scheduleDesign.pending, (state,action) => {
                            state.scheduleStatus = 'loading';
                        })
                        .addCase(scheduleDesign.fulfilled, (state, action) => {
                             state.scheduleStatus        = 'idle';
                             state.scheduleFromVal       ='success';
                             
                        })
                        .addCase(scheduleDesign.rejected, (state,action) =>{
                              state.scheduleError='failed'
                              
                        });
                    
                                           
            },           
});
export const {scheduleClose}        =popupSlice.actions;
export const socialOrderId     =(state) =>state.popupdata.socialOrderId;
export const statusSocial     =(state) =>state.popupdata.statusSocial;
export const scheduleStatus     =(state) =>state.popupdata.scheduleStatus;
export const scheduleError     =(state) =>state.popupdata.scheduleError;
export const scheduleFromVal     =(state) =>state.popupdata.scheduleFromVal;
export const status              =(state)=>state.popupdata.status;

export default popupSlice.reducer;