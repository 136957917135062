import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { modifySummaryAssest, setProductRightVariationOptions } from '../designbathshower/designBathShowerSlice';

const SummaryBathShowerContent = ({summaryData,title}) => {
const dispatch  = useDispatch();    
const location  = useLocation();

const { t } = useTranslation();

  return (
 <>
   <div className="filtertext">
           <div className="col-sm-7 headingleft">
               <p className="mainhead">{title}</p>
           </div>
            <div className="col-sm-5 addbutton">
                <Link to={`${location.pathname}${location.search}`} className="clear clearModify"  onClick={()=> dispatch(modifySummaryAssest(title))}>{t("modify")}</Link>
            </div>
       </div>
       <div className='bathTubSummary'>
        <table>
           <tbody>
              {
                (summaryData.assetImage !==undefined)?(<tr>
                    <td>
                        <span className="images">
                            <img src={window.config.REACT_APP_BACKEND_URL+summaryData.assetImage} alt='' id="summaryBath" />
                        </span>
                    </td>
                    <td>{t("summary.productName")} : <br/><span>{summaryData.assetName}</span></td>
                    <td>
                        <span className="colorboxes">{t("color")} : <br/><span>{summaryData.assetColor}</span> </span>
                        <em className="colors white" style={{'background':summaryData.assetColorStyle}}></em>
                        
                        <span className="colorboxesMain"  onClick={ () => dispatch(setProductRightVariationOptions({
                                                            'parentCat': summaryData.selectedCat,
                                                            'variantId': summaryData.variantId,
                                                            'rightImageUrl': summaryData.rightImageUrl,
                                                            'name': summaryData.assetName,
                                                            'assetColor': summaryData.assetColor,
                                                            'assetColorStyle': summaryData.assetColorStyle,
                                                            'assetImage': summaryData.assetImage,
                                                            'assetId': summaryData.assetId,
                                                            'categoryName': title,
                                                            'childLen': 0,
                                                            'headerIndex': summaryData.headerIndex,
                                                            'mainCatName': summaryData.parentCatName,
                                                        }))}>{t("modify")}</span>
                    </td> 
                </tr>):(Object.values(summaryData).map(data=><tr key={data.assetId}>
                    
                   <td >
                       <span className="images">
                           <img src={window.config.REACT_APP_BACKEND_URL+data.assetImage} alt='' id="summaryBath" />
                       </span>
                   </td>
                   <td>{t("summary.productName")} : <br/><span>{data.assetName}</span></td>
                   <td>
                       <span className="colorboxes">{t("color")} : <br/><span>{data.assetColor}</span> </span>
                       <em className="colors white" style={{'background':data.assetColorStyle}}></em>
                       <span className="colorboxesMain"  onClick={() => dispatch(setProductRightVariationOptions({
                                                            'parentCat': data.parentCatName,
                                                            'variantId': data.variantId,
                                                            'rightImageUrl': data.rightImageUrl,
                                                            'name': data.assetName,
                                                            'assetColor': data.assetColor,
                                                            'assetColorStyle': data.assetColorStyle,
                                                            'assetImage': data.assetImage,
                                                            'assetId': data.assetId,
                                                            'categoryName': data.selectedCat,
                                                            'childLen': data.childLen,
                                                            'headerIndex': data.headerIndex,
                                                            'mainCatName': data.Eng.category,
                                                        }))}>{t("modify")}</span>
                   </td> 
               </tr>))
              }
           </tbody>
         </table>
       </div>
        </>
  )
}

export default SummaryBathShowerContent