export default function ZipCodeInputField({name, placeholder, register, requiredErrorMessage, patternErrorMessage, errors}) {
    return (
        <div className="col-sm-12 fullform">
            <input type="text"
                className="form-control"
                name={name}
                placeholder={placeholder}
                {...register(name, {
                    required: requiredErrorMessage,
                    pattern: {
                        value: /^(\d{5}(?:-\d{4})?)$|^([ABCEGHJKLMNPRSTVXYZ]\d[ABCEGHJKLMNPRSTVWXYZ]) {0,1}(\d[ABCEGHJKLMNPRSTVWXYZ]\d)$/i,
                        message: patternErrorMessage
                    }
                })}
                maxLength={10}
            />
            <small className="form-text text-muted">
                {
                    errors[name] && <p>{errors[name].message}</p>
                }
            </small>
        </div>
    )
}
