import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import i18next from 'i18next';
import { TranslationKeys } from '../../i18n';
import ArrowRight from '../../images/arrow-right.svg'
import { GoogleDataLayer, getTagManagerEvents } from '../layout/GoogleDataLayer';

const ShowerPlumbingMobileData = ({showerData}) => {

    const location = useLocation();
    const categoryName = showerData["translations"] && showerData["translations"][TranslationKeys[i18next.language]] ?
        showerData["translations"][TranslationKeys[i18next.language]] :
        showerData.name;

  return (
	<Link
		to={`/${i18next.language}/design-your-own-shower/${showerData.id}${location.search}`}
		onClick={() => GoogleDataLayer({
			event: getTagManagerEvents.selectPlumbingSide,
			projectType: 'Shower',
			plumbingSide: showerData.name
		})}
	>
        <div className="item-outer">
			<img src={showerData.background_image?window.config.REACT_APP_BACKEND_URL+showerData.background_image.src:null} alt={categoryName}/>
			<div className="item-title">
				<p>{categoryName}</p>
				<img src={ArrowRight} alt="arrow"/>
			</div>
		</div>
	</Link>
  ) 
}

export default ShowerPlumbingMobileData