import React from 'react'

export const MobileDesignLayoutRight = ({data}) => {
  
  let grabIf
if(data.catNameEng!==undefined)
{
  if(data.catNameEng.toLowerCase().replace(/\s/g, '')==='grabbars' || data.catNameEng.toLowerCase().replace(/\s/g, '')==='grabbar' )
  {
    grabIf='grabBar'
  }
  else{
    grabIf=data.headerIndex
  }
}
else{
  grabIf=data.headerIndex
}
  return (
    <div className={`productMob index${grabIf}`}>      
      <img
        src={data.rightImageUrl?window.config.REACT_APP_BACKEND_URL+data.rightImageUrl:''}
        alt=''
      />
    </div>
  )
}
 