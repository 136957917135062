import React, { Component } from "react";
import Slider from "react-slick";
import IntroImage from '../../images/intro-image.png';
import Slide from '../../images/slide2.png';
import SlideT from '../../images/slide3.png';
export default class MobileHomeSlider extends Component {
  render() {
    const settings = {
      centerMode: true,
      centerPadding: '60px',
      slidesToShow: 1,
      arrows: true,
      dots: true,
      autoplay: true,
      autoplaySpeed: 2000,
      
    };
    return (    
      <div className="center-slider">
        <Slider {...settings}>
                        
                               <div className="slide"><img src={IntroImage} alt=""/></div>                        
                                <div className="slide"><img src={Slide} alt=""/></div>                        
                                <div className="slide"><img src={SlideT} alt=""/></div>
                                
        </Slider>
        </div>  
    );
  }
}