import { BlobServiceClient } from '@azure/storage-blob';
import { Buffer } from 'buffer';

const BlobStorage = async (bigImage, accountName, containerName, sasToken) => {
  if (bigImage === '' || accountName === '' || containerName === '' || sasToken === '') {
    throw Error('The image was not uploaded to the Blob storage countainer');
  }

  const fileName = `${Date.now()}-blob-image.png`;
  
  // Create a container client
  const blobServiceClient = new BlobServiceClient(`https://${accountName}.blob.core.windows.net?${sasToken}`);
  const containerClient = blobServiceClient.getContainerClient(containerName);
  
  // Create blob from image
  const byteString = Buffer.from(bigImage.split(',')[1], 'base64');
  const blob =  new Blob([byteString]);
  
  // Upload the blob to the container
  const blockBlobClient = containerClient.getBlockBlobClient(fileName);
  await blockBlobClient.upload(blob, blob.size);
  
  if (blockBlobClient.url) {
    return `https://${accountName}.blob.core.windows.net/${containerName}/${fileName}`;
  } else {
    throw Error('The image was not uploaded to the Blob storage countainer');
  }
}

export default BlobStorage