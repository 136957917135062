import React from 'react'
import { useTranslation } from 'react-i18next'
import VarinatSelector from '../../images/selectcolor.png'
import DesingChildAllColor from './DesingChildAllColor'
const DesignChildColorV = ({childColorData,childTitle,allData,parentCatName,childLen,headerIndexKey}) => {

let colorSelected,allChildColors,childTitleName,childSelectdColr;    
colorSelected       =childColorData.otherData.assetColor;
childTitleName      =childTitle.toLowerCase().replace(/\s/g, '');

childSelectdColr    =childColorData.otherData.selectedCat.toLowerCase().replace(/\s/g, '')

allChildColors      =childColorData.mainData.map(childAllColors=><DesingChildAllColor key={childAllColors.id} childColorData={childAllColors} variantId={childColorData.otherData.variantId} assetId={childColorData.otherData.assetId} allData={allData} parentCatName={parentCatName} childLen={childLen} headerIndex={headerIndexKey} mainCatName={childColorData.otherData.mainCatName} catNameEng={childColorData.otherData.catNameEng}/>).reverse()

  const { t } = useTranslation();

  return (
    (childSelectdColr===childTitleName)?(<div className="col-sm-12 colors">
           <div className="bordercolor">                    
               <span><i><img src={VarinatSelector} alt=""/></i>{t("selectColor")}</span>
               {allChildColors}
               <span className="selected">{t("selected")}: <em>{colorSelected}</em></span>
           </div>
           </div>):''
  )
}

export default DesignChildColorV