import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getIsNewScreenshotNeeded, getTakeDesignScreenshotStatus, getVariantAllData, takeDesignScreenshot } from '../designbathshower/designBathShowerSlice'
import { MobileDesignLayoutRight } from './MobileDesignLayoutRight'
import { getBigImageSrcUrl } from '../designbathshower/designBathShowerSlice'
import { getVariantDetailStatus,getLoaderVariantChange } from '../designbathshower/designBathShowerSlice'
import LoaderDesign from '../loader/LoaderDesign'

const MobileDesignLayout = () => {
  const dispatch = useDispatch();
  const allVarinatData     = useSelector(getVariantAllData);
  const isNewScreenshotNeeded = useSelector(getIsNewScreenshotNeeded);
 
  useEffect(() => {
    if (isNewScreenshotNeeded) {
      dispatch(takeDesignScreenshot({
        isMobile: true
      }));
    }
  }, [dispatch, isNewScreenshotNeeded]);

let content;
if(Object.keys(allVarinatData).length > 0)
{    
  content = Object.entries(allVarinatData).map(([key,data])=><MobileDesignLayoutRight key={key} data={data.otherData} dataClass={key} />)
}

let bigImage=useSelector(getBigImageSrcUrl)
const productVariantsStatus = useSelector(getVariantDetailStatus);
const variantImageStatus = useSelector(getLoaderVariantChange);
const takeDesignScreenshotStatus = useSelector(getTakeDesignScreenshotStatus);

  return (
    <>
      {
        productVariantsStatus === 'loading' ||
        variantImageStatus ==='loading' ||
        takeDesignScreenshotStatus === 'loading' ? (
          <LoaderDesign />
        ) : null
      }
      <span className="fullimages" id="full-design-mobile">
        {
          bigImage ? (
            <img src={window.config.REACT_APP_BACKEND_URL + bigImage.src}
              alt=''
            />
          ) : null
        }
        {content}
      </span>
    </>
  )
}

export default MobileDesignLayout