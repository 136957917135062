const Capital = (input) => {
  if(input !==undefined)
  {
      let capitalizedWords = [];
      input.split(' ').forEach(element => {
        capitalizedWords.push(element.charAt(0).toUpperCase() + element.slice(1).toLowerCase());
      });
      return capitalizedWords.join(' ');
  }
  

}

export default Capital
