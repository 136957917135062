import { createSlice } from "@reduxjs/toolkit";

let initialState={
    summaryData:'',
    saveMyDesignStatus:false,
    summaryPopUpStatus:false,
    startOverPopUpStatus:false,
    socialShareStatus:false,
    bookNowStatus:false,
    alertStatus:false,
    webSavePopStatus:false,
    webSharePopStatus:false,
    alertWebSaveStatus:false,
    copyLinkStatus:false,
    isTutorialPopUpActive: isCookiePresent('isDesktopTutorialCompleted') ? false : true,
    currentTutorialStep:1,
    isMobileTutorialPopUpActive: isCookiePresent('isMobileTutorialCompleted') ? false : true
}

function setCookie(cookieName) {
    var cookieExpiration = new Date();
    cookieExpiration.setTime(cookieExpiration.getTime() + (30 * 24 * 60 * 60 * 1000)); // set expiration date to 30 days from now
    document.cookie = `${cookieName}=true;expires=${cookieExpiration.toUTCString()};path=/;SameSite=Lax`;
}

function isCookiePresent(cookieName) {
    const cookie = document.cookie.split(';').find(cookie => cookie.trim().startsWith(`${cookieName}=`));
    return cookie;
}

export const summarySlice= createSlice({
            name:'summary',
            initialState,
            // define reducer and auto generate the actions
            reducers:{                
                getSummaryPage:(state)=>{
                   state.summaryPopUpStatus=true                  
                },
                saveMyDesign:(state,action)=>{
                    if(action.payload.bathImg==='' && action.payload.wallImage==='' && action.payload.faucetImg===null && action.payload.grabImg==='' && action.payload.rodImg==='' && action.payload.shelveImg===''  && action.payload.soapImg==='')
                    {
                        state.alertStatus=true
                        
                    }
                    else{
                       state.saveMyDesignStatus=true 
                       state.alertStatus=false
                    }
                   
                },
                saveMyWebDesign:(state,action)=>{
                   console.log("payload",action)
                    
                    if(action.payload.bathImg==='' && action.payload.wallImg==='' && action.payload.faucetImg===null && action.payload.shelveImg==='' && action.payload.soapImg==='' && action.payload.sideBarImg==='' && action.payload.rodImg==='' && action.payload.doorImg==='')
                    {        
                                       
                        state.alertWebSaveStatus=true                        
                    }
                    else
                    {
                        state.webSavePopStatus=true
                        state.alertWebSaveStatus=false
                    }
                   
                },
                alertWebClose:(state)=>{
                  
                  state.alertWebSaveStatus=false
                  state.webSavePopStatus=false
                },
                saveMyWebDesignClose:(state)=>{
                   state.webSavePopStatus=false
                },
                webSharePop:(state,action)=>{
                  
                   if(action.payload.bathImg==='' && action.payload.wallImg==='' && action.payload.faucetImg===null && action.payload.shelveImg==='' && action.payload.soapImg==='' && action.payload.sideBarImg==='' && action.payload.rodImg==='' && action.payload.doorImg==='' )
                   {
                      state.alertWebSaveStatus=true 
                   }
                   else
                   {
                     state.alertWebSaveStatus=false 
                     state.webSharePopStatus=true
                   }

                   
                },
                webSharePopClose:(state)=>{
                   state.webSharePopStatus=false 
                },
                saveDesignClose:(state)=>{
                   state.saveMyDesignStatus=false 
                },
                summaryClose:(state)=>{
                    state.summaryPopUpStatus=false 
                },
                goSummaryPage:(state)=>{
                  state.summaryData='active' 
                  state.summaryPopUpStatus=false 
                },
                startOverPopUp:(state)=>{                    
                    state.startOverPopUpStatus=true
                },
                startOverSavePopUp:(state)=>{
                    state.startOverPopUpStatus=false
                    state.saveMyDesignStatus=true 
                },
                socialShare:(state,action)=>{
                    console.log(action.payload)
                    if(action.payload.bathImg==='' && action.payload.wallImage==='' && action.payload.faucetImg===null && action.payload.grabImg==='' && action.payload.rodImg==='' && action.payload.shelveImg===''  && action.payload.soapImg==='')
                    {
                         state.alertStatus=true
                    }
                    else{
                        state.alertStatus=false
                        state.socialShareStatus=true
                    }
                   
                },
                alertClose:(state)=>{
                   state.alertStatus=false 
                },
                startOverClose:(state)=>{
                    state.startOverPopUpStatus=false
                },
                shareClose:(state)=>{
                    state.socialShareStatus=false
                },
                bookNowPopUp:(state)=>{
                    state.bookNowStatus=true
                },
                bookNowClose:(state)=>{
                    state.bookNowStatus=false
                },
                copyLink:(state)=>{
                   state.copyLinkStatus=true
                   state.socialShareStatus=false
                },
                copyClose:(state)=>{
                   state.copyLinkStatus=false
                },
                tutorialClosePopUp : (state) => {
                    setCookie('isDesktopTutorialCompleted');
                    state.isTutorialPopUpActive = false;
                },
                tutorialNextStep : (state) => {
                    if (state.currentTutorialStep === 3) {
                        setCookie('isDesktopTutorialCompleted');
                        state.isTutorialPopUpActive = false;
                    } else {
                        state.currentTutorialStep++;
                    }
                },
                tutorialGoToStep : (state, action) => {
                    if (state.currentTutorialStep !== action.payload)
                        state.currentTutorialStep = action.payload;
                },
                mobileTutorialClosePopUp : (state) => {
                    setCookie('isMobileTutorialCompleted');
                    state.isMobileTutorialPopUpActive = false;
                }
            }       
});

export const {getSummaryPage,saveMyDesign,saveDesignClose,summaryClose,goSummaryPage,startOverPopUp,startOverSavePopUp,socialShare,startOverClose,shareClose,bookNowPopUp,bookNowClose,alertClose,saveMyWebDesign,saveMyWebDesignClose,webSharePop,webSharePopClose,alertWebClose,copyLink,copyClose,tutorialClosePopUp,tutorialNextStep,tutorialGoToStep,mobileTutorialClosePopUp}        =summarySlice.actions;

export const summaryData                         =(state)=>state.summary.summaryData;
export const saveMyDesignStatus                  =(state)=>state.summary.saveMyDesignStatus;

export const webSavePopStatus                    =(state)=>state.summary.webSavePopStatus;

export const alertWebSaveStatus                  =(state)=>state.summary.alertWebSaveStatus;
export const webSharePopStatus                   =(state)=>state.summary.webSharePopStatus;
export const summaryPopUpStatus                  =(state)=>state.summary.summaryPopUpStatus;
export const startOverPopUpStatus                =(state)=>state.summary.startOverPopUpStatus;
export const socialShareStatus                   =(state)=>state.summary.socialShareStatus;
export const alertStatus                         =(state)=>state.summary.alertStatus;
export const bookNowStatus                       =(state)=>state.summary.bookNowStatus;
export const allSelected                         =(state)=>state.summary.allSelected;
export const copyLinkStatus                      =(state)=>state.summary.copyLinkStatus;
export const getIsDesktopTutorialActive = (state) => state.summary.isTutorialPopUpActive;
export const getCurrentTutorialStep = (state) => state.summary.currentTutorialStep;
export const getIsMobileTutorialActive = (state) => state.summary.isMobileTutorialPopUpActive;


export default summarySlice.reducer;

