import { createAsyncThunk,createSlice } from "@reduxjs/toolkit";
import axios from "axios";

let initialState={
    status  :'idle',
    error   :null,
    catData :[],
    plumbingPosData:[],
    plumbstatus:'idle',
    plumberror:null,
    parentId:null,
    chooseShowerData:[],
    chooseShowerParentId:null,
    plumbshowerstatus:'idle',
    plumbshowererr:null,
    showerLeftRightStatus:'idle',
    showerLeftRightErr:null,
    showerLeftRightData:[]
}
const domainUrl    =window.config.REACT_APP_PRODUCT_URL;

const requestOptions = {
    auth: {
        username: window.config.REACT_APP_CONSUMER_KEY,
        password: window.config.REACT_APP_CONSUMER_SECRET
    }
};
// will call the async thunk function 
export const fetchCatData = createAsyncThunk('cat/fetchCatData', async () => {
    const CAT_URL=domainUrl+'/categories/?parent=0&orderby=id';
        try
        {
            const response = await axios.get(CAT_URL,requestOptions)  
            return response.data;
        } 
        catch(err)
        {
            return err.message;
        }            
});

export const getPlumbingPositions =createAsyncThunk('cat/getPlumbingPositions',async(payload)=>{
   
     const CAT_URL=domainUrl+'/categories/?orderby=id&parent='+payload;
        try
        {
            const response = await axios.get(CAT_URL,requestOptions)
            if(response.data.length>0)
            {
                return response.data;
            }            
        } 
        catch(err)
        {
            return err.message;
        }    
});

export const getShowerPlumbingPositions =createAsyncThunk('fetchShowerPlumbingPositions/getShowerPlumbingPositions',async(payload)=>{
   
    const CAT_URL=domainUrl+'/categories/?orderby=id&parent='+payload;
    try
    {
        const response = await axios.get(CAT_URL,requestOptions)  
        
        if(response.data.length>0)
        {
            return response.data;
        }        
    } 
    catch(err)
    {
        return err.message;
    }  
})

export const getShowerPlumbingLeftRight =createAsyncThunk('fetchShowerPlumbingLeftRight/getShowerPlumbingLeftRight',async(payload)=>{
    const CAT_URL=domainUrl+'/categories/?orderby=id&parent='+payload;
    try
    {
        const response = await axios.get(CAT_URL,requestOptions) 
        
        if(response.data.length>0)
        {          
            return response.data;
        }
    } 
    catch(err)
    {
        return err.message;
    }  
})
export const categoryDataSlice= createSlice({
            name:'cat',
            initialState,
            // define reducer and auto generate the actions
            reducers:{},
            extraReducers:(builder)=>           
                {
                builder
                .addCase(fetchCatData.pending, (state,action) => {
                    state.status       = 'loading';
                })
                .addCase(fetchCatData.fulfilled, (state, action) => {
                     state.status      =  'idle';             
                     const loadedCats  =  action.payload;
                     
                     state.catData     =  loadedCats;
                })
                .addCase(fetchCatData.rejected, (state,action) =>{
                      state.status     = 'failed'
                      state.error      =  action.error.message
                })
                .addCase(getPlumbingPositions.pending, (state,action) => {
                    state.plumbstatus       = 'loading';
                })
                .addCase(getPlumbingPositions.fulfilled, (state, action) => {
                     state.plumbstatus      =  'idle';             
                     const loadedPlumbs     =  action.payload;  
                     
                     if(action.payload.length >2)
                     {
                           state.chooseShowerData        =  loadedPlumbs;
                           state.chooseShowerParentId    =  loadedPlumbs[0].parent;
                     }
                     else
                     {
                        if(action.payload===undefined)
                        {
                           state.plumbingPosData  =  [];
                           state.parentId         =  null;
                        } 
                        else{                        
                           state.plumbingPosData  =  loadedPlumbs;
                           state.parentId         =  loadedPlumbs[0].parent;
                        } 
                     }
                })
                .addCase(getPlumbingPositions.rejected, (state,action) =>{
                      state.plumbstatus = 'failed'
                      state.error       =  action.error.message
                })
                .addCase(getShowerPlumbingPositions.pending, (state,action) => {
                    state.plumbshowerstatus       = 'loading';
                })
                .addCase(getShowerPlumbingPositions.fulfilled, (state, action) => {
                     state.plumbshowerstatus      =  'idle';             
                     const loadedPlumbs     =  action.payload;
                     
                    if(action.payload===undefined)
                    {
                        state.chooseShowerData  =  [];
                        state.chooseShowerParentId         =  null;
                    } 
                    else{                        
                        state.chooseShowerData  =  loadedPlumbs;
                        state.chooseShowerParentId         =  loadedPlumbs[0].parent;
                    } 
                })
                .addCase(getShowerPlumbingPositions.rejected, (state,action) =>{
                      state.plumbshowerstatus    = 'failed'
                      state.plumbshowererr       =  action.error.message
                })
                .addCase(getShowerPlumbingLeftRight.pending, (state,action) => {
                    state.showerLeftRightStatus    = 'loading';
                })
                .addCase(getShowerPlumbingLeftRight.fulfilled, (state, action) => {
                     state.showerLeftRightStatus   =  'idle';             
                     const leftRightData           =  action.payload;                     
                     state.showerLeftRightData     =  leftRightData;
                })
                .addCase(getShowerPlumbingLeftRight.rejected, (state,action) =>{
                      state.showerLeftRightStatus  = 'failed'
                      state.showerLeftRightErr     =  action.error.message
                });                        
            },
});

export const selectAllCat           =(state) =>state.cat.catData;
export const getCatStatus           =(state) =>state.cat.status;
export const getCatErrors           =(state) =>state.cat.error;

export const selectAllPlumbData     =(state) =>state.cat.plumbingPosData;
export const getPlumbStatus         =(state) =>state.cat.status;
export const getPlumbErrors         =(state) =>state.cat.error;

export const parentId               =(state) =>state.cat.parentId

export const selectShowerList       =(state)=>state.cat.chooseShowerData;
export const showerParentId         =(state)=>state.cat.chooseShowerParentId;
export const chooseshowerstatus     =(state)=>state.cat.plumbshowerstatus;
export const chooseshowererr        =(state)=>state.cat.plumbshowererr; 

export const getLeftRightShower       =(state)=>state.cat.showerLeftRightData;
export const getLeftRightShowerStatus  =(state)=>state.cat.showerLeftRightStatus;


export default categoryDataSlice.reducer;