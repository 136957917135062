import React from 'react'
import { useDispatch ,useSelector} from 'react-redux';
import i18next from 'i18next';
import { TranslationKeys } from '../../i18n';
import { GoogleDataLayer, getTagManagerEvents } from '../layout/GoogleDataLayer';
import { getVariantDetails,setOrderSelectedSubCat,getOrderSelectedSubCat,removeSelectedSubCat,setCategoriesScrollColorSelection,getCategoriesScrollColorSelection, removeSingleProduct} from '../designbathshower/designBathShowerSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const MobileDesignChildInnerData = ({childInnerData,childLen,index,parentId,headerIndex}) => {
const dispatch =useDispatch();
const categoriesScrollColorSelection = useSelector(getCategoriesScrollColorSelection);

let variantId, colorName, colorHex, variantImage,categoryName,productName;

 
      productName = childInnerData.meta_data.filter(m => m.key === TranslationKeys[i18next.language]).length === 0 ?
      childInnerData.name :childInnerData.meta_data.filter(m => m.key === TranslationKeys[i18next.language])[0].value;
      
      
      categoryName = childInnerData.categories[0][TranslationKeys[i18next.language]] ?childInnerData.categories[0][TranslationKeys[i18next.language]] :childInnerData.categories[0].name;
      
      
      if (childInnerData.default_attributes[0]) {
      variantId = childInnerData.default_attributes[0].variantId;
      colorName = childInnerData.default_attributes[0][TranslationKeys[i18next.language]] ? childInnerData.default_attributes[0][TranslationKeys[i18next.language]] : childInnerData.default_attributes[0].option;
      colorHex = childInnerData.default_attributes[0].value;
      variantImage = childInnerData.images[0]?childInnerData.images[0].src:'';
      } else {
      variantId = childInnerData.product_variations[0].id;
      colorName = childInnerData.product_variations[0].name;
      colorHex = childInnerData.product_variations[0].value;
      variantImage = childInnerData.images[0]?childInnerData.images[0].src:'';
      }
      const orderSelectedSubCat=useSelector(getOrderSelectedSubCat);
      let ObjectLen=Object.keys(orderSelectedSubCat).length;

      let childSelectedVar=orderSelectedSubCat[parentId];

      const handleChildAssetVariantImage = () => {
        dispatch(getVariantDetails({
          'product_variations': childInnerData.product_variations,
          'mainCatName'       : childInnerData.categories[0].name,
          'categoryName'      : categoryName,
          'name'              : productName,
          'assetId'           : childInnerData.id,
          'variantId'         : variantId,
          'assetColor'        : colorName,
          'assetColorStyle'   : colorHex,
          'assetImage'        : variantImage,
          'parentCat'         : parentId, 
          'childLen'          : childLen ,
          'headerIndex'       : headerIndex,
          'Eng'            : {'product_name':childInnerData.name,'color':childInnerData.product_variations[0].name,'category':childInnerData.categories[0].name}  
        }));
        GoogleDataLayer({
          event: getTagManagerEvents.selectProduct,
          productName: childInnerData.name,
          gaProductCode: `${childInnerData.categories[0].name}-${childInnerData.name}`,
          productCategory: childInnerData.categories[0].name
        });
      }

      const handleSelectedChildAssetVariantImage = () => {
        handleChildAssetVariantImage();
        dispatch(
          removeSelectedSubCat({
            'parentCat'     : parentId,
            'subCategory'   : categoryName,
            'childLen'      : childLen ,
          })
        );
        dispatch(
          setOrderSelectedSubCat({
            'parentCat'     : parentId,
            'categoryName'  : categoryName,
            'assetId'       : childInnerData.id,
          })
        );
        if(!Object.keys(categoriesScrollColorSelection).includes(parentId)){
          dispatch(setCategoriesScrollColorSelection(parentId));
        }
      }
      
      const handleRemoveSelection = (e) => {
        e.stopPropagation();
        dispatch(removeSingleProduct({'categoryName': parentId, 'subCategoryName': categoryName}));
      };
      
      return (
        <li className="col-sm-4">
          <div className={`padding ${ObjectLen > 0 && childSelectedVar && Object.values(childSelectedVar).indexOf(childInnerData.id) !== -1 ? 'active' : ''}`} onClick={handleSelectedChildAssetVariantImage}>

            <span className="imagesborder">
              <img src={childInnerData.images[0]?.src ? window.config.REACT_APP_BACKEND_URL + childInnerData.images[0].src : ''} alt="" />
              {ObjectLen > 0 && childSelectedVar && Object.values(childSelectedVar).indexOf(childInnerData.id) !== -1 ? (
                <FontAwesomeIcon icon={faTimes} className="chekedIcon" onClick={handleRemoveSelection} />
              ) : null}
            </span>
            <span className="tittle">{productName}</span>
          </div>
        </li>
      )
}

export default MobileDesignChildInnerData