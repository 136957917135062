import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import i18next from 'i18next'; 
import { TranslationKeys } from '../../i18n';
import { GoogleDataLayer, getTagManagerEvents } from '../layout/GoogleDataLayer';

const ShowerPlumbingData = ({showerData}) => { 
  
  // Get plumbing category translation from object if exist, default to plumbing category name otherwise
  const categoryName = showerData["translations"] && showerData["translations"][TranslationKeys[i18next.language]] ?
    showerData["translations"][TranslationKeys[i18next.language]] :
    showerData.name;

  const location = useLocation();

  return (
    <>
      <Link
        to={`/${i18next.language}/design-your-own-shower/${showerData.id}${location.search}`}
        onClick={() => GoogleDataLayer({
          event: getTagManagerEvents.selectPlumbingSide,
          projectType: 'Shower',
          plumbingSide: showerData.name
        })}
      >
        <div className={showerData.name.toLowerCase().includes('left') ? 'left-image' : 'right-image'}>
          <div className="thumbnail-img">
            <img src={showerData.image.src?window.config.REACT_APP_BACKEND_URL+showerData.image.src:''} alt=""/>
          </div>
          <div className="hover-icon"><span><i className="fas fa-arrow-right"></i></span></div>
          <p>{categoryName}</p>
        </div>
      </Link>
    </>
  )
}

export default ShowerPlumbingData