import React from 'react'
import { Trans } from 'react-i18next'
import PdfLogo from '../../images/PDFlogo.png'
import '../../Pdf.css'


const DesignSummaryPrint = React.forwardRef((props,ref) => (

    <div ref={ref} style={{'padding':'20px'}}>
             <div  style={{"width": "100%","float": "left","margin":"0 0 40px"}}>
            <div style={{"width": "60%", "float": "left", "margin": "auto"}}>
                <span style={{"width": "100%","display": "inline-block","margin":"auto"}}>
                    <img src={PdfLogo} alt=''/>
                </span>
                 <p id="designDetails">{props.t("summary.details")}</p>
                <span id='creationDate'>{props.t("summary.creationDate")}: {props.pdfDate}</span>
                 
            </div>
            <div id='defaultImage'>
                <span id="defaultSpanImg">
                <img src={props.designImage} id='defaultImgsize' alt=''/>
                </span>
                
            </div>
            <div id="firstMainDiv" >
                     <div id="secondMainChildDiv">
                        {
                               Object.entries(props.summaryData).map(([index,data])=> 
                                  (data.assetImage!==undefined)?(<div key={index}>
                                     <div className="BathTubTitle">
                                               <p className="tubPtag">{index}</p> 
                                      </div>
                                            <table  className='bathTable'>
                                            <tbody>
                                                <tr>
                                                    <td  className='tdbathfirst'>
                                                        <span  className='spanFirst'>
                                                            <img src={window.config.REACT_APP_BACKEND_URL+data.assetImage} alt="" id='bathImagetd' className='bathImagetd'/>
                                                        </span>
                                                    </td>
                                                    <td  className='tdbathsecond'>
                                                        <span  className='titleWt'>{props.t("summary.productName")} :</span> <br />
                                                        {data.assetName}
                                                    </td>
                                                </tr>
                                                <tr>
                                                   <td  className='tdbathsecond'></td>
                                                    <td className='tdbathsecond'>
                                                        <span className='titleColor'>{props.t("color")} : </span> <br />
                                                        {data.assetColor}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table></div>):null
                               )
                        }
                    </div>
                    <div id='rightChildDiv'>
                      
                    {
                               Object.entries(props.summaryData).map(([index,data])=>
                                  (data.assetImage===undefined)?(
                                        Object.values(data).map(dataRes=>
                                            <div key={dataRes.assetName}>
                                                <div id='rightAccessDiv'>
                                                <p id='rightAccessP'>{index}</p> 
                                                </div>
                                                <table id='rightTable'>
                                                        <tbody>
                                                        <tr>
                                                            <td id='rightTableTdF'>
                                                                <span id='rightSapn'>
                                                                <img src={window.config.REACT_APP_BACKEND_URL+dataRes.assetImage} alt="" id='shelveImg' />
                                                                </span>
                                                            </td>
                                                            <td id='shelveSecTd'>
                                                                <span id="titleWt">{props.t("summary.productName")} :</span> <br />
                                                                {dataRes.assetName}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td id='tdbathsecond'></td>
                                                            <td id='tdbathsecond'>
                                                            <span id="titleColor">{props.t("color")} : </span> <br />
                                                            {dataRes.assetColor}
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                </table>
                                            </div>
                                        )
                                   ):null
                                   
                               )
                        }
                            
                    </div>
            </div>
        </div>
        <div id='footerDiv'>
            <span id='readyFooter'>{props.t("summary.printFooterQuestion")}</span>
        </div>
        <div id='footerSecond'>
            <p id='FooterP'>
            <Trans i18nKey="summary.printFooterCall">
                <em id='emOne'>CALL</em> to book your <em id='emOne'>FREE</em> design <em id='emSecond'>CONSULTATION</em><br/><em id='emThired'>1-800-000-0000</em>
            </Trans>
            </p>
        </div>
      </div>
  
  ));

export default DesignSummaryPrint