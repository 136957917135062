import React from 'react'
import { useDispatch ,useSelector} from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import i18next from 'i18next';
import { TranslationKeys } from '../../i18n';
import { getVariantDetails,getSelectedAssetsID,removeSelectedSubCat,setOrderSelectedSubCat, removeSingleProduct, setHandleHeaderMenuClick } from './designBathShowerSlice'
import { GoogleDataLayer, getTagManagerEvents } from '../layout/GoogleDataLayer';

const DesignBathShowerChildInner = ({data,parentCatName,childLen,headerIndexKey}) => {
    const dispatch = useDispatch();
    const selectedVariant = useSelector(getSelectedAssetsID);
    const childSelection = selectedVariant[parentCatName];
    const isProductSelected = childSelection && Object.values(childSelection).indexOf(data.id) !== -1;

    // Get name translation from product meta_data if exist, default to product name otherwise
    const productName = data.meta_data.filter(m => m.key === TranslationKeys[i18next.language]).length === 0 ?
        data.name :
        data.meta_data.filter(m => m.key === TranslationKeys[i18next.language])[0].value;

    const categoryName = data.categories[0][TranslationKeys[i18next.language]] ?
        data.categories[0][TranslationKeys[i18next.language]] :
        data.categories[0].name;

    let variantId, colorName, colorHex, variantImage;
    if (data.product_variations.length > 0) {
        variantId = data.product_variations[0].id;
        colorName = data.product_variations[0][TranslationKeys[i18next.language]] ?
            data.product_variations[0][TranslationKeys[i18next.language]] :
            data.product_variations[0].name;
        colorHex = data.product_variations[0].value;
        variantImage = data.images[0]?data.images[0].src:'';
    }

    return (
        <div className="col-sm-4">
            <div>
                <div className={`padding ${isProductSelected ? 'active' : ''}`}>
                    <div className='hoverMainParent'>
                        <span
                            className="imagesborder"
                            onClick={() => {
                                if (!isProductSelected) {
                                    dispatch(getVariantDetails({
                                        'product_variations': data.product_variations,
                                        'mainCatName': data.categories[0].name,
                                        'name': data.name,
                                        'assetId': data.id,
                                        'variantId': variantId,
                                        'assetColor': colorName,
                                        'assetColorStyle': colorHex,
                                        'assetImage': variantImage, 
                                        'parentCat': parentCatName,
                                        'childLen': childLen,
                                        'categoryName': categoryName,
                                        'headerIndex': headerIndexKey,
                                        'Eng': {'product_name':data.name,'color':data.product_variations[0].name,'category':data.categories[0].name}
                                    }));
                                    GoogleDataLayer({
                                        event: getTagManagerEvents.selectProduct,
                                        productName: data.name,
                                        gaProductCode: `${data.categories[0].name}-${data.name}`,
                                        productCategory: data.categories[0].name
                                    });
                                    dispatch(
                                        removeSelectedSubCat({
                                          'parentCat'     : parentCatName,
                                          'subCategory'   : categoryName,
                                          'childLen'      : childLen ,
                                        })
                                      );
                                      dispatch(
                                        setOrderSelectedSubCat({
                                          'parentCat'     : parentCatName,
                                          'categoryName'  : categoryName,
                                          'assetId'       : data.id,
                                        })
                                      );
                                      dispatch(setHandleHeaderMenuClick(parentCatName));
                                }
                            }}
                        >
                            <img src={data.images[0]?window.config.REACT_APP_BACKEND_URL+data.images[0].src:''} alt='' />                                        
                        </span>
                        {
                            isProductSelected ? (
                                <div className="hover-overlay">
                                    <FontAwesomeIcon icon={faTimes} className='chekedIcon' onClick={() => dispatch(removeSingleProduct({'subCategoryName': categoryName, 'categoryName': parentCatName}))}/>
                                </div> 
                            ) : null
                        }
                    </div>
                    <span className="tittle">{productName}</span>
                </div>
            </div>
        </div>
    );
}

export default DesignBathShowerChildInner