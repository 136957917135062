import React,{useEffect} from 'react'
import i18next from 'i18next';
import { TranslationKeys } from '../../i18n';
import { Link,useLocation } from 'react-router-dom';
import { useDispatch ,useSelector} from 'react-redux';
import { getModifySummaryMenuVal, getParentKey, setMobileParentKey, setMobileParentKeyIndex} from '../designbathshower/designBathShowerSlice';

const MobileHeaderMenuData = ({assetData,index}) => {
const dispatch = useDispatch();    
const location = useLocation();

const categoryName = assetData["translations"] && assetData["translations"][TranslationKeys[i18next.language]] ?
   assetData["translations"][TranslationKeys[i18next.language]] :
   assetData.name;

    const moveHeaderToCategory = (categoryName) => {
        let headerElement = document.getElementById('mobileCategoriesSlider');

        let horizontalScroll = 0;
        for (const li of headerElement.childNodes) {
            if (li.querySelector('a').lastChild.data === categoryName) {
                break;
            }
            horizontalScroll += li.clientWidth;
        }

        headerElement.parentElement.scrollLeft = horizontalScroll;
    }

    const selectedCategoryFromSummary = useSelector(getModifySummaryMenuVal);
    useEffect(() => {
        if (index === 0 || (selectedCategoryFromSummary && categoryName === selectedCategoryFromSummary)) {
            dispatch(setMobileParentKey(categoryName));
            dispatch(setMobileParentKeyIndex(index));

            if (selectedCategoryFromSummary) {
                // Move mobile header scroll to edited category
                moveHeaderToCategory(selectedCategoryFromSummary);
            }
        }
    }, [index, categoryName, selectedCategoryFromSummary, dispatch]);

    let menuSelection=useSelector(getParentKey);
    const onSelectHeaderCategory = () => {
        dispatch(setMobileParentKey(categoryName));
        dispatch(setMobileParentKeyIndex(index));
        moveHeaderToCategory(categoryName);
    }
 
    return (
        <li className={`${(menuSelection===categoryName)?'active':''}`}>
            <Link to={`${location.pathname}${location.search}`} onClick={onSelectHeaderCategory}>
                <span className="headerNumer">{index +1}</span> 
                {categoryName}
            </Link>
        </li>
    )
}

export default MobileHeaderMenuData