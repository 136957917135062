import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import undoImage from '../../images/undo.svg';
import shareImage from '../../images/share.svg';
import saveImage from '../../images/save.svg';
import homeArrowImage from '../../images/home-arrow.svg';
import bookArrowImage from '../../images/book-arrow-new.svg';
import restartArrowImage from '../../images/start-arrow.svg';
import undoArrowImage from '../../images/undo-arrow.svg';
import restartImage from '../../images/info.svg';
import nextArrowImage from '../../images/icon3.png';
import bathFitterLogo from  '../../images/logowhite.png'
import bainMagiqueLogo from  '../../images/BainMagique_Logo_blanc.png'
import backArrowImage from '../../images/backarrow.png'
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux'
import { getIsMobileTutorialActive, mobileTutorialClosePopUp } from '../summary/summarySlice'

const HelpScreen = () => {
    const dispatch = useDispatch();
    const isTutorialPopUpActive = useSelector(getIsMobileTutorialActive);
    const { t } = useTranslation();
    const location = useLocation();
    const logo = window.location.hostname.includes('bainmagique') || 
        location.search.includes("brand=BM") ? bainMagiqueLogo : bathFitterLogo;

    return (
        isTutorialPopUpActive ? (
            <div className="help-screen select-icons">
                <div className="headerfixed">
                    <header className="headerbg">
                        <div className="container" >
                            <div className="col-sm-1 backicon">
                                <img src={backArrowImage} alt=''/>
                                <em >{t("button.back")}</em>
                                <div className={`help-info-header back ${i18next.language}`}>
                                    <img src={homeArrowImage} alt='' />
                                    <p>{t("help.return")}</p>
                                </div>
                            </div>
                            <div className="logo">
                                <img src={logo} alt='' />
                            </div>
                            <button
                                className={`consult ${i18next.language === 'fr' ? 'frenchBtn' :
                                i18next.language === 'es' ? 'spanishBtn' : ''}`}
                            >
                                {t("button.bookNow")}
                            </button>
                            <div className={`help-info-header book ${i18next.language}`}>
                                <img src={bookArrowImage} alt='' />
                                <p>{t("help.bookNow")}</p>
                            </div>
                        </div>
                    </header>
                </div>

                <div className="get-started">
                    <Link
                        to={`${location.pathname}${location.search}`}
                        onClick={() => dispatch(mobileTutorialClosePopUp())}
                    >
                        <button className="started">{t('help.getStarted')}</button>
                    </Link>
                </div>

                <div className='bottomfixed'>
                    <ul className='middle'>
                        <li>
                            <div className={`help-info-bottom start-new ${i18next.language}`}>
                                <p>{t("help.startOver")}</p>
                                <img src={restartArrowImage} alt='' />
                            </div>
                            <img src={restartImage} alt=''/>
                        </li>
                        <li>
                            <div className={`help-info-bottom save ${i18next.language}`}>
                                <p>{t("help.save")}</p>
                            </div>
                            <img src={saveImage} alt=''/>
                        </li>
                        <li>
                            <div className={`help-info-bottom share ${i18next.language}`}>
                                <p>{t("help.share")}</p>
                            </div>
                            <img src={shareImage} alt=''/>
                        </li>
                        <li>
                            <div className={`help-info-bottom undo ${i18next.language}`}>
                                <p>{t("help.undo")}</p>
                                <img src={undoArrowImage} alt='' />
                            </div>
                            <img src={undoImage} alt=''/>
                        </li>
                    </ul>
                    <div className='fixedbck'>
                        <div className={`help-info-bottom ${i18next.language}`}>
                            <p>{t("help.nextStep")}</p>
                        </div>
                        <img src={nextArrowImage} alt=''/>
                    </div>
                </div>
            </div>
        ) : null
    )
}

export default HelpScreen