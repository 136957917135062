import React,{useEffect} from 'react'
import BathShowerHeader from './BathShowerHeader'
import { useParams } from 'react-router-dom'
import { useSelector,useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";
import PlumbingDataMobile from './PlumbingDataMobile';
import { getPlumbingPositions,selectAllPlumbData,getPlumbStatus,getPlumbErrors } from '../categorydata/categoryDataSlice'
import { getBookConsultationStatus } from '../designmobilelayout/mobileMenuSlice';
import Loader from '../loader/Loader';

const PlumbingMobile = () => {
const dispatch      = useDispatch();   
const plumbsData    = useSelector(selectAllPlumbData);
const plumbStatus   = useSelector(getPlumbStatus);
const plumbError    = useSelector(getPlumbErrors);
const bookConsultationStatus = useSelector(getBookConsultationStatus);

const params = useParams();
useEffect(() => {
    dispatch(getPlumbingPositions(params.id))
}, [dispatch, params.id]);
const { t } = useTranslation();
let content;
if(plumbStatus ==='loading')
{
    content=<p>{t("loading")}</p>
}
if(plumbStatus ==='idle')
{
        content = plumbsData.map(plumbsData=> <PlumbingDataMobile key={plumbsData.id} plumbsData={plumbsData}  />)        
}
 if(plumbStatus === 'failed')
{
        content =<p>{plumbError}</p>
}

  return (
    <>
        <BathShowerHeader/>
        <div className="select-icons select-plumbing">
            <div className="selector-title">
            <h4>{t("plumbing.configuration")}</h4>
            <p>{t("plumbing.explanation")}</p>
            </div> 
            <div className="select-item">
                {content}                
            </div>
        </div>
        {
            bookConsultationStatus === 'loading' ? <Loader /> : null
        }
    </>
    
  )
}

export default PlumbingMobile