import { useDispatch, useSelector } from "react-redux";
import { calculateSnapping, getX, getY, setIsDragging, translate } from "./layoutSlice";
import { useGesture } from "@use-gesture/react";

export default function DragMove ({mainDivRef, dragAreaRef}) {
  const dispatch = useDispatch();
  const x = useSelector(getX);
  const y = useSelector(getY);

  return useGesture({
    onDrag: ({offset: [dx, dy]}) => dispatch(translate({x: dx, y: dy})),
    onDragStart: () => dispatch(setIsDragging(true)),
    onDragEnd: () => {
      dispatch(calculateSnapping({mainDivRef, dragAreaRef}));
      dispatch(setIsDragging(false));
    }
  },
  { // useGesture options
    drag: {
      from: () => [x, y],
      delay: 1000
    },
    eventOptions: {passive: false}
  });
}