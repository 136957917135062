import React,{useEffect} from 'react'
import Header from '../header/Header'
import { Link,useLocation, useParams } from 'react-router-dom'
import { useSelector,useDispatch } from 'react-redux';
import i18next from 'i18next';
import { useTranslation } from "react-i18next";
import { getPlumbingPositions,selectAllPlumbData,getPlumbStatus,getPlumbErrors } from '../categorydata/categoryDataSlice';
import PlumbingData from './PlumbingData';
import { getBookConsultationStatus } from '../designmobilelayout/mobileMenuSlice';
import Loader from '../loader/Loader';

const Plumbing = () => {
const dispatch      = useDispatch();   
const plumbsData    = useSelector(selectAllPlumbData);
const plumbStatus   = useSelector(getPlumbStatus);
const plumbError    = useSelector(getPlumbErrors);
const bookConsultationStatus = useSelector(getBookConsultationStatus);

    const location = useLocation();
    const params = useParams();
    useEffect(() => {
      dispatch(getPlumbingPositions(params.id))
    }, [dispatch, params.id]);

const { t } = useTranslation();

let content;
if(plumbStatus ==='loading')
{
    content=<p>{t("loading")}</p>
}
if(plumbStatus ==='idle')
{
        content = plumbsData.map(plumbsData=> <PlumbingData key={plumbsData.id} plumbsData={plumbsData}  />)        
}
 if(plumbStatus === 'failed')
{
        content =<p>{plumbError}</p>
} 

  return (
    <>
        <Header fixedHeaderClass='headerbgFixed'/>
        <div className="pre-config-main2 plumbing-width-lg">
            <p className="back-to">
              <Link to={`/${i18next.language}/project-type${location.search}`}><span><i className="fas fa-angle-left"></i></span>{t("previousPage")}</Link>
            </p>
            <h2>{t("plumbing.configuration")}</h2>
            <p className="design-exp">{t("plumbing.explanation")}</p>
            <div className="left-right-img">
                {content} 
            </div>		 
	      </div>
        {
          bookConsultationStatus === 'loading' ? <Loader /> : null
        }
    </>
  )
}

export default Plumbing