import React from 'react';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';

var reactPlugin = new ReactPlugin();
var appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: window.config.REACT_APP_APPINSIGHTS_KEY,
        enableAutoRouteTracking: true,
        extensions: [reactPlugin],
    }
});
appInsights.loadAppInsights();

// Wrapper component to wrap all the components that need to be tracked via Azure Applicaiton Insights
class TelemetryProvider extends React.Component {
    render() {
        return (
            <React.Fragment>
                {this.props.children}
            </React.Fragment>
        );
    }
}

// Push track trace to Application Insights with payload and response information
function sendTrackTrace(message, payload = {}, response = {}) {
    appInsights.trackTrace({
        message: message,
        properties: {
        payload: payload,
        response: response
        }
    });
}

// Push track exception to Application Insights with error message and payload information
function sendTrackException(err, payload = {}) {
    appInsights.trackException({
        error: new Error(err.message),
        properties: {
        message: err.message,
        payload: payload
        }
    });
}

export default withAITracking(reactPlugin, TelemetryProvider);

export { sendTrackTrace, sendTrackException };