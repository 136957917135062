import React from 'react'
import LoaderImg from '../../images/loader.gif'

const LoaderDesign = () => {
  return (
    <div className='loaderImg'>
        <img src={LoaderImg} alt=''/>
    </div>
  )
}

export default LoaderDesign