import React from 'react'
import { useSelector,useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getIsDesktopTutorialActive, getCurrentTutorialStep, tutorialClosePopUp, tutorialNextStep, tutorialGoToStep } from '../summary/summarySlice';

import modalCrossImage from '../../images/crox-white.png'
import tutorialStep1Image from '../../images/tutorialStep1.svg'
import tutorialStep2Image from '../../images/tutorialStep2.svg'
import tutorialStep3Image from '../../images/tutorialStep3.svg'
import tutorialStep1FR from '../../images/tutorialStep1_FR.svg'
import tutorialStep2FR from '../../images/tutorialStep2_FR.svg'
import tutorialStep3FR from '../../images/tutorialStep3_FR.svg'
import tutorialStep1ES from '../../images/tutorialStep1_ES.svg'
import tutorialStep2ES from '../../images/tutorialStep2_ES.svg'
import tutorialStep3ES from '../../images/tutorialStep3_ES.svg'

import arrowImage from '../../images/arrow.svg'
import bulletImage from '../../images/bullet.png'

const TutorialPopUp = () => {
    const dispatch = useDispatch(); 
    const isTutorialPopUpActive = useSelector(getIsDesktopTutorialActive);
    const currentTutorialStep = useSelector(getCurrentTutorialStep);

    const { t, i18n } = useTranslation();

    const location = useLocation();

    return (
        isTutorialPopUpActive === true ? (
           <div className="modal fade in tutorial" id="exampleModal3"  role="dialog" data-backdrop="static" data-keyboard="false" style={{"display":"block"}}>
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <span className="close" data-dismiss="modal" onClick={() => dispatch(tutorialClosePopUp())}><img src={modalCrossImage} alt=""/></span>

                            <img src={
                              currentTutorialStep === 1 ?
                                (i18n.language === 'fr' ? tutorialStep1FR : (i18n.language === 'es' ? tutorialStep1ES : tutorialStep1Image))
                              : currentTutorialStep === 2 ?
                                (i18n.language === 'fr' ? tutorialStep2FR : (i18n.language === 'es' ? tutorialStep2ES : tutorialStep2Image))
                              : (i18n.language === 'fr' ? tutorialStep3FR : (i18n.language === 'es' ? tutorialStep3ES : tutorialStep3Image))
                            } alt={`Step ${currentTutorialStep} of the tutorial`}/>
                        </div>
                        <div className='modal-footer'>
                            <div className="goto-step">
                                <Link to={`${location.pathname}${location.search}`} className={currentTutorialStep === 1 ? "active" : ""} onClick={() => dispatch(tutorialGoToStep(1))}>
                                    <img src={bulletImage} alt="•"/>
                                </Link>
                                <Link to={`${location.pathname}${location.search}`} className={currentTutorialStep === 2 ? "active" : ""} onClick={() => dispatch(tutorialGoToStep(2))}>
                                    <img src={bulletImage} alt="•"/>
                                </Link>
                                <Link to={`${location.pathname}${location.search}`} className={currentTutorialStep === 3 ? "active" : ""} onClick={() => dispatch(tutorialGoToStep(3))}>
                                    <img src={bulletImage} alt="•"/>
                                </Link>
                            </div>

                            <Link to={`${location.pathname}${location.search}`} className="skip-tutorial" onClick={() => dispatch(tutorialClosePopUp())}>  
                                {t("help.skip")}
                            </Link>

                            <Link to={`${location.pathname}${location.search}`} className="next-step" onClick={() => dispatch(tutorialNextStep())}>
                                <img src={arrowImage} alt=""/>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        ) : null
    )
}

export default TutorialPopUp;