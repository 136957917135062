import React, { useRef } from 'react'
import { useDispatch ,useSelector} from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getVariantAllData,getCategoriesScrollColorSelection,setCategoriesScrollColorSelection } from '../designbathshower/designBathShowerSlice';
import MobileDesignChildInnerData from './MobileDesignChildInnerData';
import MobileColorChildData from './MobileColorChildData';

const MobileDesignChildData = ({dataChild,childLen,index,parentKey,headerIndex}) => {
const dispatch =useDispatch();
const colorData = useSelector(getVariantAllData);
const colorsContainerRef = useRef(null);
const categoriesScrollColorSelection = useSelector(getCategoriesScrollColorSelection);
const { t } = useTranslation();

let colorVariArr,colorOtherData; 

if(typeof colorData === 'object' && colorData !== null)
{  
  if (colorData[index]) {
    colorVariArr = colorData[index].mainData;
    colorOtherData = colorData[index].otherData;
  } else if (colorData[parentKey]) {
    colorVariArr = colorData[parentKey].mainData;
    colorOtherData = colorData[parentKey].otherData;
  }
}

let childContent=Object.values(dataChild).map(childDataVal=><MobileDesignChildInnerData key={childDataVal.id} childInnerData={childDataVal} childLen={childLen} index={index} parentId={parentKey} headerIndex={headerIndex}/>)   
let allColorResChild;

if(typeof colorVariArr === 'object') 
{    
     if(colorVariArr.length>0)
     {        
        allColorResChild=colorVariArr.map(color_res=><MobileColorChildData key={color_res.id} colorData={color_res} parentKey={parentKey} assestId={color_res.id} allData={dataChild} otherData={colorOtherData} childLen={childLen} headerIndex={headerIndex} catEngName={index}/>).reverse()     
     }
}

if (categoriesScrollColorSelection[parentKey] !== undefined && !categoriesScrollColorSelection[parentKey] && colorsContainerRef.current) {
  setTimeout(() => {
    colorsContainerRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
    });
    dispatch(setCategoriesScrollColorSelection(parentKey));
  }, 1000);
}

return (
     <div className="mobileContentData">
       <div className="col-sm-12 headingleft">
         <p className="mainhead">{index} </p>
       </div>
       <div className="imagesfull">{childContent}</div>
       {
         allColorResChild && childLen > 2 && (
         <div className="col-sm-12 colors" ref={colorsContainerRef}>
           <span className="colorstext"> {t('selectColor')} </span>
           <div className="colorsdiv"> {allColorResChild} </div>
         </div>)
       }
       {
         allColorResChild && childLen === 2 && colorOtherData && colorOtherData.selectedCat === index && (
           <div className="col-sm-12 colors" ref={colorsContainerRef}>
             <span className="colorstext"> {t('selectColor')} </span>
             <div className="colorsdiv"> {allColorResChild} </div>
           </div>)
       }
     </div>
  )
}

export default MobileDesignChildData