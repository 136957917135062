export default function EmailInputField({name, customClass, placeholder, register, requiredErrorMessage, patternErrorMessage, errors}) {
    return (
        <div className={customClass}>
            <input type="text"
                className="form-control"
                name={name}
                placeholder={placeholder}
                {...register(name, {
                    required: requiredErrorMessage,
                    pattern: {
                        value: /^[\w-.]+([+]{1}[\w]+){0,1}@([\w-]+\.)+[\w-]{2,6}$/,
                        message: patternErrorMessage
                    }
                })}
            />
            <small className="form-text text-muted">
                {
                    errors[name] && <p>{errors[name].message}</p>
                }
            </small>
        </div>
    )
}
