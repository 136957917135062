import React from 'react';
import { useDispatch } from 'react-redux';
import i18next from 'i18next';
import { getAssestVariantImage, setPreviousSelectedColor } from './designBathShowerSlice';

const DesingBathShowerColors = ({colorVarinatsData,variantId,assetId,allData,parentCatName,childLen,headerIndexKey,catNameEng}) => {
  const dispatch = useDispatch();
  
  let colorName, colorHex;
  if (colorVarinatsData.attributes[0][`option_${i18next.language}`]) {
    colorName = colorVarinatsData.attributes[0][`option_${i18next.language}`];
    colorHex = colorVarinatsData.attributes[0].value;
  } else {
    colorName = colorVarinatsData.attributes[0].option;
    colorHex = colorVarinatsData.attributes[0].value;
  }

  let selectedProduct = Object.values(allData).find(data => data.id === assetId);

  const handleAssetVariantImage = () => {
    dispatch(
      getAssestVariantImage({
        'assetId'           : assetId,
        'variantId'         : colorVarinatsData.id,
        'assestColor'       : colorName,
        'assestColorStyle'  : colorHex,
        'assestAllData'     : allData,
        'parentCat'         : parentCatName,
        'childLen'          : childLen,
        'headerIndex'       : headerIndexKey,
        'mainCatName'       : catNameEng,
        'Eng'              :{'product_name':selectedProduct.name,'color':colorVarinatsData['attributes'][0]['option'],'category':catNameEng} 
      })
    )
  }

  const handleColorSelection = () => {
    handleAssetVariantImage();
    dispatch(
      setPreviousSelectedColor({
        'categoryName': catNameEng,
        'colorName': colorName
      })
    );
  }

  return (
              <>
                <i  className={`white ${colorVarinatsData.attributes[0].option} ${variantId === colorVarinatsData.id ? "active" : ""}`} id='' style={{"background":colorHex}}  onClick={handleColorSelection}></i>
              </>
         )
}

export default DesingBathShowerColors