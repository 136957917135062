import React, { useRef } from 'react'
import { useSelector,useDispatch } from 'react-redux';
import { allDesignBathData, getVariantAllData, getParentKey,getParentKeyIndex,getCategoriesScrollColorSelection,setCategoriesScrollColorSelection} from '../designbathshower/designBathShowerSlice';
import MobileDesignData from './MobileDesignData';
import MobileDesignChildData from './MobileDesignChildData';
import MobileColorData from './MobileColorData';
import { useTranslation } from 'react-i18next';

const MobileDesignContent = () => {
const dispatch            = useDispatch(); 
const mobileData          = useSelector(allDesignBathData); 
const parentKey           = useSelector(getParentKey); 
const parentKeyIndex      = useSelector(getParentKeyIndex)   
const colorData           = useSelector(getVariantAllData);
const colorsContainerRef  = useRef(null);
const categoriesScrollColorSelection = useSelector(getCategoriesScrollColorSelection);
 
  const { t } = useTranslation();

let content,contentChild,colorVariArr=[],colorOtherData=[],childLen; 
if(typeof mobileData === 'object' && mobileData !== null)
{ 
  if(parentKey!==null)
  {
     
     if(mobileData[parentKey]!==undefined)
     {
        if(mobileData[parentKey].length>0)
        {
           childLen=0;
          
          content= Object.values(mobileData[parentKey]).map(data=><MobileDesignData key={data.id} datamobile={data} mainCat={parentKey} childLen={childLen} headerIndex={parentKeyIndex}/>)
        }
        else{
           
           childLen=Object.keys(mobileData[parentKey]).length;
          
          contentChild= Object.entries(mobileData[parentKey]).map(([key,dataChild])=> <MobileDesignChildData key={key} dataChild={dataChild}  childLen={childLen} index={key} parentKey={parentKey} allData={Object.values(mobileData)} headerIndex={parentKeyIndex}/>
            )
           
        }
     }
      
  }
 
}

if(typeof colorData === 'object' && colorData !== null)
{
  if (colorData[parentKey]) {
    colorVariArr = colorData[parentKey].mainData;
    colorOtherData = colorData[parentKey].otherData;
  }    
}
let allColorRes

if(colorVariArr.length>0 )
{
  if(mobileData[parentKey]!==undefined)
  {
   allColorRes=colorVariArr.map(color_res=><MobileColorData key={color_res.id} colorData={color_res} parentKey={parentKey} assestId={color_res.id} allData={Object.values(mobileData[parentKey])} otherData={colorOtherData} headerIndex={parentKeyIndex}/>).reverse()
  }
  else{
   allColorRes=colorVariArr.map(color_res=><MobileColorData key={color_res.id} colorData={color_res} parentKey={parentKey} assestId={color_res.id} allData={Object.values(mobileData)} otherData={colorOtherData} headerIndex={parentKeyIndex}/>).reverse()
  }
}

if (categoriesScrollColorSelection[parentKey] !== undefined && !categoriesScrollColorSelection[parentKey] && colorsContainerRef.current) {
    setTimeout(() => {
      colorsContainerRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
      dispatch(setCategoriesScrollColorSelection(parentKey));
    }, 1000);
}

  return (
    <>
      <div className='mobileContentData'>
        <div className= {`imagesfull ${parentKey}-mobile`}> 
            {content}                  
        </div>
        {
          allColorRes && childLen === 0 ? (
            <div className="col-sm-12 colors" ref={colorsContainerRef}>
              <span className="colorstext">{t("selectColor")}</span>
              <div className="colorsdiv"> {allColorRes} </div>
            </div>
          ) : null
        }
      </div>
      {contentChild}
    </>
  )
}

export default MobileDesignContent