import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import i18next from 'i18next';
import { TranslationKeys } from '../../i18n';
import { GoogleDataLayer, getTagManagerEvents } from '../layout/GoogleDataLayer';

const PlumbingData = ({plumbsData}) => {

  const location = useLocation();

  // Get plumbing category translation from object if exist, default to plumbing category name otherwise
  const categoryName = plumbsData["translations"] && plumbsData["translations"][TranslationKeys[i18next.language]] ?
    plumbsData["translations"][TranslationKeys[i18next.language]] :
    plumbsData.name;

  return (
    <>
      <Link
        to={`/${i18next.language}/design-your-own-bathroom/${plumbsData.id}${location.search}`}
        onClick={() => GoogleDataLayer({
          event: getTagManagerEvents.selectPlumbingSide,
          plumbingSide: plumbsData.name,
          projectType: 'Bathtub'
        })}
      >
        <div className={plumbsData.name.toLowerCase().includes('left') ? 'left-image' : 'right-image'}>
          <div className="thumbnail-img">
            <img src={plumbsData.image?window.config.REACT_APP_BACKEND_URL+plumbsData.image.src:''} alt=""/>
          </div>
          <div className="hover-icon"><span><i className="fas fa-arrow-right"></i></span></div>
          <p>{categoryName}</p>
        </div>
      </Link>
    </>
  )
}

export default PlumbingData