import React from 'react'
import { useSelector } from 'react-redux'
import { Accordion,AccordionItem,AccordionItemHeading,AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion'
import DesignBathShowerChildInner from './DesignBathShowerChildInner'
import { getVariantAllData } from './designBathShowerSlice'
import DesignChildColorV from './DesignChildColorV'
const DesignBathShowerChildList = ({childData,childTitle,childLen,parentCatName,headerIndexKey}) => {

let childInnerData,colorVarinatData,childColorDiv,accordianName;

childInnerData=childData.map(data => <DesignBathShowerChildInner key={data.id} data={data} parentCatName={parentCatName} childLen={childLen} headerIndexKey={headerIndexKey}/>);

colorVarinatData        = useSelector(getVariantAllData);
if(Object.keys(colorVarinatData).length >0)
{
 
  childColorDiv=Object.entries(colorVarinatData).map(([key,childColorData]) => <DesignChildColorV key={key} childColorData={childColorData} childTitle={childTitle} allData={childData} parentCatName={parentCatName} childLen={childLen} headerIndexKey={headerIndexKey}/>)
}
accordianName=childTitle.toLowerCase().replace(/\s/g, '');
  return (
    <>
        <div className="middimages" id="accessDiv">
          <Accordion allowZeroExpanded   preExpanded={['h']}>
              <AccordionItem uuid={accordianName}>
                  <AccordionItemHeading>
                      <AccordionItemButton>
                        {childTitle}
                      </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                       {childInnerData}
                       {childColorDiv}
                  </AccordionItemPanel>
              </AccordionItem>
          </Accordion>  
                                
         </div>
    </>
  )
}

export default DesignBathShowerChildList