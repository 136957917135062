import React from 'react'
import { Link,useLocation } from 'react-router-dom'
import { useTranslation } from "react-i18next";
import { useDispatch } from 'react-redux'
import ArrowSummaryImg from '../../images/arrowsummary.svg'
import { getIsShowMissingProductsPopUp } from './designBathShowerSlice'

const SummaryPageLink = () => {
    const dispatch = useDispatch();    
    const location = useLocation();
    const { t } = useTranslation();

    return (
        <Link to={`${location.pathname}${location.search}`}>
            <div className="satisfaction-check" onClick={() => dispatch(getIsShowMissingProductsPopUp())}>
                <div className="design-satisfy">
                    <h4>{t("button.satisfiedQuestion")}</h4>
                    <p>{t("button.instructions")}</p>
                </div>
                <div className="summary-arrow">
                    <img src={ArrowSummaryImg} alt=''/>
                </div>
            </div>
        </Link>
    )
}

export default SummaryPageLink