import React from 'react'
import BathShowerHeader from './BathShowerHeader'
import { useSelector,useDispatch } from 'react-redux'
import { useTranslation } from "react-i18next";
import {useForm} from 'react-hook-form';
import { getLoadExistingStatus,getLoadExistingData,fetchUserSavedDesigns, getIsUserDesignsFetched } from '../designmobilelayout/mobileMenuSlice';
import Loader from '../loader/Loader';
import { Link,useLocation } from 'react-router-dom';
import { openLoadExistingDesignPopUp } from '../designbathshower/designBathShowerSlice';
import DesignLoadExisitngPop from '../designbathshowerpopup/DesignLoadExisitngPop';
import { getBookConsultationStatus } from '../designmobilelayout/mobileMenuSlice';
import { GoogleDataLayer, getTagManagerEvents } from '../layout/GoogleDataLayer';
import EmailInputField from '../formfields/EmailInputField';

const MobileLoadExistingData = () => {
const { t }              = useTranslation();
const dispatch           = useDispatch();
const getUserDesignsStatus = useSelector(getLoadExistingStatus)
const savedDesigns = useSelector(getLoadExistingData)
const isUserDesignsFetched = useSelector(getIsUserDesignsFetched);
const bookConsultationStatus = useSelector(getBookConsultationStatus);

const onSubmit = (data, e) => {
    dispatch(fetchUserSavedDesigns(data.email));
    reset();
    GoogleDataLayer({event: getTagManagerEvents.loadStart});
};
const { register,reset, handleSubmit, formState: { errors } } = useForm();
const location  = useLocation();
    
  return (
    <>
       <BathShowerHeader/>
       <div className="select-icons select-plumbing load-project">
            <div className="selector-title">
            <h4>{t("loadExisting.title")}</h4>
            <p>{t("loadExisting.instructions")}</p>
            </div>
            <div className="book-free-cons">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <EmailInputField name="email"
                        placeholder={t("label.email")}
                        register={register}
                        requiredErrorMessage={t("error.emailRequired")}
                        patternErrorMessage={t("error.emailFormat")}
                        errors={errors}
                    />
                    <button type="submit" className='consult' >{t("loadExisting.load")}</button>
                </form>
            </div>
            <div className={`${isUserDesignsFetched ? "saved-project" :"saved-project noData" }`}>
                {
                    isUserDesignsFetched ? (
                        <>
                            <div className="selector-title">
                                <h4>{t("loadExisting.subtitle")}</h4>
                            </div>

                            <div className="saved-project-list">
                                {
                                    Object.keys(savedDesigns).length > 0 ? (
                                        Object.entries(savedDesigns).map(([key, data]) => (
                                            <Link key={key} to={`${location.pathname}${location.search}`} onClick={ () =>  dispatch(openLoadExistingDesignPopUp(data))}>
                                                <div className="saved-list-box" >                                                
                                                    <img src={data.big_image} alt=""/>                                          
                                                    <p>{ data['creation_date'].substr(0,10) }</p>
                                                </div>
                                            </Link> 
                                        ))
                                    ) : (
                                        <p className='form-text text-muted'>{t("loadExisting.noDesignsFound")}</p>
                                    )
                                }
                            </div>
                        </>
                    ) : null
                }
            </div>
        </div>
        {
            getUserDesignsStatus === 'loading' ? <Loader /> : null
        }
        <DesignLoadExisitngPop/>
        {
            bookConsultationStatus === 'loading' ? <Loader /> : null
        }
    </>
  )
}

export default MobileLoadExistingData