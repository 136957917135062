import React from 'react'
import { useSelector,useDispatch } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import i18next from 'i18next';
import Crox from '../../images/crox.png'
import StartOverImg from '../../images/startover.png'
import { getIsResetDesign, getIsStartOverPopUpActive, closeStartOverPopUp, openSavePopUp } from '../designbathshower/designBathShowerSlice';

const DesignStartOverPopUp = () => {
    const paramsData = useParams();
    const { t } = useTranslation();
    const dispatch  =useDispatch(); 
    const isStartOverPopUpActive = useSelector(getIsStartOverPopUpActive); 
    const isResetDesign = useSelector(getIsResetDesign);
    const startOver = {display: "block"};
    const location = useLocation();

    const goToHomePage = () => {
        dispatch(closeStartOverPopUp());
        window.location.href = `/${i18next.language}${location.search}`;
    }

    const reloadPage = () => {
        window.location.href = location.pathname.replace(`/${paramsData.designId}`,'') + location.search;
    }

    const saveDesign = () => {
        dispatch(closeStartOverPopUp());
        dispatch(openSavePopUp());
    }

    return (
        isStartOverPopUpActive ? (
            <div className="modal fade in" id="exampleModal4"  role="dialog" data-backdrop="static" data-keyboard="false" style={startOver}>
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                {isResetDesign ? t("reset") : t("startOver.title")}
                                <span className="close" data-dismiss="modal" onClick={() => dispatch(closeStartOverPopUp())}><img src={Crox} alt='' /></span>
                            </h5>
                        </div>
                        <div className="modal-body">
                            <div className="startmid">
                                <div className="startleftimages">
                                    <img src={StartOverImg} alt=''/>
                                </div>
                                <div className="starttextright">
                                    <Trans t={t} i18nKey="startOver.instructions">
                                        <b>You're about to lose your design.</b><br/>
                                        Click "Save" if you want to keep your design. If not, click "New"
                                    </Trans>
                                </div>
                                <div className="savebuttonboxs">
                                    <button className="savebtn" onClick={isResetDesign ? reloadPage : goToHomePage}>{t("button.new")}</button>
                                    <button className="savebtn save" onClick={saveDesign}>{t("button.save")}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ) : null
    )
}

export default DesignStartOverPopUp