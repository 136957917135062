import React from 'react'
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import BathShowerHeader from './BathShowerHeader';
import nextArrowImage from '../../images/icon3.png';
import { Link, useLocation } from 'react-router-dom';
import MobileHomeSlider from './MobileHomeSlider';
import i18next from 'i18next';
import { GoogleDataLayer, getTagManagerEvents } from '../layout/GoogleDataLayer';
import { getBookConsultationStatus } from '../designmobilelayout/mobileMenuSlice';
import Loader from '../loader/Loader';

const HomePageMobile = () => {
  const bookConsultationStatus = useSelector(getBookConsultationStatus); 
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <>
       <BathShowerHeader/>
       <div className="select-icons select-plumbing intro-page">
          <div className="wrapper">
               <MobileHomeSlider/>
            </div>       
           
            <div className="intro-caption">
                <h4>
                  {
                    t("homePageMobile.title", {
                      brand: window.location.hostname.includes('bainmagique') || location.search.includes("brand=BM") ? 
                        'Bain Magique' : 'Bath Fitter'
                    })
                  }
                </h4>
                <p>{t("homePageMobile.description")}</p>                                    
            </div>
            <div className="follow-steps-mobile">
                <p>{t("homePageMobile.instructions")}</p>
                <Link to={`/${i18next.language}/project-type${location.search}`} onClick={() => GoogleDataLayer({event: getTagManagerEvents.home})}>
                <div className='nextStepIcon'>
                  <img src={nextArrowImage} alt=''/>
                </div>
                </Link>
            </div>
       </div>
      {
        bookConsultationStatus === 'loading' ? <Loader /> : null
      }
    </>
  )
}

export default HomePageMobile