import React from 'react'
import MobileHeaderMenuData from './MobileHeaderMenuData'
import { useTranslation } from "react-i18next";
import { useSelector,useDispatch } from 'react-redux';
import LoaderImg from '../../images/loader.gif'
import { selectAllAssests,getAssetsErrors,getAssetsStatus,getIsShowMissingProductsPopUp,getVariantDetailStatus,getVariantAllData } from '../designbathshower/designBathShowerSlice';
import { Link,useLocation } from 'react-router-dom';
import { getMissingMinimumSelection } from '../designbathshowerpopup/MinimumSelection'

const MobileHeaderMenu = () => {
const { t }            = useTranslation();  
const dispatch         = useDispatch();
const assets_status    = useSelector(getAssetsStatus);
const assets_err       = useSelector(getAssetsErrors);
const assetData        = useSelector(selectAllAssests); 
let content;
if(assets_status ==='loading')
{
    content=<img src={LoaderImg} alt='' id="headerLoader"/>
}
else if(assets_status ==='idle')
{ 
        if(assetData!==undefined && assetData !=='Network Error')
        {
            content = assetData.map((assetData,index)=> <MobileHeaderMenuData key={assetData.id} index={index} assetData={assetData} />)    
        }            
}
else if(assets_status === 'failed')
{
        content =<p>{assets_err}</p>
} 
    
    const location = useLocation();
    const checkIfSelectionDone=useSelector(getVariantDetailStatus);
    const minimumSelect        =useSelector(getVariantAllData);

    const projectType = location.pathname.includes('design-your-own-bathroom') ? 'bathtub' : 'shower';
    let showSummaryButton;
    if (checkIfSelectionDone==='success') {
        let missingCategories = getMissingMinimumSelection(Object.keys(minimumSelect), projectType);
        if (missingCategories.length > 0) {
            showSummaryButton = false;
        } else {
            showSummaryButton = true;
        }
    }
    return (
        <div className="container">
            <div className="maninav">
                <ul id='mobileCategoriesSlider'>
                    {content}
                    {
                        showSummaryButton === true ? (
                            <li>
                                <Link to={`${location.pathname}${location.search}`} onClick={ () =>  dispatch(getIsShowMissingProductsPopUp()) }>
                                    <span className="summary" >{t('button.summary')}</span>
                                </Link>
                            </li>
                        ) : null
                    }
                </ul>
            </div>
        </div>
    )
}

export default MobileHeaderMenu