import React from 'react'
import { useDispatch } from 'react-redux';
import i18next from 'i18next'
import { getAssestVariantImage, setPreviousSelectedColor } from '../designbathshower/designBathShowerSlice';
const MobileColorData = ({parentKey,colorData,allData,otherData,headerIndex}) => {
const dispatch=useDispatch();
let colorName, colorHex;
if (colorData.attributes[0][`option_${i18next.language}`]) {
    colorName = colorData.attributes[0][`option_${i18next.language}`];
    colorHex = colorData.attributes[0].value;
} else {
    colorName = colorData.attributes[0].option;
    colorHex = colorData.attributes[0].value;
}

let variantDetails = Object.values(allData).find(data => data.id === otherData.assetId);

const handleAssetVariantImage = () => {
  dispatch(
    getAssestVariantImage({
      'assetId'          :otherData.assetId,
      'variantId'        :colorData.id,            
      'assestColor'      :colorName,
      'assestColorStyle' :colorHex,
      'assestAllData'    :allData,
      'parentCat'        :parentKey,
      'childLen'         :0,
      'headerIndex'      :headerIndex,
      'mainCatName'      :otherData.catNameEng,
      'Eng'              :{'product_name':variantDetails.name,'color':colorData['attributes'][0]['option'],'category':otherData.catNameEng}  
    })
  );
};

const handleColorSelectionChild = () => {
  handleAssetVariantImage();

  dispatch(
    setPreviousSelectedColor({
      'categoryName': otherData.catNameEng,
      'colorName': colorName
    })
  );
};


  return (
    <span className='boxescolor' onClick={handleColorSelectionChild}>
      <i  className={`white ${colorData.attributes[0].option} ${otherData.variantId === colorData.id ? "active" : ""}`} id={colorHex} style={{"background":colorHex}}></i>
      <em className="colortext">{colorName}</em>
    </span>
  )
        
}

export default MobileColorData