import React,{useEffect} from 'react'
import { useDispatch,useSelector } from 'react-redux'
import { useTranslation } from "react-i18next";
import BathShowerHeader from './BathShowerHeader';
import ShowerPlumbingMobileData from './ShowerPlumbingMobileData';
import { getShowerPlumbingLeftRight,getLeftRightShower,getLeftRightShowerStatus } from '../categorydata/categoryDataSlice'
import Loader from '../loader/Loader';
import { getBookConsultationStatus } from '../designmobilelayout/mobileMenuSlice';
import { useParams } from 'react-router-dom';

const ShowerPlumbingMobile = () => {
const { t }          = useTranslation(); 
const dispatch       = useDispatch();
const params = useParams();
let   showerData     = useSelector(getLeftRightShower);
let   showerStatus   = useSelector(getLeftRightShowerStatus);
const bookConsultationStatus = useSelector(getBookConsultationStatus);

  useEffect(() => {
    dispatch(getShowerPlumbingLeftRight(params.id.split("&")[0].split("=").pop()));
  }, [dispatch, params.id]);

let content;
if(showerStatus ==='loading')
{
    content=<p>{t("loading")}</p>
} 
if(showerStatus ==='idle')
{
  content = showerData.map(showerData=> <ShowerPlumbingMobileData key={showerData.id} showerData={showerData}/>)        
} 
  return (
   
            <>
                  <BathShowerHeader/>
                  <div className="select-icons select-plumbing">
                      <div className="selector-title">
                      <h4>{t("plumbing.configuration")}</h4>
                      <p>{t("plumbing.explanation")}</p>
                      </div>
                      <div className="select-item">
                          {content}                
                      </div>
                  </div>
                  {
                    bookConsultationStatus === 'loading' ? <Loader /> : null
                  }
            </>
   
  )
}

export default ShowerPlumbingMobile