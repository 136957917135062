import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { TranslationKeys } from '../../i18n';
import i18next from 'i18next';
import { GoogleDataLayer, getTagManagerEvents } from '../layout/GoogleDataLayer';

const ProjectData = ({cats}) => {
    const location = useLocation();

    // Get category translation from object if exist, default to category name otherwise
    const categoryName = cats["translations"] && cats["translations"][TranslationKeys[i18next.language]] ?
        cats["translations"][TranslationKeys[i18next.language]]:
        cats.name;

  return (
    <>
        <div className="left-image">
            <div className="thumbnail-img">
                <Link
                    to={cats.slug.toLowerCase().includes('bathtub') ? 
                        `/${i18next.language}/plumbing-position/${cats.id}${location.search}` : 
                    cats.slug.toLowerCase().includes('showers') ?
                        `/${i18next.language}/choose-shower/${cats.id}${location.search}` :
                        `/${i18next.language}/load-existing-design${location.search}`}
                    onClick={() => {
                        if (cats.slug.toLowerCase().includes('bathtub')) {
                            GoogleDataLayer({
                                event: getTagManagerEvents.selectProjectType,
                                projectType: 'Bathtub'
                            });
                        } else if (cats.slug.toLowerCase().includes('showers')) {
                            GoogleDataLayer({
                                event: getTagManagerEvents.selectProjectType,
                                projectType: 'Shower'
                            });
                        }
                    }}
                >
                    <img src={cats.image?window.config.REACT_APP_BACKEND_URL+cats.image.src:''} alt=""/>
                    <p>{categoryName}</p>
                    <div className='backgroundImage'>
                        <img src={cats.background_image?window.config.REACT_APP_BACKEND_URL+cats.background_image.src:''} alt=''/>
                    </div>
                </Link>
            </div>
        </div>
    </>
  )
}

export default ProjectData