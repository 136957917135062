import React from 'react'
import LoaderImg from '../../images/loader.gif'

const Loader = () => {
  return (
    <div className='fullScreenLoader'>
        <img src={LoaderImg} alt=''/>
    </div>
  )
}

export default Loader
