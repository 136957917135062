import React from 'react'
import { useDispatch } from 'react-redux';
import AlertImg from '../../images/alertWarn.png'
import ModalCros from '../../images/crox.png'
import { hideAllAlerts } from '../designmobilelayout/mobileMenuSlice';

const AlertMesssage = ({msg}) => {
const dispatch = useDispatch();
  return (
    <div className='alertMainDiv'>
      <div className='midditemss alert'>
        <div className='imagAlert'><img src={AlertImg} alt=''/></div>
        <div className='msgText'> {msg}</div>
        <div className='cross' onClick={() => dispatch(hideAllAlerts())}> <img src={ModalCros} alt='' /></div>
      </div>
    </div>
  )
}

export default AlertMesssage
