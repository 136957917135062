import React from 'react'
import { useDispatch } from 'react-redux'
import i18next from 'i18next'
import { getAssestVariantImage, setPreviousSelectedColor } from './designBathShowerSlice'
const DesingChildAllColor = ({childColorData,assetId,variantId,allData,parentCatName,childLen,headerIndex,catNameEng}) => {
const dispatch=useDispatch();

  let colorName, colorHex;
  if (childColorData.attributes[0][`option_${i18next.language}`]) {
    colorName = childColorData.attributes[0][`option_${i18next.language}`];
    colorHex = childColorData.attributes[0].value;
  } else {
    colorName = childColorData.attributes[0].option; 
    colorHex = childColorData.attributes[0].value;
  }

  let selectedProduct = Object.values(allData).find(data => data.id === assetId);

  const handleAssetVariantImage = () => {
    dispatch(
      getAssestVariantImage({
        'assetId'          : assetId,
        'variantId'        : childColorData.id,
        'assestColor'      : colorName,
        'assestColorStyle' : colorHex,
        'assestAllData'    : allData,
        'parentCat'        : parentCatName,
        'childLen'         : childLen,
        'headerIndex'      : headerIndex,
        'mainCatName'      : catNameEng,
        'Eng'              :{'product_name':selectedProduct.name,'color':childColorData['attributes'][0]['option'],'category':catNameEng}
      })
    )
  }

  const handleColorSelectionChild = () => {
    handleAssetVariantImage();
    dispatch(
      setPreviousSelectedColor({
        'categoryName': catNameEng,
        'colorName': colorName
      })
    )
  }

  return (
    <>
      <i  className={`white ${childColorData.attributes[0].option} ${variantId === childColorData.id ? "active" : ""}`} id='' style={{"background":colorHex}}  onClick={handleColorSelectionChild}></i>
    </>

  )
}

export default DesingChildAllColor