import { configureStore } from '@reduxjs/toolkit';
import layoutReducer from '../features/layout/layoutSlice'
import summaryReducer from '../features/summary/summarySlice'
import popupReducer from '../features/popup/popupSlice'
import categoryDataReducer from '../features/categorydata/categoryDataSlice'
import designBathShowerReducer from '../features/designbathshower/designBathShowerSlice';
import mobileMenuReducer from '../features/designmobilelayout/mobileMenuSlice';

export const store = configureStore({
  reducer: {
    layout : layoutReducer,
    summary: summaryReducer,
    popupdata:popupReducer,
    cat      :categoryDataReducer,
    designbathshower:designBathShowerReducer,
    mobilemenu:mobileMenuReducer
  },
});
