import i18next from "i18next";

export const minimumBathtubSelection = {
    "en": ["Tub fronts", "Walls"],
    "fr": ["Baignoires", "Murs"],
    "es": ["Bañeras", "Paredes"]
}

export const minimumShowerSelection = {
    "en": ["Shower bases", "Walls"],
    "fr": ["Bases de douche", "Murs"],
    "es": ["Bases de ducha", "Paredes"]
}

// Looks at the current product selection and returns minimum selection category names
export const getMissingMinimumSelection = (selectionMade, projectType, locale = i18next.language) => {
    let missingSelection = [];

    if (projectType.toLowerCase().includes("bathtub")) {
        minimumBathtubSelection[locale].forEach((category) => {
            if (!selectionMade.includes(category)) {
                return missingSelection.push(category);
            }
        });
    } else {
        minimumShowerSelection[locale].forEach((category) => {
            if (!selectionMade.includes(category)) {
                return missingSelection.push(category);
            }
        });
    }

    return missingSelection;
}