import React,{useEffect} from 'react'
 import { useDispatch,useSelector } from 'react-redux'
import { Link,useLocation, useParams } from 'react-router-dom'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'
import { getShowerPlumbingLeftRight,getLeftRightShower,getLeftRightShowerStatus } from '../categorydata/categoryDataSlice'
import ShowerPlumbingData from './ShowerPlumbingData'
import Header from '../header/Header'
import { getBookConsultationStatus } from '../designmobilelayout/mobileMenuSlice';
import Loader from '../loader/Loader';

const ShowerPlumbing = () => {
    const dispatch = useDispatch();
    const location = useLocation();
        
    const params = useParams();
    let showerIds = params.id.split("&");
    let showerTypeId = showerIds[0].split("=").pop();
    let parentId = showerIds[1].split('=').pop();
    let showerData = useSelector(getLeftRightShower);
    let showerStatus = useSelector(getLeftRightShowerStatus);
    const bookConsultationStatus = useSelector(getBookConsultationStatus);
    
    useEffect(() => {
        dispatch(getShowerPlumbingLeftRight(showerTypeId));
    }, [dispatch, showerTypeId]);

    const { t } = useTranslation();

let content
if(showerStatus ==='loading')
{
    content=<p>{t("loading")}</p>
} 
if(showerStatus ==='idle')
{
        content = showerData.map(showerData=> <ShowerPlumbingData key={showerData.id} showerData={showerData}  />)        
}
    return (
        <>
            <Header fixedHeaderClass='headerbgFixed'/>
            <div className="pre-config-main2 plumbing-width-lg">
                <p className="back-to"><Link to={`/${i18next.language}/choose-shower/${parentId}${location.search}`}><span><i className="fas fa-angle-left"></i></span>{t("previousPage")}</Link></p>
                <h2>{t("plumbing.configuration")}</h2>
                <p className="design-exp">{t("plumbing.explanation")}</p>
                <div className="left-right-img">
                    {content}
                </div>
                {
                    bookConsultationStatus === 'loading' ? <Loader /> : null
                }
            </div>
        </>          
    )
}

export default ShowerPlumbing