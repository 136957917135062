import  React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import i18next from 'i18next';
import { useTranslation } from "react-i18next";
import BackArrrow from '../../images/backarrow.png'
import bathFitterLogo from  '../../images/logowhite.png'
import bainMagiqueLogo from  '../../images/BainMagique_Logo_blanc.png'
import { Link,useLocation, useNavigate } from 'react-router-dom'
import { bookNowPopUpDesingBathShower, getIsScheduleDesignBookNowError, getIsScheduleDesignBookNowSuccess, resetDesignId, hideAllAlerts } from '../designmobilelayout/mobileMenuSlice';
import DesignBathShowerBookNowPop from '../designbathshowerpopup/DesignBathShowerBookNowPop';
import { resetAllState } from '../designbathshower/designBathShowerSlice';
import SaveMessage from '../message/SaveMessage';
import AlertMesssage from '../message/AlertMesssage';
import { GoogleDataLayer, getTagManagerEvents } from '../layout/GoogleDataLayer';

const Header = ({isOrientationChanged = false, fixedHeaderClass = `headerbg`}) => { 
const dispatch     = useDispatch();

const isScheduleDesignBookNowSuccess = useSelector(getIsScheduleDesignBookNowSuccess);
const isScheduleDesignBookNowError = useSelector(getIsScheduleDesignBookNowError);

const { t } = useTranslation();
const location = useLocation();
if(location.pathname.includes('plumbing-position'))
{
    dispatch(resetAllState())
    dispatch(resetDesignId());
}
const logo = window.location.hostname.includes('bainmagique') || 
    location.search.includes("brand=BM") ? bainMagiqueLogo : bathFitterLogo;

const navigate = useNavigate();
const navigateBack = () => {
    navigate(-1);
    dispatch(resetAllState())
    dispatch(resetDesignId());
}

    const refreshPage = () => {
        window.location.reload(false);
        window.location.href = `/${i18next.language}${location.search}`;
    }
   
    useEffect(() => {

        if (isScheduleDesignBookNowSuccess || isScheduleDesignBookNowError) {            
            const interval = setInterval(() => dispatch(hideAllAlerts()), 2000)
            return () => clearInterval(interval)
        }
    }, [isScheduleDesignBookNowSuccess, isScheduleDesignBookNowError, dispatch]);

    useEffect(() => {
        // Prevent duplicate event push when changing screen orientation
        if (!isOrientationChanged) {
            GoogleDataLayer({event: getTagManagerEvents.pageView});
        }
    }, [isOrientationChanged]);

    return ( 
    <>
        <header className={fixedHeaderClass}>
            <div className="container">
                <span className="col-sm-1 backicon">
                    {
                        location.pathname !== '/' &&
                        location.pathname !== `/${i18next.language}` ? (
                            <Link to={`${location.pathname}${location.search}`} onClick={navigateBack}><img src={BackArrrow} alt=''/></Link>
                        ) : null
                    }
                </span>
                <span className="col-sm-3 logo">
                    <Link to={`/${i18next.language}${location.search}`} onClick={refreshPage}><img src={logo} alt='' /></Link>
                </span>
                <button className="consult" onClick={ () => dispatch(bookNowPopUpDesingBathShower())}>{t("button.bookFree")}</button>
            </div>
            
            {
                isScheduleDesignBookNowSuccess === true ? <SaveMessage msg={t('alert.requestSent')}/> : null
            }
            {
                isScheduleDesignBookNowError === true ? <AlertMesssage msg={t('alert.requestFailed')}/> : null
            }
        </header>
        {<DesignBathShowerBookNowPop/>}
    </> 
    )
}

export default Header
