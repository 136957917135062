import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import '../node_modules/slick-carousel/slick/slick.css'
import '../node_modules/slick-carousel/slick/slick-theme.css'
import './StyleCustom.css'
import './Layout.css'
import './ResponsiveCustom.css'
import './StyleMobile.css'
import  Header  from './features/header/Header'
import HeaderMenu  from './features/headermenu/HeaderMenu';
import Layout from './features/layout/Layout';
import AlertMesssage from './features/message/AlertMesssage';
import SaveMessage from './features/message/SaveMessage';
import Loader from './features/loader/Loader';
import { useParams } from 'react-router-dom';
import { Link,useLocation } from 'react-router-dom';
import ThirdIcon from './images/icon3.png';
import Undo from './images/undo.svg';
import Share from './images/share.svg';
import Save from './images/save.svg';
import Info from './images/info.svg';
import Download from './images/downloadImg.png';
import DesignSummaryPopUp from './features/designbathshowersummary/DesignSummaryPopUp';
import { getSummaryMissingData, getSelectedAssetsID, getBigImageUrl, openStartOverPopUp, getIsShowMissingProductsPopUp, getMobileDivClassCheck, getParentKey, getAllAssetsHeaderMenus, getIsSummaryViewActive, setMobileParentKey, fetchProjectType, setMobileParentKeyIndex, getParentKeyIndex, loadDesignData, getOrderSelectedSubCat, getDesignImageSource, getCategoryProductsData, selectAllAssests, removeSingleProduct, getLoadExistingDesignStatus, getHasLoadedDesign, openSavePopUp } from './features/designbathshower/designBathShowerSlice';
import DesignStartOverPopUp from './features/designbathshowerpopup/DesignStartOverPopUp';
import SaveDesignPopUp from './features/designbathshowerpopup/SaveDesignPopUp';
import BathShowerHeader from './features/designbathshowermobile/BathShowerHeader';
import MobileDesignLayout from './features/designmobilelayout/MobileDesignLayout';
import MobileHeaderMenu from './features/designmobilelayout/MobileHeaderMenu';
import MobileDesignContent from './features/designmobilecontent/MobileDesignContent';
import MobileDesignSummary from './features/designmobilecontent/MobileDesignSummary';
import { getSaveForSocialStatus, hideAllAlerts, getSaveDesignStatus, getIsShowSaveDesignError, getBookConsultationStatus, getIsShowSaveDesignSuccess, getIsScheduleDesignBookNowSuccess, getIsScheduleDesignBookNowError, openMobileSharePopUp, showMinimumSelectionAlert, getIsShowMinimumSelectionAlert, getIsShowProductRequiredAlert, getIsShowLinkCopiedAlert, getIsShowShareByEmailSuccessAlert, getIsShowShareByEmailFailAlert, getShareByEmailStatus, showProductRequiredAlert, getBlobStorageToken, getIsShowSaveForShareErrorAlert } from './features/designmobilelayout/mobileMenuSlice';
import SaveDesignLayoutRightPopUp from './features/designbathshowerpopup/SaveDesignLayoutRightPopUp';
import ShareDesignPopUp from './features/designbathshowerpopup/ShareDesignPopUp';
import ShareDesignLayoutRightPopUp from './features/designbathshowerpopup/ShareDesignLayoutRightPopUp';
import HelpScreen from './features/designbathshowermobile/HelpScreen';
import i18next from 'i18next';
import { GoogleDataLayer, getTagManagerEvents } from './features/layout/GoogleDataLayer';
import { getMissingMinimumSelection, minimumBathtubSelection, minimumShowerSelection } from './features/designbathshowerpopup/MinimumSelection';
import jsPDF from 'jspdf';

function App() {
const dispatch        = useDispatch();  

const paramsData         = useParams();
const get_assest_count = useSelector(getIsShowProductRequiredAlert);
const isShowMinimumSelectionAlert = useSelector(getIsShowMinimumSelectionAlert); 
const saveDesignStatus = useSelector(getSaveDesignStatus);   
const bookConsultationStatus = useSelector(getBookConsultationStatus);
const isShowSaveDesignSuccess = useSelector(getIsShowSaveDesignSuccess);
const isShowSaveDesignError = useSelector(getIsShowSaveDesignError);
const isScheduleDesignBookNowSuccess = useSelector(getIsScheduleDesignBookNowSuccess);
const isScheduleDesignBookNowError = useSelector(getIsScheduleDesignBookNowError);

const isShowLinkCopiedAlert = useSelector(getIsShowLinkCopiedAlert);
const shareByEmailStatus = useSelector(getShareByEmailStatus);
const isShowShareByEmailSuccessAlert = useSelector(getIsShowShareByEmailSuccessAlert);
const isShowShareByEmailFailAlert = useSelector(getIsShowShareByEmailFailAlert);
const isShowSaveForShareFailed = useSelector(getIsShowSaveForShareErrorAlert);

const { t } = useTranslation();
const saveForSocialStatus = useSelector(getSaveForSocialStatus);

const clickedMenu=useSelector(getMobileDivClassCheck);

const isSummaryViewActive = useSelector(getIsSummaryViewActive);
const mobileParentKey = useSelector(getParentKey)
const mobileParentIndex=useSelector(getParentKeyIndex);

const orderSelectedSubCat=useSelector(getOrderSelectedSubCat);
const loadExistingDesignStatus = useSelector(getLoadExistingDesignStatus);
const hasLoadedDesign = useSelector(getHasLoadedDesign);
const location = useLocation();

  const moveMobileHeaderToCategory = (categoryElement) => {
    let headerElement = document.getElementById('mobileCategoriesSlider');

    let horizontalScroll = 0;
    for (const li of headerElement.childNodes) {
      if (li === categoryElement) {
        break;
      }
      horizontalScroll += li.clientWidth;
    }

    headerElement.parentElement.scrollLeft = horizontalScroll;
  }

  const mobileUndoLastStep = () => {
    if (Object.keys(orderSelectedSubCat).indexOf(mobileParentKey) !== -1) {
      // Remove last item selected in current category
      const category = orderSelectedSubCat[mobileParentKey];
      let subCategories = Object.keys(category);
      if (Object.keys(category).length>0) {
        let subCategory = subCategories[subCategories.length - 1];
        dispatch(removeSingleProduct({ 'categoryName': mobileParentKey, 'subCategoryName': subCategory }));
      } 
    } else {
      // No remaining item, go back to previous category      
      let previousHeaderElement = document.querySelector('#mobileCategoriesSlider li.active').previousSibling;

      if (previousHeaderElement) {
        moveMobileHeaderToCategory(previousHeaderElement);

        let previousElementValue = previousHeaderElement.querySelector('li a').lastChild;
        dispatch(setMobileParentKey(previousElementValue.data));
        dispatch(setMobileParentKeyIndex(mobileParentIndex - 1));
      }
    }
  }

  const mobileGoToNextStep = () => {
    let nextHeaderElement = document.querySelector('#mobileCategoriesSlider li.active').nextSibling;
    if (nextHeaderElement) {
      moveMobileHeaderToCategory(nextHeaderElement);

      let nextHeaderElementValue = nextHeaderElement.querySelector('li a').lastChild;
      if (nextHeaderElementValue.data) {
        // There's another category after the current one
        dispatch(setMobileParentKey(nextHeaderElementValue.data));
        dispatch(setMobileParentKeyIndex(mobileParentIndex + 1));
      } else {
        // Summary step
        dispatch(getIsShowMissingProductsPopUp());
      }
    }
  }

    // React hook whenever the category id changes
    useEffect(() => {
        if (paramsData.id > 0) {
            // Get the parent category names for the header, the project type and the image to place in the right layout
            dispatch(getAllAssetsHeaderMenus(paramsData.id));
            dispatch(fetchProjectType(paramsData.id));
            dispatch(getBigImageUrl({'catid': paramsData.id}));
            dispatch(getBlobStorageToken());
        }
    }, [dispatch, paramsData.id]);

    // React hook whenever the design id changes
    useEffect(() => {
        if (paramsData.id > 0 && paramsData.designId > 0 && !hasLoadedDesign) {
            // Get the design information
            dispatch(loadDesignData(paramsData.designId));
        }
    }, [dispatch, paramsData, hasLoadedDesign]);

    useEffect(() => {
      if (get_assest_count || isShowLinkCopiedAlert || isShowMinimumSelectionAlert ||
        isShowSaveDesignSuccess || isShowSaveDesignError || isShowShareByEmailSuccessAlert ||
        isShowShareByEmailFailAlert || isShowSaveForShareFailed) {
            
        const interval = setInterval(() => dispatch(hideAllAlerts()), 2000)
        return () => clearInterval(interval)
      }
    }, [get_assest_count, isShowLinkCopiedAlert, isShowMinimumSelectionAlert, isShowSaveDesignSuccess,
      isShowSaveDesignError, isShowShareByEmailSuccessAlert, isShowShareByEmailFailAlert,
      isShowSaveForShareFailed, dispatch]);

  const assetData = useSelector(selectAllAssests);
  useEffect(() => {
    const callGetCategoryProductsData = async () => {
      for (const category in assetData){
        await dispatch(getCategoryProductsData(assetData[category]));
      }
    }
    callGetCategoryProductsData()
  }, [dispatch, assetData]);

const missingCategoriesForSummary = useSelector(getSummaryMissingData);
const selectedProductIds = useSelector(getSelectedAssetsID);

  const projectType = location.pathname.includes('design-your-own-bathroom') ? 'bathtub' : 'shower';
  const minimumMissingCategories = getMissingMinimumSelection(Object.keys(selectedProductIds), projectType);
  const [missingCategoriesText, setMissingCategoriesText] = useState('');
  // This React hook allows to prepare the appropriate alert text depending what minimal category is not yet selected
  useEffect(() => {
    if (minimumMissingCategories.length === 2) { // All minimal categories are missing

      if (projectType.includes('bathtub')) {
        setMissingCategoriesText(t('alert.minimumSelectionBathtubBoth'));
      } else {
        setMissingCategoriesText(t('alert.minimumSelectionShowerBoth'));
      }

    } else if (minimumMissingCategories.length === 1) { // Only one minimal category is missing

      switch (minimumMissingCategories[0]) {
        case minimumBathtubSelection[i18next.language][0]:
          // Tub front
          setMissingCategoriesText(t('alert.minimumSelectionBathtub'));
          break;

        case minimumShowerSelection[i18next.language][0]:
          // Shower base
          setMissingCategoriesText(t('alert.minimumSelectionShowerBase'));
          break;

        default:
          // Walls
          setMissingCategoriesText(t('alert.minimumSelectionWall'));
          break;
      }

    } else { // No minimal category missing
      setMissingCategoriesText('');
    }
  }, [minimumMissingCategories, projectType, t]);

  const onShareClick = () => {
    if (minimumMissingCategories.length > 0) {
      dispatch(showMinimumSelectionAlert());
    } else {
      dispatch(openMobileSharePopUp());
    }
  }
  
  let designImage = useSelector(getDesignImageSource);
  let generatePDFIMage = () => {     
    let doc = new jsPDF();   
    doc.setFontSize(40);
    doc.text(t("summary.finalDesign"), 35, 25);
    doc.addImage(designImage, "JPEG", 15, 40, 180, 180);
    doc.save('bathfitterDyob.pdf')
  }
  
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1081);
  const [isOrientationChanged, setIsOrientationChanged] = useState(false);
  useEffect(() => {
    const updateMobileStatus = () => {
      setIsMobile(window.innerWidth < 1081);
      setIsOrientationChanged(true);
    }

    const mediaQuery = window.matchMedia('(max-width: 1081px)');
    mediaQuery.addEventListener('change', updateMobileStatus);
    return () => mediaQuery.removeEventListener('change', updateMobileStatus);
  }, []);

  const handleStartOverClick = () => {
    if (Object.keys(selectedProductIds).length === 0) {
      window.location.href = `/${i18next.language}${location.search}`;
    } else {
      dispatch(openStartOverPopUp());
    }
  }

  const handleSaveClick = () => {
    if (Object.keys(selectedProductIds).length === 0) {
      dispatch(showProductRequiredAlert());
    } else {
      dispatch(openSavePopUp());
    }
  }
  
  return (
    <>
      {
        isMobile === false ? (
          <div id="desktop" className="middleitems">
            <div className="headerfixed">
              <Header isOrientationChanged={isOrientationChanged}/>
              <HeaderMenu/>
              <div className="sideitems">
                <div className="container">
                  <Layout/>
                </div>
              </div>
            </div>
            <ShareDesignLayoutRightPopUp/>
            <SaveDesignLayoutRightPopUp/>
          </div>
        ) : (
          <div id="Mobile" className="mobileresponsive">
            <BathShowerHeader isOrientationChanged={isOrientationChanged}/>
            <div className='MobileNavLayout'>
              <div className="col-sm-12 rightitems">
                <MobileDesignLayout/>
              </div>
              <div className="navigation">
                {
                  isSummaryViewActive !== true ? <MobileHeaderMenu/> : null
                }
              </div>
            </div>
            <div className={`col-sm-12 leftmenu  ${clickedMenu ===true ? 'mobileAssestActive' : ''}`}>
              <div className="row marginpadding">
                <div className="middle_scroll"> 
                  {
                    isSummaryViewActive !== true ? <MobileDesignContent/> : <MobileDesignSummary/>
                  }
                </div>
              </div>
            </div>
            <HelpScreen/>
            <div className='bottomfixed'>
              <ul className='middle'>
                <li>
                  <Link to={`${location.pathname}${location.search}`}  onClick={handleStartOverClick}><img src={Info} alt=''/></Link>
                </li>
                <li>
                  <Link to={`${location.pathname}${location.search}`} onClick={handleSaveClick}><img src={Save} alt=''/></Link>
                </li>
                <li>
                  <Link to={`${location.pathname}${location.search}`} onClick={onShareClick}><img src={Share} alt=''/></Link>
                </li>
                {
                  isSummaryViewActive !== true ? (
                    <li>
                      <Link to={`${location.pathname}${location.search}`}><img src={Undo} alt='' onClick={mobileUndoLastStep}/></Link>
                    </li>
                  ) : null
                }
              </ul>
              <div className='fixedbck'>
                {
                  isSummaryViewActive !== true ? (
                    <img src={ThirdIcon} alt='' onClick={mobileGoToNextStep}/>
                  ) : (
                    <img src={Download} alt='' onClick= {() => {
                      generatePDFIMage();
                      GoogleDataLayer({event: getTagManagerEvents.download});
                    }}/>
                  )
                }
              </div> 
            </div>
            <ShareDesignPopUp/>
          </div>
        )
      }
      
      <DesignStartOverPopUp/>
      <SaveDesignPopUp/>
      
      {
        missingCategoriesForSummary.length > 0 ? <DesignSummaryPopUp/> : null
      } 
      {
        get_assest_count === true ? <AlertMesssage msg={t('alert.selectOne')}/> : ''
      }
      {
        isShowMinimumSelectionAlert ? <AlertMesssage msg={missingCategoriesText}/> : null
      }
      {
        isShowSaveDesignSuccess === true ? <SaveMessage msg={t('alert.designSaved')}/> : ''
      }
      {
        isShowSaveDesignError === true ? <AlertMesssage msg={t('alert.designSaveFailed')}/> : ''
      }
      {
        isScheduleDesignBookNowSuccess === true ? <SaveMessage msg={t('alert.requestSent')}/> : ''
      }
      {
        isScheduleDesignBookNowError === true ? <AlertMesssage msg={t('alert.requestFailed')}/> : ''
      }
      {
        isShowLinkCopiedAlert ? <SaveMessage msg={t('alert.linkCopied')}/> : null
      }
      {
        isShowShareByEmailSuccessAlert ? <SaveMessage msg={t('alert.shareByEmailSuccess')}/> : null
      }
      {
        isShowShareByEmailFailAlert ? <AlertMesssage msg={t('alert.shareByEmailFailed')}/> : null
      }
      {
        isShowSaveForShareFailed ? <AlertMesssage msg={t('alert.saveForShareFailed')}/> : null
      }
      {
        saveDesignStatus === 'loading' ? <Loader /> : null
      }
      {
        bookConsultationStatus === 'loading' ? <Loader /> : null
      }
      {
        saveForSocialStatus === 'loading' ? <Loader /> : null
      }
      {
        shareByEmailStatus === 'loading' ? <Loader /> : null
      }
      {
        loadExistingDesignStatus === 'loading' ? <Loader />: null
      }
    </>
  );
}

export default App;