import React from 'react'
import { useSelector,useDispatch } from 'react-redux';
import {useForm} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getIsSaveDesignLayoutRightPopUpActive,saveMyWebBathShowerDesignClose,addNewDesignBathShower, getDesignId } from '../designmobilelayout/mobileMenuSlice'
import { getVariantAllData } from '../designbathshower/designBathShowerSlice';
import ModalCros from '../../images/crox.png'
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import i18next from 'i18next';
import { getCampaignData } from '../campaignanalytics/CampaignAnalytics';
import { GoogleDataLayer, getTagManagerEvents } from '../layout/GoogleDataLayer';
import Checkbox from '../formfields/Checkbox';
import TextInputField from '../formfields/TextInputField';
import EmailInputField from '../formfields/EmailInputField';
const SaveDesignLayoutRightPopUp = () => {
const dispatch                  = useDispatch();
const web_pop_status            = useSelector(getIsSaveDesignLayoutRightPopUpActive);
const { t }                     = useTranslation();
const allData                   = useSelector(getVariantAllData);

let allDataJson = {
    'allData':allData,
}

const designId        = useSelector(getDesignId);
const params          = useParams();
let catid             = params.id 
const location        = useLocation(); 
let pathName
if(location.pathname.split("/").indexOf('design-your-own-bathroom') > -1)
{
    pathName='design-your-own-bathroom'
} 
else{
    pathName='design-your-own-shower'
}

    const { register, reset, handleSubmit, formState: { errors } } = useForm();

    const onSubmit =(data, e) => {
        dispatch(addNewDesignBathShower({
            'firstName': data.firstName.trim(),
            'lastName': data.lastName.trim(),
            'designId': designId,
            'allData' :allDataJson,
            'platform': 'web',
            'email': data.email,
            'locale': i18next.language,
            'catid':catid,
            'pathName':pathName,
            'isMarketingConsent': true,
            'campaignInformations': getCampaignData(location)
        }));
        reset();
        dispatch(saveMyWebBathShowerDesignClose());
        GoogleDataLayer({event: getTagManagerEvents.save});
    };

  return (
    (web_pop_status===true)?(<div className={`modal fade  in WebSaveDesing ${Object.keys(errors).length > 0 ? `errors${Object.keys(errors).length}` : ''}`} id="WebSaveDesingId"  role="dialog" data-backdrop="static" data-keyboard="false" style={{"display":"block"}}>
    <div className="modal-dialog" role="document">
        <div className="modal-content">
           
            <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">{t("saveDesign.title")}
                    <span className="close" data-dismiss="modal" onClick={ () =>  dispatch(saveMyWebBathShowerDesignClose())}><img src={ModalCros} alt=""/></span>
                </h5>
            </div>                    
            <div className="modal-body">
                <div className="startmid">
                    <p className="emailtexts">{t("saveDesign.instructions")}</p>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <TextInputField name="firstName"
                            placeholder={t("label.firstName")}
                            register={register}
                            requiredErrorMessage={t("error.firstNameRequired")}
                            errors={errors}
                        />
                        <TextInputField name="lastName"
                            placeholder={t("label.lastName")}
                            register={register}
                            requiredErrorMessage={t("error.lastNameRequired")}
                            errors={errors}
                        />
                        <EmailInputField name="email"
                            customClass={"col-sm-12 fullform"}
                            placeholder={t("label.email")}
                            register={register}
                            requiredErrorMessage={t("error.emailRequired")}
                            patternErrorMessage={t("error.emailFormat")}
                            errors={errors}
                        />
                        <Checkbox name="isMarketingConsent"
                            customClass="custom-checkbox"
                            label={t("label.marketingConsent", {
                                brand: window.location.hostname.includes('bainmagique') || location.search.includes("brand=BM") ? 
                                'Bain Magique' : 'Bath Fitter'
                            })}
                            register={register}
                            requiredErrorMessage={t("error.marketingConsentRequired")}
                            errors={errors}
                        />
                        <div className="col-sm-12 fullform">
                            <button type="submit"  className="btn upload">{t("button.save")}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div> ):null
  )
}

export default SaveDesignLayoutRightPopUp