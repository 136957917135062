import React from 'react'
import { useDispatch ,useSelector} from 'react-redux';
import DesignBathShowerInnerData from './DesignBathShowerInnerData';
import DesignBathShowerChildList from './DesignBathShowerChildList';
import DesignBathShowerColorV from './DesignBathShowerColorV';
import { getVariantAllData,getSelectedAssetsID,getClickedMenuVal, clearEntireCategory, } from './designBathShowerSlice';
import { Link,useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";


const DesingBathShowerData = ({finalData,index,indexKey}) => {
  
const dispatch             = useDispatch(); 
let colorVarinatData       = useSelector(getVariantAllData);
const headTitleSelected  = useSelector(getSelectedAssetsID);



let colorVarinatDiv;
if(Object.keys(colorVarinatData).length >0)
{  
   colorVarinatDiv=Object.entries(colorVarinatData).map(([key,colorData]) => <DesignBathShowerColorV key={key} colorData={colorData} title={index.toLowerCase().replace(/\s/g, '')}  allData={finalData} titleNameChild={key} mainCat={index} headerIndexKey={indexKey}/>)
}

const { t } = useTranslation();
  
let content_inner,child_data 
if(finalData !==undefined)
{
  if(finalData.length >0)
  {     
    
    content_inner=finalData.map(innerData=> <DesignBathShowerInnerData key={innerData.id} innerData={innerData} mainCat={index} headerIndexKey={indexKey}/>)
  }
  else
  {
      if(Object.keys(finalData).length >0)
      {    
           
        child_data=Object.entries(finalData).map(([childKey,childData])=><DesignBathShowerChildList key={childKey} childData={childData} childTitle={childKey} childLen={Object.keys(finalData).length} parentCatName={index} headerIndexKey={indexKey} />)
      }
  }
}

const location = useLocation();

let menuId;
if (index.includes('/')) {
  menuId = index.replace(/\s*\/\s*/g, '');
} else {
  menuId = index.replace(/\s/g, '');
}

const clickedMenu           = useSelector(getClickedMenuVal);

const clearSelection = () => {
  if (child_data && Object.keys(finalData).length > 2) {
    dispatch(clearEntireCategory({'categoryName': index, 'subCategoryNames': Object.keys(headTitleSelected[index])}));
  } else {
    dispatch(clearEntireCategory({'categoryName': index}));
  }
};

  return (
    <>
      <div className= {`filtertext ${(Object.keys(headTitleSelected).indexOf(index)!==-1 ||(indexKey===0 && clickedMenu===null) || clickedMenu===index)?'active':''}`} id={'slide'+menuId}>
        <div className="col-sm-7 headingleft">
          <p className="mainhead">{index}  </p>
        </div>
        <div className="col-sm-5 addbutton">
          {
            Object.keys(headTitleSelected).indexOf(index) !== -1 ? (
              <Link to={`${location.pathname}${location.search}`} className="clear clearAsset active"  onClick={clearSelection}>{t("clearSelection")}</Link>
            ) : null
          }
        </div>
      </div>
      
      <div className="row marginpadding" id={index.toLowerCase().replace(/\s/g, '')}>
        <div className="middimages" >                              
          <div className="imagesfull">
            {content_inner}
            {child_data}
          </div>
          {colorVarinatDiv}
        </div>
      </div>
    </>
  )
}

export default DesingBathShowerData