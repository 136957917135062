import React,{useEffect} from 'react'
import { useSelector,useDispatch } from 'react-redux'
import { selectShowerList,showerParentId,chooseshowerstatus,chooseshowererr,getShowerPlumbingPositions } from '../categorydata/categoryDataSlice'
import Header from '../header/Header'
import { Link,useLocation, useParams } from 'react-router-dom'
import i18next from 'i18next'
import { useTranslation } from "react-i18next";
import ShowerData from './ShowerData'
import { getBookConsultationStatus } from '../designmobilelayout/mobileMenuSlice';
import Loader from '../loader/Loader';

const ChooseShowerList = () => {
const dispatch         = useDispatch(); 
const chooseShower     = useSelector(selectShowerList);
const showerStatus      = useSelector(chooseshowerstatus);
const showerError       = useSelector(chooseshowererr); 
const parentId         = useSelector(showerParentId);
const bookConsultationStatus = useSelector(getBookConsultationStatus);

const location = useLocation();
    
    const params = useParams();
    useEffect(() => {
        dispatch(getShowerPlumbingPositions(params.id))
    }, [dispatch, params.id]);

const { t } = useTranslation();

let content;
if(showerStatus ==='loading')
{
    content=<p>{t("loading")}</p>
}
if(showerStatus ==='idle' && chooseShower.length >0 )
{
    content = chooseShower.map(chooseShower=> <ShowerData key={chooseShower.id} chooseShower={chooseShower} parentId={parentId} />)        
}
if(showerStatus === 'failed')
{
        content =<p>{showerError}</p>
}    
  return (
    <>
        <Header fixedHeaderClass='headerbgFixed'/>
        <div className="pre-config-main2 choose-showers">
            <p className="back-to"><Link to={`/${i18next.language}/project-type${location.search}`}><span><i className="fas fa-angle-left"></i></span>{t("previousPage")}</Link></p>
            <h2>{t("shower.instructions")}</h2>
            <div className="left-right-img">
                {content}
            </div>
        </div>
        {
            bookConsultationStatus === 'loading' ? <Loader /> : null
        }
     </>
  )
}

export default ChooseShowerList