import React from 'react'
import { useTranslation } from "react-i18next";
import VarinatSelector from '../../images/selectcolor.png'
import DesingBathShowerColors from './DesingBathShowerColors'
import Capital from '../layout/Capital'

const DesignBathShowerColorV = ({colorData,title,allData,mainCat,headerIndexKey}) => {
   let allVariantColorsDiv,selectedColrName,colorSelected,titleName;
   
   if(Object.keys(colorData.mainData).length >0)
   { 
        allVariantColorsDiv=Object.values(colorData.mainData).map((colorVarinatsData,index)=> <DesingBathShowerColors key={colorVarinatsData.id} colorVarinatsData={colorVarinatsData} variantId={colorData.otherData.variantId} assetId={colorData.otherData.assetId} allData={allData} parentCatName={mainCat} childLen={colorData.otherData.childLen} headerIndexKey={headerIndexKey} catNameEng={colorData.otherData.catNameEng} />).reverse()
   }
   
  const { t } = useTranslation();
  
   colorSelected    =Capital(colorData.otherData.assetColor)?Capital(colorData.otherData.assetColor):'';
   selectedColrName  =colorData.otherData.selectedCat.toLowerCase().replace(/\s/g, '')
   titleName         =title.toLowerCase().replace(/\s/g, '');

  return (
           (selectedColrName===titleName)?(<div className="col-sm-12 colors">
           <div className="bordercolor">                    
               <span><i><img src={VarinatSelector} alt=""/></i>{t("selectColor")}</span>
               {allVariantColorsDiv}
               <span className="selected">{t("selected")}: <em>{colorSelected}</em></span>
           </div>
           </div>):''
           
  )
}

export default DesignBathShowerColorV