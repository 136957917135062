import React from 'react'
import { useSelector } from 'react-redux'
import Header from '../header/Header'
import BathShowerHeader from '../designbathshowermobile/BathShowerHeader'
import { Link, useLocation } from 'react-router-dom'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'
import { getBookConsultationStatus } from '../designmobilelayout/mobileMenuSlice';
import Loader from '../loader/Loader'

const PageNotFound = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const bookConsultationStatus = useSelector(getBookConsultationStatus);

    return (
        <>
            <div id="desktop" className="middleitems">
                <Header fixedHeaderClass='headerbgFixed' />
                <div className="container error-container">
                    <div className="row d-flex align-items-center justify-content-center">
                        <div className="col-md-12 text-center">
                            <h1 className="big-text">{t("pageNotFound.title")}</h1>
                            <h2 className="small-text">{t("pageNotFound.errorCode")}</h2>
                        </div>
                        <div className="col-sm-12 text-center">
                            <p>{t("pageNotFound.description")}</p>
                            <Link to={`/${i18next.language}${location.search}`} className="button button-dark-blue iq-mt-15 text-center">{t("pageNotFound.home")}</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div id="Mobile" className="mobileresponsive">
                <BathShowerHeader/>
                <div className="container error-container">
                    <div className="row d-flex align-items-center justify-content-center">
                        <div className="col-md-12 text-center">
                            <h1 className="big-text">{t("pageNotFound.title")}</h1>
                            <h2 className="small-text">{t("pageNotFound.errorCode")}</h2>
                        </div>
                        <div className="col-sm-12 text-center">
                            <p>{t("pageNotFound.description")}</p>
                            <Link to={`/${i18next.language}${location.search}`} className="button button-dark-blue iq-mt-15 text-center">{t("pageNotFound.home")}</Link>
                        </div>
                    </div>
                </div>
            </div>
            {
                bookConsultationStatus === 'loading' ? <Loader /> : null
            }
        </>
    )
}

export default PageNotFound
