import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import i18next from 'i18next'
import { TranslationKeys } from '../../i18n'
import ArrowRight from '../../images/arrow-right.svg'
import { GoogleDataLayer, getTagManagerEvents } from '../layout/GoogleDataLayer'

const ShowerDataMobile = ({chooseShower,parentId}) => {

    const location = useLocation();
    const categoryName = chooseShower["translations"] && chooseShower["translations"][TranslationKeys[i18next.language]] ?
        chooseShower["translations"][TranslationKeys[i18next.language]] :
        chooseShower.name;   

    return (
        <Link
            to={`/${i18next.language}/shower-plumbing-position-m/showerId=${chooseShower.id}&parentId=${parentId}${location.search}`}
            onClick={() => GoogleDataLayer({
                event: getTagManagerEvents.selectShowerType,
                showerType: chooseShower.name
            })}
        >
            <div className="item-outer">
                <img src={window.config.REACT_APP_BACKEND_URL+chooseShower.background_image.src} alt={categoryName}/>
                <div className="item-title">
                    <p>{categoryName}</p>
                    <img src={ArrowRight} alt="arrow"/>
                </div>
            </div>
        </Link>
    )
}

export default ShowerDataMobile