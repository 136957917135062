import React,{useState,useEffect} from 'react'
import { useSelector,useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Email from '../../images/Email-Icon.svg'
import LinkIcon from '../../images/Link-Icon.svg'
import Crox from '../../images/crox.png'
import Tags from '../../images/tags.png'
import { saveDesignForSharing, getSaveForSocialStatus, getShareLink, closeMobileSharePopUp, getIsMobileSocialPopUpActive, showLinkCopiedAlert, getShareDesignId, shareDesignByEmail } from '../designmobilelayout/mobileMenuSlice';
import { useParams } from 'react-router-dom';
import { getVariantAllData } from '../designbathshower/designBathShowerSlice';
import { useLocation } from 'react-router-dom';
import i18next from 'i18next';
import { useForm } from 'react-hook-form';
import { GoogleDataLayer, getTagManagerEvents } from '../layout/GoogleDataLayer';
import TextInputField from '../formfields/TextInputField';
import EmailInputField from '../formfields/EmailInputField';
import Checkbox from '../formfields/Checkbox';

const ShareDesignPopUp = () => {
    const dispatch = useDispatch(); 
    const { t } = useTranslation();
    const isMobileSharePopUpActive = useSelector(getIsMobileSocialPopUpActive);
    const saveForShareStatus = useSelector(getSaveForSocialStatus);
    let [selectedShareMethod, setSelectedShareMethod] = useState('email');

    const shareDesignId = useSelector(getShareDesignId);
    const urlSocial = useSelector(getShareLink);
    const allData = useSelector(getVariantAllData);

    const params = useParams();
    let catid = params.id 
    const location = useLocation(); 
    let pathName
    if (location.pathname.includes('design-your-own-bathroom')) {
        pathName = 'design-your-own-bathroom';
    } else {
        pathName = 'design-your-own-shower';
    }

    const { register, reset, handleSubmit, formState } = useForm();
    const { errors } = formState;

    useEffect(() => {
        async function saveDesign() {
            if (isMobileSharePopUpActive === true) {
                let allDataJson = {
                    'allData': allData,
                };

                await dispatch(saveDesignForSharing({
                    'allData': allDataJson,
                    'locale': i18next.language,
                    'catid': catid,
                    'pathName': pathName
                }));
            }
        }
        saveDesign();
    }, [isMobileSharePopUpActive, catid, dispatch, pathName, allData]);

    const onSubmit = (data, e) => {
        dispatch(shareDesignByEmail({
            'shareDesignId': shareDesignId,
            'firstName': data.firstName.trim(),
            'lastName': data.lastName.trim(),
            'email': data.email,
            'locale': i18next.language,
            'friendEmail': data.friendEmail,
            'isMarketingConsent': true
        }));
        reset();
        GoogleDataLayer({
            event: getTagManagerEvents.share,
            shareMethod: selectedShareMethod
        });
    }

    const onCopyLink = () => {
        navigator.clipboard.writeText(urlSocial);
        dispatch(showLinkCopiedAlert());
        GoogleDataLayer({
            event: getTagManagerEvents.share,
            shareMethod: selectedShareMethod
        });
    }

    const onSelectedShareMethodChanged = (shareMethod) => {
        setSelectedShareMethod(shareMethod);
        if (shareMethod === 'facebook' || shareMethod === 'pinterest' || shareMethod === 'twitter') {
            GoogleDataLayer({
                event: getTagManagerEvents.share,
                shareMethod: selectedShareMethod
            });
        }
    } 

    return (
        (isMobileSharePopUpActive === true && saveForShareStatus !== 'loading') ? (
            <div className="modal fade in" id="share"  role="dialog" data-backdrop="static" data-keyboard="false" style={{display: "block"}}> 
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">{t("shareDesign.title")}
                                <span className="close" data-dismiss="modal" onClick={ () =>  dispatch(closeMobileSharePopUp())}><img src={Crox} alt='' /></span>
                            </h5>
                        </div>
                        <div className="modal-body">
                            <div className="startmid">
                                <p className="emailtexts">{t("shareDesign.shareVia")}</p>
                                <div className="socialicons">
                                    <li className={`${selectedShareMethod === 'email' ? 'socialLiActive' : ''}`} onClick={() => onSelectedShareMethodChanged('email')}>
                                        <img src={Email} alt=''/>
                                    </li>
                                    <li className={`${selectedShareMethod === 'link' ? 'socialLiActive' : ''}`} onClick={() => onSelectedShareMethodChanged('link')}>
                                        <img src={LinkIcon} alt=''/>
                                    </li>
                                </div>

                                <div className={`${selectedShareMethod !== 'email' ? 'emailShare' : 'emailShareActive'}`} >
                                    <p className='emailtexts'>{t('shareDesign.subtitle')}</p>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <TextInputField name="firstName"
                                            placeholder={t("label.yourFirstName")}
                                            register={register}
                                            requiredErrorMessage={t("error.yourFirstNameRequired")}
                                            errors={errors}
                                        />
                                        <TextInputField name="lastName"
                                            placeholder={t("label.yourLastName")}
                                            register={register}
                                            requiredErrorMessage={t("error.yourLastNameRequired")}
                                            errors={errors}
                                        />
                                        <EmailInputField name="email"
                                            customClass={"col-sm-12 fullform"}
                                            placeholder={t("label.yourEmail")}
                                            register={register}
                                            requiredErrorMessage={t("error.yourEmailRequired")}
                                            patternErrorMessage={t("error.emailFormat")}
                                            errors={errors}
                                        />
                                        <EmailInputField name="friendEmail"
                                            customClass={"col-sm-12 fullform"}
                                            placeholder={t("label.friendEmail")}
                                            register={register}
                                            requiredErrorMessage={t("error.friendEmailRequired")}
                                            patternErrorMessage={t("error.emailFormat")}
                                            errors={errors}
                                        />
                                        <Checkbox name="isMarketingConsent"
                                            customClass="custom-checkbox"
                                            label={t("label.marketingConsent", {
                                                brand: window.location.hostname.includes('bainmagique') || location.search.includes("brand=BM") ? 
                                                'Bain Magique' : 'Bath Fitter'
                                            })}
                                            register={register}
                                            requiredErrorMessage={t("error.marketingConsentRequired")}
                                            errors={errors}
                                        />
                                        <div className="col-sm-4 fullform">
                                            <button type="submit" className="btn upload">{t('button.send')}</button>
                                        </div>
                                    </form>
                                </div>

                                <div className= {`fbSocial ${selectedShareMethod !== 'email' ? 'activeSocialShare' : ''}`}>
                                    <p className="emailtexts">{t("shareDesign.copyLink")}</p>
                                    <div className="copywith">
                                        <input type="text" name="" className="form-control" value={urlSocial} readOnly />
                                        <img src={Tags} alt=''/>
                                        <input type='hidden' id='copyValue' value={urlSocial}/>
                                        <button className="copy" onClick={onCopyLink}>{t("button.copy")}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ) : null
    )
}

export default ShareDesignPopUp