import React,{useRef,useEffect} from 'react'
import jsPDF from 'jspdf';
import { useDispatch,useSelector } from 'react-redux';
import { Link,useLocation } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { TranslationKeys } from '../../i18n';
import SaveDesign from '../../images/savedesign.png'
import Download from '../../images/download.png'
import Print from '../../images/print.png'
import Start from '../../images/start.png'
import { getSummaryPageData, openStartOverPopUp, getDesignImageSource, backToDesign, getProjectType, openSavePopUp, getLoadExistingDesignStatus } from '../designbathshower/designBathShowerSlice';
import SummaryBathShowerContent from './SummaryBathShowerContent';
import ReactToPrint from 'react-to-print';
import DesignSummaryPrint from './DesignSummaryPrint';
import { bookNowPopUpDesingBathShower } from '../designmobilelayout/mobileMenuSlice';
import { GoogleDataLayer, getTagManagerEvents } from '../layout/GoogleDataLayer';

const SummaryBathShower = () => {
const dispatch = useDispatch();  
const location = useLocation();

let summaryDataList =useSelector(getSummaryPageData);
let loadExistingDesignStatus = useSelector(getLoadExistingDesignStatus)
let summary_content;
if(Object.keys(summaryDataList).length >0)
{
    summary_content=Object.entries(summaryDataList).map(([key,data])=><SummaryBathShowerContent key={key} summaryData={data} title={key}  />)
}
const componentRef = useRef();
const current = new Date();

    const { t } = useTranslation();

    const projectType = useSelector(getProjectType);
    const projecTypeName = projectType["translations"] && projectType["translations"][TranslationKeys[i18next.language]] ? projectType["translations"][TranslationKeys[i18next.language]]: projectType.name;

let designImage = useSelector(getDesignImageSource);
const date = `${current.getDate()}-${current.getMonth()+1}-${current.getFullYear()}`;

let generatePDFIMage = () => {     
    let doc = new jsPDF();   
    doc.setFontSize(40);
    doc.text(t("summary.finalDesign"), 35, 25);
    doc.addImage(designImage, "JPEG", 15, 40, 180, 180);
    doc.save('bathfitterDyob.pdf')
  }  

  useEffect(() => {
    if (loadExistingDesignStatus === 'idle') {
      GoogleDataLayer({
          event: getTagManagerEvents.viewSummary,
          summaryPrintData: summaryDataList
      });
  }
  }, [loadExistingDesignStatus, summaryDataList]);

const handleBackToDesignClick = () => {
  dispatch(backToDesign());
};

  return (
    <div className='tabsinnerOd' id="tablescrollOd">
        <p className="back-to back-to-summary">
            <Link to={`${location.pathname}${location.search}`} onClick={handleBackToDesignClick}><span><i className="fas fa-angle-left"></i></span>{t("summaryBackPage")}</Link>
        </p>
    <div className="filtertext middletextcenter"  >
      
           <p className="congrats">{t("summary.congratulations")}</p>
           <Trans i18nKey="summary.instructions" values={{category: projecTypeName.toLowerCase()}}>
                <span className="congratssmalltex">
                    You've designed your new category!<br/>The next step towards your new category is to request a free consultation.
                </span> 
           </Trans>
           <button className="consult" onClick={ () => dispatch(bookNowPopUpDesingBathShower())}>{t("button.bookFree")}</button>
       </div>
       
       {summary_content}
       <div className="bottomfixed">
           
           <div className="saveicons">  
               <ul className="border">
                  <li>
                    <Link to={`${location.pathname}${location.search}`} onClick={() => dispatch(openSavePopUp())}>
                      <i><img src={SaveDesign} alt=''/></i>{t("button.saveDesign")}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`${location.pathname}${location.search}`}
                      onClick={() => {
                        generatePDFIMage();
                        GoogleDataLayer({event: getTagManagerEvents.download});
                      }}
                    >
                      <i><img src={Download} alt='' /></i>{t("button.downloadPDF")}
                    </Link>
                  </li>
                  <li onClick={() => GoogleDataLayer({event: getTagManagerEvents.download})}>
                    <ReactToPrint trigger={() => <Link to={`${location.pathname}${location.search}`} ><i><img src={Print} alt=''/></i>{t("button.printDesign")}</Link>} content={() => componentRef.current}/>
                  </li>
                  <li><Link to={`${location.pathname}${location.search}`} onClick={() => dispatch(openStartOverPopUp())}><i><img src={Start} alt='' /></i>{t("button.startOver")}</Link></li>
               </ul>
               <div className='printSummarypage'>
                    <div style={{"display":"none"}}>
                            <DesignSummaryPrint ref={componentRef}  pdfDate={date} summaryData={summaryDataList} t={t} designImage={designImage}/>
                    </div>            
                </div>
                   
           </div>
            
       </div>
       
       
</div>
  )
}

export default SummaryBathShower