import React,{useEffect} from 'react'
import { useSelector,useDispatch } from 'react-redux';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { Link,useLocation,useNavigate } from 'react-router-dom'
import bathFitterLogo from  '../../images/logowhite.png'
import bainMagiqueLogo from  '../../images/BainMagique_Logo_blanc.png'
import BackArrrow from '../../images/backarrow.png'
import { resetAllState } from '../designbathshower/designBathShowerSlice';
import { bookNowPopUpDesingBathShower, getIsScheduleDesignBookNowError, getIsScheduleDesignBookNowSuccess, resetDesignId, hideAllAlerts } from '../designmobilelayout/mobileMenuSlice';
import DesignBathShowerBookNowPop from '../designbathshowerpopup/DesignBathShowerBookNowPop';
import AlertMesssage from '../message/AlertMesssage';
import SaveMessage from '../message/SaveMessage';
import { GoogleDataLayer, getTagManagerEvents } from '../layout/GoogleDataLayer';
 
const BathShowerHeader = ({isOrientationChanged = false}) => {
const isScheduleDesignBookNowSuccess = useSelector(getIsScheduleDesignBookNowSuccess);
const isScheduleDesignBookNowError = useSelector(getIsScheduleDesignBookNowError);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  
  const location = useLocation();
  if (location.pathname.includes('plumbing-position')) {
    dispatch(resetAllState())
    dispatch(resetDesignId());
  }
  const logo = window.location.hostname.includes('bainmagique') || 
    location.search.includes("brand=BM") ? bainMagiqueLogo : bathFitterLogo;

  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
    dispatch(resetAllState());
    dispatch(resetDesignId());
  }

  useEffect(() => {
    if (isScheduleDesignBookNowSuccess || isScheduleDesignBookNowError) {
      const interval = setInterval(() => dispatch(hideAllAlerts()), 2000)
      return () => clearInterval(interval)
    }
  }, [isScheduleDesignBookNowSuccess, isScheduleDesignBookNowError, dispatch]);

  useEffect(() => {
    // Prevent duplicate event push when changing screen orientation
    if (!isOrientationChanged) {
      GoogleDataLayer({event: getTagManagerEvents.pageView});
    }
  }, [isOrientationChanged]);

  return (
    <div className="headerfixed">
      <header className="headerbg">
        <div className="container" >   
          {
            location.pathname !== '/' &&
            location.pathname !== `/${i18next.language}` ? (
              <div className="col-sm-1 backicon" onClick={goBack}>
                <img src={BackArrrow} alt=''/>
                <em >{t("button.back")}</em>
              </div>
            ) : null
          }
          <div className="logo">
            <Link to={`/${i18next.language}${location.search}`}><img src={logo} alt='' /></Link>
          </div>
          <button
            className={`consult ${i18next.language === 'fr' ? 'frenchBtn' :
              i18next.language === 'es' ? 'spanishBtn' : ''}`}
            onClick={() => dispatch(bookNowPopUpDesingBathShower())}
          >
            {t("button.bookNow")}
          </button>
        </div>
        <DesignBathShowerBookNowPop/>
        {
          isScheduleDesignBookNowSuccess === true ? <SaveMessage msg={t('alert.requestSent')}/> : null
        }
        {
          isScheduleDesignBookNowError === true ? <AlertMesssage msg={t('alert.requestFailed')}/> : null
        }
      </header>
    </div>
  )
}

export default BathShowerHeader