import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import App from './App';
import HomePage from './features/homepage/HomePage';
import ProjectType from './features/projecttype/ProjectType';
import MobileProjectType from './features/designbathshowermobile/MobileProjectType';
import PlumbingMobile from './features/designbathshowermobile/PlumbingMobile';
import ShowerPlumbingMobile from './features/designbathshowermobile/ShowerPlumbingMobile';
import PageNotFound from './features/pagenotfound/PageNotFound';
import Plumbing from './features/plumbing/Plumbing';
import ShowerPlumbing from './features/chooseshower/ShowerPlumbing';
import ChooseShowerList from './features/chooseshower/ChooseShowerList';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ChooseShowerListMobile from './features/designbathshowermobile/ChooseShowerListMobile';
import LoadExistingDesign from './features/projecttype/LoadExistingDesign';
import MobileLoadExistingData from './features/designbathshowermobile/MobileLoadExistingData';
import "./i18n";
import HomePageMobile from './features/designbathshowermobile/HomePageMobile';
import TagManager from 'react-gtm-module';
import TelemetryProvider from './features/telemetry/TelemetryProvider';

if (window.location.hostname.includes('bathfitter') && window.location.pathname.includes('/fr')) {
  // Loaded french version on BF domain, redirect to BM domain
  window.location.hostname = window.location.hostname.replace('bathfitter', 'bainmagique');
} else if (window.location.hostname.includes('bainmagique') && window.location.pathname.includes('/es')) {
  // Loaded spanish version on BM domain, redirect to BF domain
  window.location.hostname = window.location.hostname.replace('bainmagique', 'bathfitter');
}

// This is the same as above, but for the query string for testing on local, dev and staging
if (window.location.search.includes('brand=BF') && window.location.pathname.includes('/fr')) {
  window.location.search = window.location.search.replace('brand=BF', 'brand=BM');
} else if (window.location.search.includes('brand=BM') && window.location.pathname.includes('/es')) {
  window.location.search = window.location.search.replace('brand=BM', 'brand=BF');
}

document.title = window.location.hostname.includes('bathfitter') || 
  window.location.search.includes('brand=BF') ? 'Bath Fitter' : 'Bain Magique';

const container = document.getElementById('root');
const root      = createRoot(container);

const isMobile = window.innerWidth < 768;

const tagManagerArgs = {
  gtmId: window.config.REACT_APP_GTM_ID
}
TagManager.initialize(tagManagerArgs);

root.render(
  // What to display while languages are loading
  <Suspense fallback="Loading...">
    <Provider store={ store }>
         <Router path='/dyob2' >
            <TelemetryProvider>
              <Routes>
                {
                  (isMobile === true) ?
                    <Route path="/:locale?">
                      <Route path="" element={<HomePageMobile/>} />
                      <Route path="project-type" element={<MobileProjectType/>} />
                      <Route path="plumbing-position-m/:id" element={<PlumbingMobile/>} />
                      <Route path="shower-plumbing-position-m/:id" element={<ShowerPlumbingMobile/>} />
                      <Route path="choose-shower-m/:id" element={<ChooseShowerListMobile/>} />
                      <Route path="design-your-own-bathroom/:id" element={<App/>} />
                      <Route path='design-your-own-shower/:id' element={<App/>}/> 
                      <Route path="design-your-own-bathroom/:id/:designId" element={<App/>} />
                      <Route path='design-your-own-shower/:id/:designId' element={<App/>}/> 
                      <Route path="load-existing-design-mobile" element={<MobileLoadExistingData />} />
                    </Route>
                  :
                    <Route path="/:locale?">
                      <Route path="" element={<HomePage/>} />
                      <Route path="project-type" element={<ProjectType/>} />
                      <Route path="plumbing-position/:id" element={<Plumbing/>} />
                      <Route path="shower-plumbing-position/:id" element={<ShowerPlumbing/>} />
                      <Route path="choose-shower/:id" element={<ChooseShowerList/>} />
                      <Route path="design-your-own-bathroom/:id" element={<App/>} />
                      <Route path='design-your-own-shower/:id' element={<App/>}/>
                      <Route path="design-your-own-bathroom/:id/:designId" element={<App/>} /> 
                      <Route path="design-your-own-shower/:id/:designId" element={<App/>} /> 
                      <Route path="load-existing-design" element={<LoadExistingDesign/>} /> 
                    </Route>
                }
                <Route path="*" element={<PageNotFound />} />
              </Routes>
            </TelemetryProvider>
          </Router>
    </Provider>
  </Suspense>
);


